import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import MemberProfileDropDown from '../layouts/Member/components/MemberProfileDropDown';
import MemberDashboardToDoComponent from '../layouts/Member/components/MemberDashboardToDo';
import AuthService from '../services/AuthService';
import {Typography} from '@material-ui/core';
import Box from '@material-ui/core/Box';
import List from '@material-ui/core/List';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Icon from '@material-ui/core/Icon';
import DateUtils from '../services/Utility/DateUtils';
import {ProductTypes} from '../services/ProductService';
import CardHeader from '@material-ui/core/CardHeader';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: 'center',
    margin: '0px 0px 15px 0px'
  },
  listItems: {
    lineHeight: '5px'
  }

}));

function MemberPortalDashboard(props) {
  const [dense] = React.useState(true);
  const classes = useStyles();
  const user = AuthService.getUser();

  return (
      <div className={classes.root}>
        <Box
            display='flex'
        >
          <Grid container spacing={1}>
            <Box
                display='flex'
                justifyContent='space-around'
            >
              <Grid item xs={4}>
                <Paper className={classes.paper}>


                  <Typography variant='h5' gutterBottom>
                    Welcome to the PPSWG Invoice Portal. For PPSWG to prepare your
                    company’s {DateUtils.getCurrentYear() + 1} invoice,
                    please complete the following tasks as they apply to your organization:
                  </Typography>
                    <div>
                      <List className={classes.listItems} dense={dense}>
                        <ListItem>
                          <ListItemIcon>
                            <Icon fontSize='large' color={'secondary'}>bubble_chart</Icon>
                          </ListItemIcon>
                          <ListItemText
                              primary='Review and update Rx covered products.'
                          />
                        </ListItem>
                        <ListItem>
                          <ListItemIcon>
                            <Icon fontSize='large' color={'secondary'}>bubble_chart</Icon>
                          </ListItemIcon>
                          <ListItemText
                              primary='Review and update over the counter (OTC) covered products.'
                          />
                        </ListItem>
                        <ListItem>
                          <ListItemIcon>
                            <Icon fontSize='large' color={'secondary'}>bubble_chart</Icon>
                          </ListItemIcon>
                          <ListItemText
                              primary='Complete Private Label Self-Reporting Form.'
                          />
                        </ListItem>
                        <ListItem>
                          <ListItemIcon>
                            <Icon fontSize='large' color={'secondary'}>bubble_chart</Icon>
                          </ListItemIcon>
                          <ListItemText
                              primary='Complete Sharps Self-Reporting Form.'
                          />
                        </ListItem>
                      </List>
                    </div>
                </Paper>

                <MemberProfileDropDown/>
              </Grid>
              <Grid item xs={4}>
                <Paper className={classes.paper}>
                  <MemberDashboardToDoComponent/>
                </Paper>
              </Grid>
            </Box>
          </Grid>
        </Box>
      </div>
  );
}

export default MemberPortalDashboard;
