/* @flow */

import StringUtils from './Utility/StringUtils';
import {conf} from '../config';
import {HttpService} from './HttpService';
import type {MaterialPage, MaterialPageQuery} from './Utility/ServiceUtil';
import {resToTablePage} from './Utility/ServiceUtil';

export type Program = {|
  id: number,
  programJurisdictionId: number,
  programCategoryId: number,
  name: string,
  isActive: string,
  programJurisdiction: string,
  programCategory: string,
  companyPrograms?: Array<any>
|};

export type ProgramView = {|
  id: number,
  name: string,
  category?: string,
  jurisdiction?: string,
  isActive?: string
|};

export type ProgramJurisdictions = {|
  id: number,
  name: string
|};

export type ProgramCategory = {|
  id: number,
  name: string
|};

export default class ProgramService {
  static getPrograms(query: MaterialPageQuery): Promise<MaterialPage<ProgramView>> {
    const pathParams: { [string]: number | string } = {
      page: query.page,
      rows: query.pageSize
    };

    let url = conf.api.getProgramsByKeyWord;
    if (!StringUtils.isBlank(query.search) && query.search.length > 2) {
      pathParams.search = encodeURIComponent(query.search);
      url = conf.api.getProgramsByKeyWordSearch;
    }

    return HttpService.get(url, {pathParams: pathParams})
    .toPromise()
    .then(resToTablePage(query.page));
  };

  static updateProgram(newData: ProgramView): Promise<Program> {
    return HttpService.put(conf.api.programUpdate, {data: newData}).toPromise();
  };

  static createProgram(newData: ProgramView): Promise<Program> {
    return HttpService.post(conf.api.programCreate, {data: newData}).toPromise();
  };

  static getProgramJurisdictions(keyword: string): Promise<Array<ProgramJurisdictions>> {
    if (!keyword || !keyword.length || keyword.length < 3) {
      return Promise.resolve([]);
    }

    return HttpService.get(conf.api.programJurisdictionGetByKeyword, {
      pathParams: {
        keyword: keyword
      }
    }).toPromise();
  };

  static getProgramCategories(keyword: string): Promise<Array<ProgramCategory>> {
    if (!keyword || !keyword.length || keyword.length < 3) {
      return Promise.resolve([]);
    }

    return HttpService.get(conf.api.programCategoryGetByKeyword, {
      pathParams: {
        keyword: keyword
      }
    }).toPromise();
  };

}
