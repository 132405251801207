/* @flow */

import {conf} from '../config';
import {HttpService} from './HttpService';
import type {MaterialPage, VendorProduct} from './Utility/ServiceUtil';
import {paramsError, resToTablePage} from './Utility/ServiceUtil';
import type {Vendor} from './ProductService';
import {Vendors} from './ProductService';
import {LoggerService} from './LoggerService';

const logger = LoggerService.getLogger('AdminEditService');

export class AdminEditService {

  static getAdminByBatchId(vendor: Vendor, batchId: number, page: number, pageSize: number):
      Promise<MaterialPage<VendorProduct>> {
    if (!(vendor in Vendors) || !(batchId > -1) || !(page > -1)) {
      logger.error('Missing required params.');
      return paramsError();
    }

    return HttpService.get(conf.api.adminByBatchId, {
      pathParams: {
        'vendor': vendor,
        'batchId': batchId,
        'page': page,
        'rows': pageSize
      },
      httpTimeout: conf.httpGetVendorDataTimeout
    })
    .toPromise()
    .then(resToTablePage(page));
  }

  static updateVendorData(vendor: Vendor, data: any): Promise<void> {
    if (!(vendor in Vendors) || !data) {
      logger.error('Missing required params.');
      return paramsError();
    }

    return HttpService.put(conf.api.adminUpdateVendorData, {
      data: data,
      pathParams: {
        'vendor': vendor,
      }
    }).toPromise();
  }

  static uploadVendorFile(file: File, vendor: Vendor, batchId: number): Promise<void> {
    if (!file || !(vendor in Vendors) || !(batchId > -1)) {
      logger.error('Missing required params.');
      return paramsError();
    }

    const formData = new FormData();
    formData.append('body', file);

    return HttpService.post(conf.api.uploadVendorFile, {
      data: formData,
      pathParams: {
        vendor: vendor,
        batchId: batchId
      },
      httpTimeout: conf.httpUploadTimeout
    }).toPromise();
  }

}
