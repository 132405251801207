/* @flow */

import {conf} from '../config';
import {HttpService} from './HttpService';
import {paramsError} from './Utility/ServiceUtil';

export type BatchException = {|
  batchExceptionId: number,
  reason: string,
  companyName: string
|};

export type BatchExceptionRes = {|
  resolvedCount: number,
  pendingCount: number,
  unresolvedCount: number,
  batchExceptions: Array<BatchException>
|};

export default class BatchExceptionService {

  static getBatchExceptions(batchId: number, page: number, pageSize: number): Promise<BatchExceptionRes | null> {
    if (!batchId || batchId < 1 || page < 0 || pageSize <= 0) {
      return paramsError();
    }

    return HttpService.get(conf.api.getBatchExceptionsByBatchId, {
      pathParams: {
        batchId: batchId
      },
      queryParams: {
        page: page,
        pageSize: pageSize
      }
    }).toPromise();
  }

  static getNumberOfBatchExceptions(batchId: number): Promise<number> {
    if (!batchId || batchId < 1) {
      return paramsError();
    }

    return HttpService.get(conf.api.numberOfBatchExceptions, {
      pathParams: {
        batchId: batchId
      }
    }).toPromise();
  }

  static ignoreBatchException(companyName: string, batchExceptionId: number): Promise<void> {
    if (!companyName || batchExceptionId < 1) {
      return paramsError();
    }

    return HttpService.post(conf.api.ignoreBatchException, {
      data: {
        companyName: companyName,
        batchExceptionId: batchExceptionId
      }
    }).toPromise();
  }

  static resolveBatchException(companyId: number, newAlias: string, batchExceptionId: number): Promise<void> {
    if (companyId < 1 || !newAlias || batchExceptionId < 1) {
      return paramsError();
    }

    return HttpService.post(conf.api.resolveBatchException, {
      data: {
        companyId: companyId,
        newAlias: newAlias,
        batchExceptionId: batchExceptionId
      }
    }).toPromise();
  }
}
