/* @flow */

import {conf} from '../config';
import {HttpService} from './HttpService';
import {LoggerService} from './LoggerService';
import type {MaterialPage, MaterialPageQuery} from './Utility/ServiceUtil';
import {resToTablePage} from './Utility/ServiceUtil';
import type {ProductType} from './ProductService';
import {ProductTypes, ProductVendor} from './ProductService';

const logger = LoggerService.getLogger('RawDataService');

export type OtcBatchItem = {|
  id: number,
  batchDataId: number,
  totalUs: string,
  bcSuperCategory: string,
  bcCategory: string,
  bcSubCategory: string,
  bcSegment: string,
  brandLow: string,
  brandHigh: string,
  brandOwnerLow: string,
  brandOwnerHigh: string,
  allPeriods: string,
  dollarValue: string,
  revenue: string,
  units: string,
  marketshareByValueBCSuperCategory: string,
  marketshareByUnitsBCSuperCategory: string,
  batchData: boolean,
  nielsenData: boolean,
  isDeleted: boolean,
  createdBy: string,
  createdDateUtc: string,
  lastModifiedBy: string,
  lastModifiedDateUtc: string
|};

export type RxBatchItem = {|
  id: number,
  batchDataId: number,
  corporation: string,
  manufacturer: string,
  prodForm1: string,
  prodForm2: string,
  prodForm3: string,
  product: string,
  ndc: string,
  packSize: string,
  packQuantity: string,
  yearTrX: string,
  revenue: string,
  batchData: boolean,
  nielsenData: boolean,
  isDeleted: boolean,
  createdBy: string,
  createdDateUtc: string,
  lastModifiedBy: string,
  lastModifiedDateUtc: string
|};

export type RawDataBatchItem = OtcBatchItem | RxBatchItem;

export default class RawDataService {

  static getProducts(query: MaterialPageQuery, type: ProductType, batchId: number):
      Promise<MaterialPage<RawDataBatchItem>> {

    if (!(type in ProductTypes)) {
      logger.error('Missing required params.');
      return Promise.reject(conf.defaultErrMessage);
    }

    return HttpService.get(conf.api.getRawDataByBatchIdSize, {
      pathParams: {
        vendor: ProductVendor[type],
        batchId: batchId,
        page: query.page,
        rows: query.pageSize
      },
      httpTimeout: conf.httpGetVendorDataTimeout
    })
    .toPromise()
    .then(resToTablePage(query.page));
  }

}
