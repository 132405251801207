import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

function ActionTypeSelect(props) {
  const classes = useStyles();
  return (
      <form className={classes.root} autoComplete='off'>
        <FormControl className={classes.formControl} component={'div'}>
          <InputLabel htmlFor='action'>Action</InputLabel>
          <Select
              value={props.action}
              onChange={evt => props.setAction(evt.target.value)}
              inputProps={{
                name: 'action',
                id: 'action',
              }}
          >
            <MenuItem value={'All'} component={'li'} button={false}><em>All</em></MenuItem>
            <MenuItem value={'Create'} component={'li'} button={false}>Create</MenuItem>
            <MenuItem value={'Edit'} component={'li'} button={false}>Edit</MenuItem>
            <MenuItem value={'Deactivate'} component={'li'} button={false}>Deactivate</MenuItem>
          </Select>
        </FormControl>

      </form>
  );
}

export default ActionTypeSelect;
