/* @flow */

import React from 'react';

import TopBarAdmin from '../Admin/components/TopBar';
import NavTabsAdmin from '../Admin/components/NavTabs';
import {JSXElement} from '@babel/types';
import AuthService from '../../services/AuthService';
import {conf} from '../../config';
import {Redirect} from 'react-router';

type Props = {||};
type State = {||};

export default class AdminLayout extends React.Component<Props, State> {
  render(): JSXElement {
    if (!AuthService.isAdmin()) {
      return <Redirect to={conf.pathRoot}/>;
    }

    return (
        <React.Fragment>
          <TopBarAdmin/>
          <NavTabsAdmin/>
        </React.Fragment>
    );
  }
}
