/* @flow */
import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import ActionTypeSelect from './ActionTypeSelect';
import ServiceTypeSelect from './ServiceTypeSelect';
import {Button} from '@material-ui/core';
import Box from '@material-ui/core/Box';
import HistoryDatePicker from '../../Admin/components/HistoryDatePicker';
import Tooltip from '@material-ui/core/Tooltip';
import HistoryEndDatePicker from './HistoryEndDatePicker';

const tabStyle = {
  padding: '0px 0px',
  margin: '0px 0px 20px 0px'
};

type State = {|
  actionType: string,
  startDateRange: string,
  endDateRange: string,
  service: string
|}

type Props = {|
  filters: any,
  runReport: (State) => void,
  exportToExcel: () => void,
  totalRecords: number
|}

export default class HistoryRecordFilters extends React.Component<Props, State> {
  state: State = {
    actionType: '',
    startDateRange: '',
    endDateRange: '',
    service: ''
  };

  componentDidMount(){
    this.setState({
      actionType: this.props.filters.actionType,
      startDateRange: this.props.filters.startDateRange,
      endDateRange: this.props.filters.endDateRange,
      service: this.props.filters.service
    });
  }

  setService(service:string):void{
    this.setState({
      service: service
    });
  }

  setActionType(actionType:string):void{
    this.setState({
      actionType: actionType
    });
  }

  setStartDate(startDateRange:string):void{
    this.setState({
      startDateRange: startDateRange
    });
  }

  setEndDate(endDateRange:string):void{
    this.setState({
      endDateRange: endDateRange
    });
  }

  render() {
    let disabled = (this.props.totalRecords === 0 || this.props.totalRecords >= 1048575 /*Max row count in excel*/);
    let disabledText = () => {
      if (this.props.totalRecords === 0){
        return 'There are no records to export!'
      }else if (this.props.totalRecords >= 1048576) {
        return 'The maximum row limit in Excel is 1,048,576. Please reduce the number of rows you would like to export!';
      }else{
        return ''
      }
    };
    return (
        <div>
          <AppBar position='static' style={tabStyle}>
            <Toolbar  style={{padding: '0px', }}>

              <div style={{width: '100%'}}>
                <Box

                    alignItems='center'
                    display='flex'
                    flexWrap='wrap'
                    bgcolor='#ffffff'
                >
                  <Box mx='auto'>
                    <HistoryDatePicker label='Start Date' type='StartDate' date={this.state.startDateRange} setDate={(startDate) => {
                      this.setStartDate(startDate);
                    }}/>
                  </Box>
                  <p>To</p>
                  <Box mx='auto'>
                    <HistoryEndDatePicker type='EndDate' date={this.state.endDateRange} setDate={(endDate) => {
                      this.setEndDate(endDate);
                    }}/>
                  </Box>
                  <Box mx='auto'>
                    <ActionTypeSelect setAction={(action) => {
                      this.setActionType(action);
                    }} action={this.state.actionType}/>
                  </Box>
                  <Box mx='auto'>
                    <ServiceTypeSelect setService={(service) => {
                      this.setService(service);
                    }} service={this.state.service}/>
                  </Box>
                  <Box mx='auto'>
                    <Button id='recordHistoryRunReport' variant={'contained'} color='secondary'
                            onClick={() => {
                              this.props.runReport(this.state);
                            }}
                            href={''}> Run report </Button>
                  </Box>
                  <Box mx='auto'>
                    <Tooltip title={disabledText()}>
                    <div>
                      <Button id='exportHistoryToExcel' variant={'contained'} color='secondary'
                              onClick={() => {
                                this.props.exportToExcel(this.state);
                              }}
                              disabled={disabled}
                              href={''}> Export To Excel </Button>
                    </div>
                    </Tooltip>
                  </Box>
                </Box>


              </div>
            </Toolbar>
          </AppBar>
        </div>
    );
  }
}
