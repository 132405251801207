/* @flow */

import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import {ClassNameMap} from '@material-ui/styles/withStyles';
import Box from '@material-ui/core/Box';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Button from '@material-ui/core/Button';
import InvoiceService, {InvoiceCalculationStatuses} from '../../../services/InvoiceService';
import type {InvoiceCalculationStatus} from '../../../services/InvoiceService';

const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    margin: '0 0 15px 0',
    maxWidth: 500,
  },
  button: {
    fontSize: '.7rem',
  }
});

const validStatusesForRunningCalculation = [
  InvoiceCalculationStatuses.notStarted, InvoiceCalculationStatuses.completed,
  InvoiceCalculationStatuses.failed, InvoiceCalculationStatuses.unknown
];

type Props = {|
  classes: ClassNameMap,
  calculationStatus: string,
  disabled: boolean,
  runInvoices: void => void
|};
type State = {|
  agreeInvoice: boolean
|};

class RunInvoiceAction extends React.Component<Props, State> {
  state: State = {
    agreeInvoice: false
  };

  onCheckInvoice(e: Event, checked: boolean): void {
    this.setState({agreeInvoice: checked});
  };

  render() {
    const {classes, calculationStatus, disabled, runInvoices} = this.props;
    const {agreeInvoice} = this.state;

    return <React.Fragment>
      <Box
          display='flex'
          justifyContent='space-around'
          m={2}>
        {calculationStatus ?
            <p className={classes.toggleText}>Invoice Calculation Status: {calculationStatus}</p>
            : <p className={classes.toggleText}>Getting Invoice Calculation Status...</p>
        }
      </Box>
      {validStatusesForRunningCalculation.includes(calculationStatus) ?
          <Box
              display='flex'
              justifyContent='space-around'
              m={2}
          >

            <p className={classes.toggleText}>I AGREE, Run Invoices</p>
            <FormControlLabel
                value='start'
                disabled={disabled}
                control={<Switch id='switchRunInvoice' onChange={(e, checked) => this.onCheckInvoice(e, checked)}
                                 color='primary'/>}
                label={''}
            />
            <Button
                id='btnRunInvoice'
                variant='contained'
                color='secondary'
                className={classes.button}
                disabled={disabled || !agreeInvoice}
                onClick={runInvoices}
            >
              Run Invoices
            </Button>
          </Box>
          : null
      }
    </React.Fragment>;
  }
}

export default withStyles(styles)(RunInvoiceAction);
