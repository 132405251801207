import Tab from '@material-ui/core/Tab';
import {Link} from 'react-router-dom';
import React from 'react';

// Creates a Tab with mappings from Material UI to React Router.
export const routerTab = (label, routePath, key) => {
  const id = 'nav-' + (label || '').split(' ').join('').toLocaleLowerCase();

  return <Tab id={id} value={routePath} to={routePath} component={Link} href={''} label={label} key={key}/>;
};

export const getDerivedStateFromProps = (props, current_state) => {
  if (props.location.pathname !== current_state.selectedTab) {
    return {
      selectedTab: props.location.pathname,
      ...props
    };
  }

  return null;
};
