import {LoggerService} from '../LoggerService';

const msInMinute = 60000;

const logger = LoggerService.getLogger('DateUtil');

export default class DateUtils {
  static currentYear = (new Date()).getFullYear();

  static formatDate = (template, date) => {
    try {
      template = template.replace('ss', (date.getSeconds() < 10 ? '0' : '') + date.getSeconds()); // Pad with '0' if needed
      template = template.replace('mm', (date.getMinutes() < 10 ? '0' : '') + date.getMinutes()); // Pad with '0' if needed
      template = template.replace('HH', (date.getHours() < 10 ? '0' : '') + date.getHours()); // Pad with '0' if needed
      template = template.replace('DD', (date.getDate() < 10 ? '0' : '') + date.getDate()); // Pad with '0' if needed
      template = template.replace('MM', (date.getMonth() < 9 ? '0' : '') + (date.getMonth() + 1)); // Months are zero-based
      template = template.replace('YYYY', date.getFullYear());
      return template;
    } catch (err) {
      logger.error('Could not format date: ' + date + ' ' + err);
      return date;
    }
  };

  static utcToLocalTime = (date) => {
    try {
      return new Date(date);
    } catch (err) {
      logger.error('Could not convert UTC to Local: ' + date + ' ' + err);
      return date;
    }
  };

  static getCurrentYear = () => {
    return new Date().getFullYear();
  };

  static dateTimeNow = () => {
    const d = new Date();
    let month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) {
      month = '0' + month;
    }
    if (day.length < 2) {
      day = '0' + day;
    }

    return [year, month, day].join('-');
  };

  static createFromLocalDateString = (dateStr) => {
    //Create UTC date from date string.
    const utcDate = new Date(dateStr);

    //Offest the UTC date by the UTC offset in minutes
    return new Date(utcDate.getTime() + utcDate.getTimezoneOffset() * 60000);
  };
}
