/* @flow */

import React from 'react';
import {JSXElement} from '@babel/types';

type Props = {
  error: string
};
type State = {||};

export default class Error extends React.Component<Props, State> {
  render(): JSXElement {
    return <div>
      <h4>An error has occurred</h4>
      <div>{this.props.error}</div>
    </div>
  }
}
