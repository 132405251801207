/* @flow */
import React from 'react';
import type {ProgramView} from '../../../services/ProgramService';
import ProgramService from '../../../services/ProgramService';
//$FlowFixMe
import AsyncSelect from 'react-select/async';
import type {MaterialPage} from '../../../services/Utility/ServiceUtil';
import {JSXElement} from '@babel/types';
//$FlowFixMe
import type {OptionType, ValueType} from 'react-select/src/types';
import {conf} from '../../../config';
import {withStyles} from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Chip from '@material-ui/core/Chip';
import CancelIcon from '@material-ui/icons/Cancel';

function MultiValue(props) {
  return (
      <Chip
          tabIndex={-1}
          label={props.children}
          onDelete={props.removeProps.onClick}
          deleteIcon={<CancelIcon {...props.removeProps} />}
          variant='outlined'
          size='small'
          color='secondary'
          component='div'
      />
  );
}

MultiValue.propTypes = {
  children: PropTypes.node,
  isFocused: PropTypes.bool.isRequired,
  removeProps: PropTypes.shape({
    onClick: PropTypes.func.isRequired,
    onMouseDown: PropTypes.func.isRequired,
    onTouchEnd: PropTypes.func.isRequired,
  }).isRequired,
  selectProps: PropTypes.object.isRequired,
};

type Props = {
  onChange: (programs: Array<ProgramView>) => void,
  initialPrograms?: Array<OptionType>
};

type State = {||};

export default class ProgramAsyncSelect extends React.Component<Props, State> {

  componentDidMount(): void {
    this.fetchPrograms('');
  }

  fetchPrograms = (search: string) => {
    return ProgramService.getPrograms({
      page: 0, // TODO always only need the first page?
      search: search,
      pageSize: conf.defaultRowsPerPage
    }).then((programMaterialPage: MaterialPage<ProgramView>): Array<OptionType> => {
      return programMaterialPage.data.map((program: ProgramView) => {
        return {
          value: program.id,
          label: program.name
        };
      });
    });
  };

  onChange = (values: ValueType): void => {
    values = values || [];

    this.props.onChange(values.map((item: OptionType): ProgramView => {
      return {
        id: item.value,
        name: item.label
      };
    }));
  };

  render(): JSXElement {
    return (
        <AsyncSelect
            fullWidth
            label='Search for a Program by Name'
            placeholder='Enter a program name.'
            isMulti={true}
            cacheOptions
            defaultOptions={true}
            loadOptions={this.fetchPrograms}
            onChange={this.onChange}
            onInputChange={this.fetchPrograms}
            value={this.props.initialPrograms}
            components={{MultiValue}}
        />
    );
  };
}