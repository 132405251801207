/* @flow */

import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import {ClassNameMap} from '@material-ui/styles/withStyles';
import Chip from '@material-ui/core/Chip';

const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    margin: '0 0 15px 0',
    maxWidth: 500,
    backgroundColor: '#f5f5f5',

  },
  chip: {
    margin: theme.spacing(1),
  },
});

type Props = {|
  classes: ClassNameMap,
  revenue: number,
  category: string
|};
type State = {};

class RevenueTotalItem extends React.Component<Props, State> {
  state = {};

  render() {
    const {classes, revenue, category} = this.props;

    return <div className={classes.root}>
      <Paper className={classes.paper}>
        <Grid container spacing={2}>

          <Grid item xs={12} sm container>
            <Grid item xs container direction='column' spacing={2}>
              <Grid item xs>
                <Typography gutterBottom variant='subtitle1'>
                  {category}
                </Typography>
              </Grid>

            </Grid>
            <Grid item>
              <Chip label={revenue === null ? 'n/a' : ('$' + revenue)} className={classes.chip} color={'secondary'} />
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </div>;
  }
}

export default withStyles(styles)(RevenueTotalItem);
