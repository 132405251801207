/* @flow */

import {LoggerService} from '../LoggerService';
import {HttpError} from '../HttpService';
import {conf} from '../../config';

const logger = LoggerService.getLogger('ServiceUtil');

export type MaterialPageQuery = {|
  page: number,
  pageSize: number,
  search: string,
|};

export type MaterialPage<T> = {|
  data: Array<T>,
  page: number,
  totalCount: number
|};

export type PagedResponse<T> = {|
  data: Array<T>,
  currentPage?: number,
  hasNextPage: boolean,
  count: number
|};

export type NielsenProductMeta = {|
  id: number,
  companyId: number,
  category: string,
  subCategory: string,
  segment: string,
  subSegment: string,
  brandLow: string,
  brandOwnerHigh: string,
  revenue: string,
  units: string,
  isCovered: boolean,
  notes?: string,
  memberNotes?: string,
|};

export type IqviaProductMeta = {|
  id: number,
  companyId: number,
  corporation: string,
  prodForm1: string,
  prodForm2: string,
  prodForm3: string,
  product: string,
  ndc: string,
  units: string,
  revenue: string,
  isOpioidBenzo: boolean,
  isBiologic: boolean,
  notes?: string,
  memberNotes?: string,
  isCovered: boolean
|};

export type VendorProduct = NielsenProductMeta | IqviaProductMeta;

export const resToTablePage = (page: number): (PagedResponse<any> => MaterialPage<any>) => {
  return (result: PagedResponse<any>): MaterialPage<any> => {
    if (!result || !result.data || !(result.count > -1)) {
      if (result !== null) {
        logger.warn(`Invalid paged response: ${JSON.stringify(result)}`);
      }

      return {data: [], page: page, totalCount: 0};
    }

    return {
      data: result.data,
      page: page,
      totalCount: result.count
    };
  };
};

export const resToPageResponse = (): (PagedResponse<any> => PagedResponse<any>) => {
  return (result: PagedResponse<any>): PagedResponse<any> => {
    if (!result || !result.data || !(result.count > -1)) {
      if (result !== null) {
        logger.warn(`Invalid paged response: ${JSON.stringify(result)}`);
      }

      return { data: [], hasNextPage: false, count: 0 };
    }

    return {
      data: result.data,
      hasNextPage: result.hasNextPage,
      count: result.count
    };
  };
};

export const stripNullKeyVals = (obj: Object): Object => {
  obj = obj || {};
  obj = {...obj};

  Object.keys(obj).forEach((key: string): void => {
    if (obj[key] === null) {
      delete obj[key];
    }
  });

  return obj;
};

export const paramsError = () => Promise.reject(new HttpError(-1, conf.defaultErrMessage));
