/* @flow */

import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import ViewInvoice from '../../../views/ViewInvoice';
import {ClassNameMap} from '@material-ui/styles/withStyles';
import {withStyles} from '@material-ui/core';
import {JSXElement} from '@babel/types';
import DialogTitle from '@material-ui/core/DialogTitle';

const styles = theme => ({

  title: {
    backgroundColor: '#fb9b06',
    padding: '0px 15px',
  },
  barTitle: {
    padding: '3px',
    color: '#ffffff',
  },
  icon: {
    color: '#ffffff',
  },


});

type Props = {|
  classes: ClassNameMap,
  open: boolean,
  invoiceId: number,
  handleClose: void,
  dueDate: string,
  createdDate: string,
  companyId: number,
  customerPoNumber: string
|};
type State = {||};

class InvoiceDialog extends React.Component<Props, State> {

  transitionComponent = React.forwardRef(function Transition(props, ref) {
    return <Slide direction='down' ref={ref} {...props} />;
  });

  render(): JSXElement {
    const {classes} = this.props;

    return (
        <div>
          <Dialog
              open={this.props.open}
              onClose={this.props.handleClose}
              TransitionComponent={this.transitionComponent}
          >
            <DialogTitle id='customized-dialog-title' className={classes.title} onClose={this.props.handleClose}>
              <IconButton edge='start' className={classes.icon} onClick={this.props.handleClose} aria-label='close'>
                <CloseIcon/>
                <p className={classes.barTitle}>Invoice</p>
              </IconButton>
            </DialogTitle>

            <ViewInvoice
                    companyId={this.props.companyId} invoiceId={this.props.invoiceId} dueDate={this.props.dueDate}
                    createdDate={this.props.createdDate} customerPoNumber={this.props.customerPoNumber}/>
          </Dialog>
        </div>
    );
  }
}

export default withStyles(styles)(InvoiceDialog);