// This is the only non-react-app env variable supported.
const isProduction = process.env.NODE_ENV === 'production';

// Environment variables besides NODE_ENV must begin with REACT_APP_
//   to be compiled into the webpack for static deploys.
const apiPrefix = '/api';
const apiBase = process.env.REACT_APP_API_URL || 'https://localhost:5001' + apiPrefix;
const authUrl = process.env.REACT_APP_AUTH_URL || 'https://localhost:5001/#/fakeAuth';
const authLogoutUrl = process.env.REACT_APP_LOGOUT_URL || 'https://staging-jumpstart-16d72c6d83e-16df487b4b4.cs123.force.com/communityhub/secur/logout.jsp';
const buildVersion = process.env.REACT_APP_BUILD_VERSION || 'Number.Hash';
const mockApiBase = 'http://localhost:3000/api';

const secondsToMillis = (seconds: number): number => {
  return seconds * 1000;
};

const conf = {
  isProduction: isProduction,
  mockApiBase: mockApiBase,
  useXHRLogger: false,
  authRedirectUrl: authUrl,
  authLogoutUrl: authLogoutUrl,
  buildVersion: buildVersion,
  filenameHeader: 'x-suggested-filename',
  defaultDownloadedFileName: 'ppswg-download.xlsx',
  defaultErrMessage: 'An error has occurred. Please try again later.',
  vendors: {
    Iqvia: 'Iqvia',
    Nielsen: 'Nielsen'
  },
  defaultRowsPerPage: 20,
  httpRetryInterval: secondsToMillis(3),
  httpRequestTimeout: secondsToMillis(15),
  httpRetryLimit: 2,
  httpRequestTimeoutRetryLimit: 0,
  httpRecordHistoryTimeout: secondsToMillis(1200), //TODO obscene amount, but will likely have to be changed to be without temporal tables. just want to make sure it doesnt break until PPSWG requests the change
  httpMemberSubmissionsTimeout: secondsToMillis(210),
  httpRecordHistoryExcelTimeout: secondsToMillis(1200), //TODO obscene amount, but will likely have to be changed to be without temporal tables. just want to make sure it doesnt break until PPSWG requests the change
  httpInvoiceExcelTimeout: secondsToMillis(1200),
  httpGetVendorDataTimeout: secondsToMillis(10),
  httpUploadTimeout: secondsToMillis(240),
  httpGetMarketShareStatusTimeout: secondsToMillis(20),
  httpStartMarketShareCalculationTimeout: secondsToMillis(20),
  httpPrintInvoiceTimeout: secondsToMillis(20),
  httpMemberSubmissionsExcelTimeout: secondsToMillis(1200),
  api: {
    base: apiBase,
    prefix: apiPrefix,
    adminByBatchId: '/:vendor/GetAdminByBatchId/:batchId/page/:page/pageSize/:rows',
    adminUpdateVendorData: '/:vendor/AdminUpdate:vendorData/',
    memberUpdateVendorData: '/:vendor/MemberUpdate:vendorData/',
    numberOfBatchExceptions: '/BatchException/GetNumberofBatchExceptionsByBatchId/:batchId',
    upsertDueDate: '/DueDate/UpsertDueDate',
    getAllDueDates: '/DueDate/GetAllDueDates/',
    uploadVendorFile: '/:vendor/Upload/:batchId',
    getMemberData: '/:vendor/GetMember/page/:page/pageSize/:rows',
    getRawDataByBatchId: '/:vendor/GetRawDataByBatchId/:batchId/page/:page',
    getRawDataByBatchIdSize: '/:vendor/GetRawDataByBatchId/:batchId/page/:page/pageSize/:rows',
    reviewFormSubmitted: '/:vendor/ReviewFormSubmitted',
    getReviewFormSubmitted: '/SharpsForm/ReviewFormSubmitted',
    submitReviewForm: '/:vendor/SubmitReviewForm',
    getMostRecentInProgBatch: '/BatchData/GetMostRecentInProgressBatchData/:type',
    updateBatchDataStep: '/BatchData/UpdateBatchDataStep',
    deleteBatchData: '/BatchData/DeleteBatchData/:batchId',
    createBatchData: '/BatchData/CreateBatchData/:type',
    getCompanyInfo: '/Companies/GetCompanyInformation',
    getDueDateByType: '/DueDate/GetDueDateByType',
    getThisYearsNda: '/Nda/GetThisYearsNda',
    createNewNdaForm: '/Nda/CreateNewNdaForm/:person',
    writeToLogger: '/Logging/WriteToLogger',
    getAssemblyVersion: '/Utility/GetAssemblyVersion',
    getHeartBeat: '/Utility/GetHeartbeat',
    getForm: '/SelfReportedOtc/GetForm',
    selfReportedOtc: '/SelfReportedOtc/SaveForm',
    getProducts: '/:vendor/GetProducts/page/:page/pageSize/:rows',
    upsertProduct: '/:vendor/UpsertProduct',
    categorySearch: '/Category/Search:typeLeafCategoriesByName/',
    getTodoList: '/ToDoList/GetToDoList',
    fakeAuthCallback: '/FakeAuth/FakedAuthCallback',
    getProgramsByKeyWord: '/Program/GetProgramsByKeyword/page/:page/pageSize/:rows',
    getProgramsByKeyWordSearch: '/Program/GetProgramsByKeyword/:search/page/:page/pageSize/:rows',
    programUpdate: '/Program/Update',
    programCreate: '/Program/Create',
    programJurisdictionGetByKeyword: '/ProgramJurisdiction/GetByKeyword/:keyword',
    programCategoryGetByKeyword: '/ProgramCategory/GetByKeyword/:keyword',
    getBatchExceptionsByBatchId: '/BatchException/GetBatchExceptionsByBatchId/:batchId',
    ignoreBatchException: '/BatchException/IgnoreBatchException',
    resolveBatchException: '/BatchException/ResolveBatchException',
    searchCompanies: '/companies/searchCompanies/:query',
    getSharpsProductsForCompany: '/SharpsForm/GetSharpsProductsForCompany',
    saveSharpsForm: '/SharpsForm/SaveSharpsForm/',
    submitSharpsForm: '/SharpsForm/SubmitSharpsForm/',
    recordHistory: '/RecordHistory/Get/',
    getRecordHistoryExcel: '/RecordHistory/GetExcel/',
    memberSubmissions: '/MemberSubmissions/MemberSubmissionsByFilters/',
    getProgramParticipation: '/Companies/GetProgramParticipation/',
    getCompanySettings: '/Companies/GetCompanySettings',
    upsertCompanySettings: '/Companies/UpsertCompanySettings',
    getApplicableForms: '/Companies/GetApplicableForms',
    invoiceHistory: '/Invoice/GetInvoices',
    invoiceItems: '/Invoice/GetInvoiceLineItems',
    getBankingInfo: '/Invoice/GetBankingInfo',
    memberSubmissionSharps: '/MemberSubmissions/GetSharpsFormByCompanyIdAndYear/',
    memberSubmissionSaveSharps: '/MemberSubmissions/SaveSharpsForm/',
    memberSubmissionSubmitSharps: '/MemberSubmissions/SubmitSharpsForm/',
    memberSubmissionOtcPrivateLabel: '/MemberSubmissions/GetOtcPrivateLabelFormByCompanyIdAndYear/',
    memberSubmissionUpdateOtcPrivateLabel: '/MemberSubmissions/UpdateOtcPrivateLabelForm/',
    memberSubmissionRx: '/MemberSubmissions/GetRxDataByCompanyIdAndYear/',
    memberSubmissionUpdateRx: '/MemberSubmissions/UpdateRxData/',
    memberSubmissionSubmitRx: '/MemberSubmissions/SubmitRxForm/',
    memberSubmissionBulkDownloadRx: '/MemberSubmissions/DownloadRxDataByCompanyIdAndYear/',
    memberSubmissionBulkUploadRx: '/MemberSubmissions/UpdateRxDataFromExcelUpload/',
    memberSubmissionOtc: '/MemberSubmissions/GetOtcDataByCompanyIdAndYear/',
    memberSubmissionUpdateOtc: '/MemberSubmissions/UpdateOtcData/',
    memberSubmissionSubmitOtc: '/MemberSubmissions/SubmitOtcForm/',
    memberSubmissionBulkDownloadOtc: '/MemberSubmissions/DownloadOtcDataByCompanyIdAndYear/',
    memberSubmissionBulkUploadOtc: '/MemberSubmissions/UpdateOtcDataFromExcelUpload/',
    unsubmitForm: '/MemberSubmissions/UnsubmitForm',
    exportMemberSubmissions: '/MemberSubmissions/ExportMemberSubmissionsByYear/:year',
    getTotalUnits: '/:vendor/GetTotalUnits',
    getTotalSales: '/:vendor/GetTotalSales',
    getMarketShareCalculationStatus: '/MarketShare/GetMarketShareCalculationStatus',
    startMarketShareCalculation: '/MarketShare/StartMarketShareCalculation',
    getMemberCompanyPrimary: '/Companies/GetMemberCompanyPrimary',
    downloadInvoice: '/Invoice/Download',
    getInvoiceCalculationStatus: '/Invoice/GetInvoiceCalculationStatus',
    startInvoiceCalculation: '/Invoice/StartInvoiceCalculation',
    getInvoicesByFilters: '/Invoice/GetInvoicesByFilters',
    upsertInvoiceAdjustments: '/Invoice/UpsertInvoiceAdjustments',
    invoiceItemsAdmin: '/Invoice/GetInvoiceLineItemsAdmin',
    getMemberCompanyPrimaryAdmin: '/Companies/GetMemberCompanyPrimaryAdmin',
    getCompanyInfoAdmin: '/Companies/GetCompanyInformationAdmin',
    adminDownloadInvoice: '/Invoice/AdminDownload',
    getAllMemberTypes: '/MemberType/GetAll',
    downloadMember: '/:vendor/DownloadMember',
    getInvoiceReleasedStatus: '/Invoice/GetInvoiceReleasedStatus',
    releaseInvoices: '/Invoice/ReleaseInvoices',
    exportInvoicesToExcel: '/Invoice/GetInvoiceExportDataByYear',
    uploadMemberFile: '/:vendor/MemberUpdateDataFromExcelUpload',
    updateInvoicePoNumber: '/Invoice/UpdatePoNumber'
  },
  features: {
    simplifySharpsFormEffectiveDate: '1/1/2023'
  },
  pathRoot: '/',
  hash: {
    root: '/#',
    fakeAuth: '/fakeAuth',
    fakeAuthAdmin: '/fakeAuth/admin',
    fakeAuthMember: '/fakeAuth/member',
    errorWithException: '/error/:error',
    error: '/error/',
    admin: {
      home: '/admin',
      settings: '/admin/settings',
      upload: '/admin/upload',
      viewSubmissions: '/admin/viewSubmissions',
      recordHistory: '/admin/recordHistory',
      invoices: '/admin/invoices'

    },
    member: {
      home: '/member',
      otcData: '/member/otcData',
      rxData: '/member/rxData',
      otcPrivateLabel: '/member/otcPrivateLabel',
      sharps: '/member/sharps',
      invoices: '/member/invoices',
    }
  },
};

export {conf};
