/* @flow */
import React from 'react';
import HistoryRecordTable from '../layouts/Admin/components/HistoryRecordTable';
import {LoggerService} from '../services/LoggerService';
import HistoryRecordService from '../services/HistoryRecordService';
import HistoryRecordFilters from '../layouts/Admin/components/HistoryRecordFilters';
import Typography from '@material-ui/core/Typography';
import Icon from '@material-ui/core/Icon';
import Box from '@material-ui/core/Box';

const logger = LoggerService.getLogger('AdminRecordHistory');
const styles = theme => ({
  tableMargin: {
    margin: '20px 0px 0px 0px',
  },
  textMargin: {
    margin: '5px 5px 30px 5px',
    padding: '3px 0px 0px 0px',
  }
});

type Props = {||};

type State = {|
  actionType: string,
  startDateRange: string,
  endDateRange: string,
  service: string,
  totalRecords: number
|}

export default class AdminRecordHistory extends React.Component<Props, State> {
  state:State = {
    actionType: 'All',
    startDateRange: '',
    endDateRange: '',
    service: 'Products (Admin & Member)',
    totalRecords: 0
  };

  exportToExcel(filters:State) {
      HistoryRecordService.exportToExcel(filters);
  }

  runReport(filters:State){
    this.setState({...filters});
  }

  render(){
    return (
        <React.Fragment>
          <Box
              display='flex'
          >
            <Icon color={'secondary'}>info</Icon>

            <div className='padding-bottom-5'>
            <Typography  variant='caption' gutterBottom>
              To view an audit trail of changes, click on the RUN REPORT button above. This will
              generate a detailed list of changes made to the data.
            </Typography>
            </div>
          </Box>
          <HistoryRecordFilters
              runReport={this.runReport.bind(this)}
              filters={this.state}
              exportToExcel={this.exportToExcel.bind(this)}
              totalRecords={this.state.totalRecords}
          />
          <div className={styles.tableMargin}>
            <HistoryRecordTable recordFilter={this.state} setTotalRecords={(totalRecords) => this.setState({ totalRecords: totalRecords})}/>
          </div>
        </React.Fragment>
    );
  }
}
