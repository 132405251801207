/* @flow */

import React from 'react';
import MaterialTable from 'material-table';
import ProgramParticipationDialog from './ProgramParticipationDialog';
import Chip from '@material-ui/core/Chip';
import type {
  ProgramName,
  ProgramNames,
  ProgramsAffiliates,
  TotalPrograms
} from '../../../services/ProgramParticipationService';
import {ProgramParticipationService} from '../../../services/ProgramParticipationService';
import type {MaterialPage} from '../../../services/Utility/ServiceUtil';
import { JSXElement } from '@babel/types';
import MemberTypeService from '../../../services/MemberTypeService';
import type { MemberType } from '../../../services/MemberTypeService';

type ProgramsAffiliatesChips = {|
  id: number,
  name: string,
  programChips: Array<JSXElement>,
  affiliatedCompanyChips: Array<JSXElement>
|};

type Props = {||};
type State = {|
  open: boolean,
  companyId: number,
  pageSize: number,
  memberTypes: Array<MemberType>
|};

class ProgramParticipateTable extends React.Component<Props, State> {
  state = {
    open: false,
    companyId: 0,
    pageSize: 5,
    memberTypes: []
  };

  tableRef = React.createRef();

  componentDidMount(): void
{
    MemberTypeService.getAllMemberTypes()
      .then(results => this.setState({ memberTypes: results}));
}

  columns = [
    {title: 'Company Name', field: 'name'},
    {title: 'Program Participation', field: 'programChips'},
    {title: 'Affiliated Companies', field: 'affiliatedCompanyChips'}
  ];

  getAmountRemainingEl = (amountRemaining: number): JSXElement => {
    return <span key='remaining'>+{amountRemaining}</span>;
  };

  makeChip = (x: ProgramName, index: number): JSXElement => {
    return <Chip
        variant='outlined'
        size='small'
        color='secondary'
        key={index}
        label={x}
        component={'div'}
    />;
  };

  makeChips = (names: ProgramNames, totalPrograms: TotalPrograms): Array<JSXElement> => {
    names = names || [];

    const chips = names.map(this.makeChip);

    // Add count of remaining items.
    if (totalPrograms > 3) {
      chips.push(this.getAmountRemainingEl(totalPrograms - 3));
    }

    return chips;
  };

  formatProgramsAffiliates = (programAffPage: MaterialPage<ProgramsAffiliates>): MaterialPage<ProgramsAffiliatesChips> => {
    if (!programAffPage || !programAffPage.data) {
      return programAffPage;
    }

    return {
      page: programAffPage.page,
      totalCount: programAffPage.totalCount,
      data: programAffPage.data.map((prog: ProgramsAffiliates): ProgramsAffiliatesChips => {
        return {
          id: prog.id,
          name: prog.name,
          programChips: this.makeChips(prog.programs, prog.totalPrograms),
          affiliatedCompanyChips: this.makeChips(prog.affiliatedCompanies, prog.totalAffiliatedCompanies)
        };
      })
    };
  };

  handleClickOpen = (event: Event, id: number): void => {
    this.setState({
      open: true,
      companyId: id
    });
  };

  handleClose = (event: Event, reason: string): void => {
    this.setState({
      open: false,
      companyId: reason === 'SaveCompleted' ? -1 : this.state.companyId
    });
    // Refresh the material table.
    if (reason === 'SaveCompleted') {
      this.tableRef && this.tableRef.current.onQueryChange();
    }
  };

  render() {
    return (
        <div id='programParticipationTable'>
        <ProgramParticipationDialog open={this.state.open}
          handleClose={this.handleClose}
          companyId={this.state.companyId}
          memberTypes={this.state.memberTypes}
          />
          <MaterialTable
              style={{
                padding: '0px',
              }}
              title={''}
              columns={this.columns}
              tableRef={this.tableRef}
              data={
                query => {
                  return ProgramParticipationService.getProgramParticipation(query)
                  .then(this.formatProgramsAffiliates);
                }
              }
              options={{
                actionsColumnIndex: -1,
                debounceInterval: 1000,
                sorting: false,
                filtering: false,
                pageSize: this.state.pageSize,
                emptyRowsWhenPaging: false,
                rowStyle: {
                  padding: '0px',
                },
              }}
              actions={[
                {
                  icon: 'remove_red_eye',
                  tooltip: 'View',
                  onClick: (event: Event, row: { id: number }) => this.handleClickOpen(event, row.id)
                },
                {
                  icon: 'add_box',
                  tooltip: 'Add Company',
                  isFreeAction: true,
                  onClick: (event: Event) => this.handleClickOpen(event, 0)
                }
              ]}
          />
        </div>
    );
  }
}

export default ProgramParticipateTable;
