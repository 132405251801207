/* @flow */

import {conf} from '../config';
import {HttpService} from './HttpService';
import {paramsError} from './Utility/ServiceUtil';
import {LoggerService} from './LoggerService';
import StringUtils from './Utility/StringUtils';

export type HistoryRecord = {|
  user: string,
  companyId: string,
  action: string,
  service: string,
  field: string,
  date: string,
  currentValue: string,
  previousValue: string,
  record: string,
|};

export type HistoryRecordResponse = {|
  data: Array<HistoryRecord>,
  count: number,
|};

export type HistoryRecordFilter = {|
  startDateRange: string,
  endDateRange: string,
  actionType: string,
  service: string,
  page?: number,
  excel: boolean
|};

const logger = LoggerService.getLogger('HistoryRecordService');

export default class HistoryRecordService {

  static postRecordFilter(recordFilter: HistoryRecordFilter): Promise<HistoryRecordResponse> {
    if (!recordFilter) {
      logger.error('Missing required params.');
      return paramsError();
    }
    if (!StringUtils.isBlank(recordFilter.startDateRange)){
      recordFilter.startDateRange = new Date(recordFilter.startDateRange).toUTCString();
    }
    if (!StringUtils.isBlank(recordFilter.endDateRange)){
      recordFilter.endDateRange = new Date(recordFilter.endDateRange).toUTCString();
    }
    return HttpService.post(conf.api.recordHistory, {
      data: recordFilter,
      httpTimeout: conf.httpRecordHistoryTimeout
    }).toPromise();
  }

  static exportToExcel(recordFilter: HistoryRecordFilter): Promise<void> {
    if (!recordFilter) {
      logger.error('Missing required params.');
      return paramsError();
    }
    if (!StringUtils.isBlank(recordFilter.startDateRange)){
      recordFilter.startDateRange = new Date(recordFilter.startDateRange).toUTCString();
    }
    if (!StringUtils.isBlank(recordFilter.endDateRange)){
      recordFilter.endDateRange = new Date(recordFilter.endDateRange).toUTCString();
    }
    return HttpService.postFileDownload(conf.api.getRecordHistoryExcel, {
      data: recordFilter,
      httpTimeout: conf.httpRecordHistoryTimeout
    }).toPromise();
  }
}
