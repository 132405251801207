/* @flow */

import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import {JSXElement} from '@babel/types';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import DateUtils from '../../../services/Utility/DateUtils';
import InvoiceService from '../../../services/InvoiceService';

const styles = () => ({

  title: {
    fontSize: 18,
  },
  pos: {
    marginBottom: 2,
  },
  info: {
    display: 'flex',
    flexDirection: 'column',

  },
  border: {
    border: '1px solid #DFE3E8;',
    borderRadius: '4px',
    boxShadow: '0 0 0 0 rgba(255, 105, 135, .3)',
    margin: '5px 0px 0px 0px',
    backgroundColor: '#ffffff',
  },
  padding: {
    padding: '10px 0px',
    textAlign: 'right'
  },
});

type Props = {|
  companyId: number,
  classes: ClassNameMap,
  invoiceId: number,
  dueDate: string,
  createdDate: string,
  customerPoNumber: string
|};
type State = {||};

class InvoiceDataPoints extends React.Component<Props, State> {

  onPoNumberChange(event): void {
      InvoiceService.updateInvoicePoNumber(this.props.invoiceId, this.props.companyId, event.target.value);
      this.props.onPoNumberChange && this.props.onPoNumberChange(event.target.value);
  }

  render(): JSXElement {
    const {classes} = this.props;
    return (

        <ListItem className={classes.border}  alignItems="flex-start">

          <ListItemText
              secondary={
                <React.Fragment>
                  <Typography
                      component="span"
                      variant="body2"
                      className={classes.inline}
                      color="textPrimary"
                  >
                  </Typography>
                  <div className={classes.info}>
                    <p className={classes.padding}>Invoice #{this.props.invoiceId}</p>
                    <p className={classes.padding}>Invoice Date: {this.props.createdDate !== null ? DateUtils.formatDate('MM/DD/YYYY',DateUtils.createFromLocalDateString(this.props.createdDate)) : ''}</p>
                    <p className={classes.padding}>Due Date: {this.props.dueDate !== null ? DateUtils.formatDate('MM/DD/YYYY',DateUtils.createFromLocalDateString(this.props.dueDate)) : ''}</p>
                    <TextField
                        id='ponumber'
                        label='PO Number'
                        key={this.props.customerPoNumber}
                        className={classes.poNumberField}
                        defaultValue={this.props.customerPoNumber}
                        onBlur={evt => this.onPoNumberChange(evt)}
                        margin='normal'
                        variant='outlined'
                    />
                  </div>

                </React.Fragment>
              }
          />
        </ListItem>
    );
  }
}

export default withStyles(styles)(InvoiceDataPoints);
