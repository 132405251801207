/* @flow */

import React from 'react';
import {JSXElement} from '@babel/types';
import {Redirect, withRouter} from 'react-router';
import type {User} from '../services/AuthService';
import AuthService from '../services/AuthService';
import {conf} from '../config';
import {LoggerService} from '../services/LoggerService';

const logger = LoggerService.getLogger('FakeAuth');

type Props = {|
  location: any
|};
type State = {|
  user: User
|};

class FakeAuth extends React.Component<Props, State> {
  state = {
    user: null
  };

  componentDidMount(): void {
    const path = this.props.location.pathname;
    const admin = path === conf.hash.fakeAuth || path === conf.hash.fakeAuthAdmin;

    AuthService.fakeLogin(admin).then(() => {
      this.setState({
        user: AuthService.getUser()
      });
    }).catch((err) => {
      logger.error('Could not login:', err);
    });
  }

  render(): JSXElement {
    if (!this.state.user) {
      return null;
    }

    return (
        AuthService.isAdmin() ? <Redirect to={conf.hash.admin.home}/> : <Redirect to={conf.hash.member.home}/>
    );
  }
}

export default withRouter<any>(FakeAuth);
