import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import SharpsDataDateNotification from '../components/SharpsDataDateNotification';
import DateUtils from '../../../services/Utility/DateUtils';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles(theme => ({
  grow: {
    flexGrow: 1,
  },

  root: {
    background: '#ffffff',
    padding: '0px',
    borderRadius: 3,
    color: '#000000',
    margin: '0px 0px 20px 0px',
  },

  widthFull: {
    width: '100%',
  },
  title: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },

  textField: {
    position: 'relative',
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1),
    width: '100%',
  },
  datePicker: {
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1)
  },
  inputRoot: {
    color: 'inherit',
  },

}));
function SharpsSelfReportTopNav(props) {
    const classes = useStyles();
    let submitted = props.dateSubmitted ? DateUtils.formatDate("YYYY-MM-DD", DateUtils.createFromLocalDateString(props.dateSubmitted)) : DateUtils.dateTimeNow();
    if (props.companyName !== "") {
        return (
            <React.Fragment>
                <AppBar className={classes.root} position='static'>
                    <Toolbar>
                        <Grid
                            container
                            alignItems='center'
                            display='flex'
                            flexwrap='wrap'
                            bgcolor='#ffffff'
                            boxshadow={1}
                            spacing={1}
                        >
                            <Grid item xs={12} lg={3}>
                                <Typography className={classes.title} variant='h4' noWrap>
                                    Sharps Self-Reporting Form
                </Typography>
                            </Grid>

                            <Grid item xs={12} lg={7}>
                                <form noValidate autoComplete='off'>
                                    <TextField
                                        className={classes.textField}
                                        id='company-name'
                                        label='Company Name'
                                        placeholder={props.companyName}
                                        value={props.companyName}
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                        variant='outlined'
                                        margin='normal'
                                    />
                                </form>
                            </Grid>

                            <Grid item xs={6} lg={2}>
                                <TextField
                                    id='date'
                                    label='Date'
                                    type='date'
                                    disabled
                                    defaultValue={submitted}
                                    className={classes.datePicker}
                                    margin='normal'
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    variant='outlined'
                                />
                            </Grid>
                        </Grid>
                    </Toolbar>
                </AppBar>
            </React.Fragment>
        );
    }else return null
}

export default SharpsSelfReportTopNav;
