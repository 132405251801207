import React from 'react';
import MaterialTable from 'material-table';
import {makeStyles} from '@material-ui/core/styles';
import ProgramService from '../../../services/ProgramService.js';
import {TextField} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import AsyncSelect from 'react-select/async';
import DialogContentText from '@material-ui/core/DialogContentText';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import background from '../../../theme/palette';
import StringUtils from '../../../services/Utility/StringUtils';

const pageSize = 10;

const useStyles = makeStyles(theme => ({
  margFive: {
    margin: '10px 0 0 0'
  }
}));

function ProgramManageTable() {
  const classes = useStyles();

  const [newProgramName, setNewProgramName] = React.useState(null);
  const [newProgramCategory, setNewProgramCategory] = React.useState(null);
  const [newProgramJurisdiction, setNewProgramJurisdiction] = React.useState(
      null);
  const [open, setOpen] = React.useState(false);

  function handleClickOpen() {
    setOpen(true);
  }

  function handleClose() {
    setOpen(false);
  }

  function handleProgramSave() {
    ProgramService.createProgram({
      ProgramJurisdictionId: newProgramJurisdiction,
      ProgramCategoryId: newProgramCategory,
      Name: newProgramName
    });
    setOpen(false);

  }

  function getColumns() {
    return ([
      {title: 'id', field: 'id', hidden: true},
      {title: 'Program Name', field: 'name'},
      {title: 'Jurisdiction', field: 'jurisdiction', editable: 'never'},
      {title: 'Category', field: 'category', editable: 'never'},
      {
        title: 'Status',
        field: 'isActive',
        lookup: {true: 'Active', false: 'Inactive'},
      },
    ]);
  }

  const categoryOptions = (value) => {
    return ProgramService.getProgramCategories(value)
    .then((res) => {
      return (res || []).map(val => {
        return {value: val.id, label: val.name};
      });
    });
  };

  const handleProgramJurisdictionChange = (value) => {
    return ProgramService.getProgramJurisdictions(value)
    .then((res) => {
      return (res || []).map(val => {
        return {value: val.id, label: val.name};
      });
    });
  };
  const touchedFields = {};
  const markTouched = (field: string): void => {
    touchedFields[field] = true;
  };

  const checkError = (field: string, value: string): JSXElement => {
    if (touchedFields[field] && StringUtils.isBlank(value)) {
      return <DialogContentText color='error' className={classes.margFive}>
        This field is required.
      </DialogContentText>;
    }

    return null;
  };
  const isDisabled = () => {
    return (
        newProgramName === null ||
        newProgramName === '' ||
        newProgramJurisdiction === null ||
        newProgramCategory === null
    );
  };

  return (
      <React.Fragment>
        <Dialog open={open} onClose={handleClose} maxWidth='md' fullWidth={true}
                aria-labelledby='form-dialog-title' id='addProgram'>
          <Box
              display='flex'
              flexWrap='nowrap'
              justifyContent='center'
              bgcolor={background.paper}
          >
            <Typography variant='h4' color='secondary'>
              Add Program
            </Typography>
          </Box>
          <DialogContent>
            <TextField
                autoFocus
                id='addProgramName'
                label='Program Name'
                type='Program Name'
                onChange={(e) => setNewProgramName(e.target.value)}
                onBlur={markTouched('name')}
            />
            {checkError('name', newProgramName)}
            <DialogContentText color='secondary' className={classes.margFive}>
              Please enter a Category.
            </DialogContentText>
            <AsyncSelect
                id='programCategorySelect'
                autoFocus
                margin='dense'
                label='Search for a Program Category by Name'
                placeholder='Type at least 3 characters to search.'
                cacheOptions
                defaultOptions
                loadOptions={categoryOptions}
                onChange={e => setNewProgramCategory(e.value)}
                onBlur={markTouched('category')}
            />
            {checkError('category', newProgramCategory)}
            <DialogContentText color='secondary' className={classes.margFive}>
              Please enter a Jurisdiction.
            </DialogContentText>
            <AsyncSelect
                id='programJurisdictionSelect'
                autoFocus
                margin='dense'
                label='Search for a Program Jurisdiction by Name'
                placeholder='Type at least 3 characters to search.'
                cacheOptions
                defaultOptions
                loadOptions={handleProgramJurisdictionChange}
                onChange={e => setNewProgramJurisdiction(e.value)}
                onBlur={markTouched('jurisdiction')}
            />
            {checkError('jurisdiction', newProgramJurisdiction)}
          </DialogContent>
          <DialogActions>
            <Button id='addProgramCancel' onClick={handleClose} color='primary' href={''}>
              Cancel
            </Button>
            <Button disabled={isDisabled()} onClick={handleProgramSave} color='primary' href={''}>
              Submit
            </Button>
          </DialogActions>
        </Dialog>
        <MaterialTable
            style={{
              padding: '0px',
            }}
            options={{
              sorting: false,
              debounceInterval: 1000,
              actionsColumnIndex: -1,
              pageSize: pageSize,
              emptyRowsWhenPaging: false
            }}
            title=''
            columns={getColumns()}

            data={query => ProgramService.getPrograms(query)}
            editable={{
              onRowUpdate: (newData, oldData) => ProgramService.updateProgram(newData),
            }}
            actions={[
              {
                icon: 'add_box',
                id: 'pgm-mgt-add-program',
                tooltip: 'Add Program',
                isFreeAction: true,
                onClick: () => handleClickOpen()
              }
            ]}
        />
      </React.Fragment>
  );
}

export default ProgramManageTable;
