import React, {useEffect} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import DueDateService from '../../../services/DueDateService';
import DateUtils from '../../../services/Utility/DateUtils';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
}));

function ReleaseDatepicker(props) {
  const [dueType] = React.useState(props.dueType);
  const [date, setDate] = React.useState(null);

  const classes = useStyles();

  function handleChange(event) {
    props.handleChange(dueType, event.target.value);
  }

  useEffect(() => {
    DueDateService.getDueDateByType(dueType).then(r => {
      setDate(DateUtils.formatDate('YYYY-MM-DD', r));
    });
  }, [dueType]);

  if (!date) {
    return null;
  }

  return (
      <form className={classes.container} noValidate>
        <TextField
            id={'date-' + dueType}
            label=''
            type='date'
            defaultValue={date}
            className={classes.textField}
            InputLabelProps={{
              shrink: true,
            }}
            onBlur={evt => handleChange(evt)}
        />
      </form>
  );
}

export default ReleaseDatepicker;
