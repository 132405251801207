/* @flow */

import { conf } from '../config';
import { HttpService } from './HttpService';
import type { MaterialPage, MaterialPageQuery, VendorProduct } from './Utility/ServiceUtil';
import { paramsError, resToTablePage } from './Utility/ServiceUtil';
import type { Vendor } from './ProductService';
import { Vendors } from './ProductService';
import { LoggerService } from './LoggerService';
import type { OtcForm } from './MemberEditService';

export type MemberSubmission = {|
    companyId: number,
        companyName: string,
            submissionDate: string,
                type: string,
                    status: string,
                        year: number,
|};

export type MemberSubmissionFilter = {|
    startYear: number,
        endYear: number,
            keyword: string,
                page: number,
                    types: Array < string >,
                        statuses: Array < string >,
|};

export type MemberSubmissionResponse = {|
    data: Array < MemberSubmission >,
        hasNextPage: boolean,
|};

const logger = LoggerService.getLogger('MemberSubmissionService');

export default class MemberSubmissionService {
    static postSubmissionFilter(submissionFilter: MemberSubmissionFilter): Promise<MemberSubmissionResponse> {
        if (!submissionFilter) {
            logger.error('Missing required params.');
            return paramsError();
        }

        return HttpService.post(conf.api.memberSubmissions, {
            data: submissionFilter,
            httpTimeout: conf.httpMemberSubmissionsTimeout
        }).toPromise();
    }

    static unsubmitForm(companyId: number, year: number, type: string): Promise<void> {
        if (!(companyId > 0) || !(year > 0) || !type) {
            logger.error('Missing required params.');
            return paramsError();
        }

        return HttpService.put(conf.api.unsubmitForm, {
            queryParams: { companyId: companyId, year: year, type: type }
        }).toPromise();
    }

    static getSelfReportedOtc(companyId: number, year: number): Promise<OtcForm> {
        if (!(companyId > 0) || !(year > 0)) {
            logger.error('Missing required params.');
            return paramsError();
        }

        return HttpService.get(conf.api.memberSubmissionOtcPrivateLabel, {
            queryParams: { companyId: companyId, year: year }
        }).toPromise();
    }

    static postSelfReportedOtc(selfReportOtcForm: OtcForm): Promise<void> {
        return HttpService.post(conf.api.memberSubmissionUpdateOtcPrivateLabel, {
            data: selfReportOtcForm
        }).toPromise();
    }

    static getMemberByCompanyId(vendor: Vendor, year: number, companyId: number, query: MaterialPageQuery):
        Promise<MaterialPage<VendorProduct>> {
        if (!(vendor in Vendors) || !query) {
            return paramsError();
        }

        const url = vendor === Vendors.Iqvia ? conf.api.memberSubmissionRx : conf.api.memberSubmissionOtc;
        return HttpService.get(url, {
            queryParams: {
                companyId: companyId,
                year: year,
                page: query.page,
                pageSize: query.pageSize
            }
        })
            .toPromise()
            .then(resToTablePage(query.page));
    }

    static updateMemberData(vendor: Vendor, product: VendorProduct): Promise<void> {
        if (!(vendor in Vendors)) {
            return paramsError();
        }

        const url = vendor == Vendors.Iqvia ? conf.api.memberSubmissionUpdateRx : conf.api.memberSubmissionUpdateOtc;

        return HttpService.put(url, {
            data: product
        }).toPromise();
    }

    static submitMemberReviewForm(vendor: Vendor, year: number, companyId: number): Promise<void> {
        if (!(vendor in Vendors)) {
            return paramsError();
        }

        const url = vendor == Vendors.Iqvia ? conf.api.memberSubmissionSubmitRx : conf.api.memberSubmissionSubmitOtc;

        return HttpService.put(url, {
            data: {
                year: year,
                companyId: companyId
            }
        }).toPromise();
    }

    static exportToExcel(vendor: Vendor, year: number, companyId: number): Promise<void> {
        if (!(vendor in Vendors) || !year || !companyId) {
            logger.error('Missing required params.');
            return paramsError();
        }

        const url = vendor == Vendors.Iqvia ? conf.api.memberSubmissionBulkDownloadRx : conf.api.memberSubmissionBulkDownloadOtc;

        return HttpService.getFileDownload(url, {
            queryParams: {
                'year': year,
                'companyId': companyId
            }
        }).toPromise();
    }

    static uploadMemberFile(file: File, vendor: Vendor, year: number, companyId: number): Promise<void> {
        if (!file || !(vendor in Vendors) || !year || !companyId) {
            logger.error('Missing required params.');
            return paramsError();
        }

        const url = vendor == Vendors.Iqvia ? conf.api.memberSubmissionBulkUploadRx : conf.api.memberSubmissionBulkUploadOtc;

        const formData = new FormData();
        formData.append('body', file);

        return HttpService.put(url, {
            data: formData,
            queryParams: {
                'year': year,
                'companyId': companyId
            },
            httpTimeout: conf.httpUploadTimeout,
            httpRetryLimit: 0
        }).toPromise();
    }
    
    static exportMemberSubmissions(year: number): Promise<void> {
        if (!year) {
            logger.error('Missing required params.');
            return paramsError();
        }
        return HttpService.postFileDownload(conf.api.exportMemberSubmissions, {
            pathParams: {
                'year': year
            },
            httpTimeout: conf.httpMemberSubmissionsExcelTimeout
        }).toPromise();
    }
}
