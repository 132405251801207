import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import MemberEditTable from '../layouts/Member/components/MemberEditTable';
import FilteredDataDateNotification from '../layouts/Member/components/FilteredDataDateNotification';
import AuthService from '../services/AuthService';
import Box from '@material-ui/core/Box';
import Icon from '@material-ui/core/Icon';
import { Typography } from '@material-ui/core';
import { ProductTypes, ProductVendor } from '../services/ProductService';
import DateUtils from '../services/Utility/DateUtils';
import { MemberEditService } from '../services/MemberEditService';
import StringUtils from '../services/Utility/StringUtils';
import DueDateService from '../services/DueDateService.js';
import { DueTypes } from '../services/DueDateService';

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },

    snackbar: {
        width: '600',
        margin: theme.spacing(1),
    },
    infoText: {
        margin: '0px 5px 15px 5px',
        fontWeight: 300,
    },

}));

function MemberOTCDataTab(props) {
    const [isSubmitted, setIsSubmitted] = React.useState(false);
    const [dateSubmitted, setDateSubmitted] = React.useState(null);
    const classes = useStyles();
    const user = AuthService.getUser();
    const companyId = user ? user.companyId : 0;
    const [hasProducts, setHasProducts] = React.useState(true);
    const [dueDate, setDueDate] = React.useState(null);
    const isPastDueDate = dueDate && dueDate < new Date();

    useEffect(() => {
        if (companyId > 0) {
            MemberEditService.getReviewFormSubmitted(ProductVendor['Otc'])
                .then((dateSubmitted: Date) => {
                    if (dateSubmitted) {
                        setIsSubmitted(true);
                        setDateSubmitted(dateSubmitted);
                    }
                });
        }
    }, [companyId, isSubmitted]);

    useEffect(() => {
        DueDateService.getDueDateByType(DueTypes.otcData).then(result => {
            setDueDate(result);
        });
    }, [companyId]);

    return (
        <div className={classes.root}>
            <Card className={classes.card}>
                {hasProducts ?
                    <React.Fragment>
                        <Box
                            display='flex'
                        >
                            <Icon color={'secondary'}>info</Icon>


                            <Typography className={classes.infoText}>
                                This data is from Nielsen and is a list of the products manufactured, units sold,
                                and gross sales revenue attributed to your company from
                                1/1/{DateUtils.getCurrentYear() - 1} through 12/31/{DateUtils.getCurrentYear() - 1}.
                                This information will be used to determine your company’s share of program costs
                                that will be allocated to producers of branded over-the-counter (OTC) products.

                                Please review the list of products and indicate if your company does not consider
                                them “covered products” or if your company no longer manufactures these products.
                                To mark a product as “Not Covered”, click the “Edit” button on the far-right side of
                                the page next to the product, uncheck the “Covered” box, and click “Save”.
                            </Typography>

                        </Box>
                        <FilteredDataDateNotification dueDate={dueDate} isSubmitted={isSubmitted}
                            dateSubmitted={dateSubmitted} />
                        <MemberEditTable source='Otc' company={companyId} setHasProducts={setHasProducts}
                            isSubmitted={isSubmitted || isPastDueDate}
                            setIsSubmitted={setIsSubmitted} />
                    </React.Fragment>
                    :
                    <Typography className={classes.infoText}>
                        According to the Nielsen Data source PPSWG used, your company has no over the counter data reported
                        for calendar year {DateUtils.getCurrentYear() - 1}. Therefore, it will not be invoiced for this
                        portion of the program.
                    </Typography>
                }
            </Card>
        </div>
    );
}

export default MemberOTCDataTab;
