import React from 'react';
import { withStyles } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';

const styles = theme => ({
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
});

type Props = {|
  classes: ClassNameMap,
  id: string,
  label: string,
  value: string,
  onChange: (value: string) => string,
  disabled: boolean
    |}

class TextBox extends React.Component<Props> {

  handleUnitInputChange = (event: object): void => {
    this.props.onChange(event.target.value);
  };

  render() {
    const { classes, id, label, value, disabled } = this.props;

    return (
      <TextField
        id={ id }
        label={ label }
        className={classes.textField}
        value={ value }
        onChange={this.handleUnitInputChange}
        margin='normal'
        disabled={disabled}
      />
    );

  }
}

export default withStyles(styles)(TextBox);
