import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import InvoicesTable from '../layouts/Member/components/InvoicesTable';
import InvoiceDialog from '../layouts/Member/components/InvoiceDialog';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  snackbar: {
    width: '600',
    margin: theme.spacing(1),
  },
}));

function Invoices() {
  const classes = useStyles();

  return (
      <div className={classes.root}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Card className={classes.card}>
              <InvoicesTable/>
            </Card>
          </Grid>
        </Grid>
      </div>
  );
}

export default Invoices;
