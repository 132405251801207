/* @flow */
import React from 'react';
import {makeStyles, withStyles,} from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Dividers from '../../../components/DataDisplay/Divider';
import CurrencyUtils from '../../../services/Utility/CurrencyUtils';

const useStyles = theme => ({
  root: {
    flexGrow: 1,
    maxWidth: 752,
  },
  demo: {
    backgroundColor: theme.palette.background.paper,
  },
  title: {
    margin: theme.spacing(4, 0, 2),
  },
  alignRight: {
    textAlign: 'right'
  }
});

type Props = {|
  summaryItems: Array<any>
|};

type State = {||};

class InvoiceSummaryTable extends React.Component<Props, State> {

  render() {
    const {classes} = this.props;
    return (
        <div className={''}>
          <Box
              display='flex'
              justifyContent='flex-end'
          >
            <List>
              <Box
                  display='flex'
                  justifyContent='flex-end'
              >
                <Grid container sm={6}>
                  {this.props.summaryItems.map((elm) => {
                    return (
                        <React.Fragment>
                          <ListItem>
                            {elm.description === 'Invoice Total' ?
                                <Grid container>
                                  <Grid item sm={8}>
                                    <ListItemText
                                        className={classes.alignLeft}
                                        primary={`${elm.description}:`}
                                    />
                                  </Grid>
                                  <Grid item sm={4}>
                                    <ListItemText
                                        className={classes.alignRight}
                                        primary={CurrencyUtils.formatCurrency(elm.amount)}
                                    />
                                  </Grid>
                                </Grid>
                                :
                                <Grid container>
                                  <Grid item sm={8}>
                                    <ListItemText
                                        className={classes.alignLeft}
                                        secondary={`${elm.description}:`}
                                    />
                                  </Grid>
                                  <Grid item sm={4}>
                                    <ListItemText
                                        className={classes.alignRight}
                                        secondary={CurrencyUtils.formatCurrency(elm.amount)}
                                    />
                                  </Grid>
                                </Grid>
                            }
                          </ListItem>
                          <Dividers/>
                        </React.Fragment>
                    );
                  })}
                </Grid>
              </Box>
            </List>
          </Box>
        </div>

    );
  }
}

export default withStyles(useStyles)(InvoiceSummaryTable);