/* @flow */

export const LOG_SEVERITY = Object.freeze({
  DEFAULT: 'DEFAULT',
  DEBUG: 'DEBUG',
  INFO: 'INFO',
  NOTICE: 'NOTICE',
  WARNING: 'WARNING',
  ERROR: 'ERROR',
  CRITICAL: 'CRITICAL',
  ALERT: 'ALERT',
  EMERGENCY: 'EMERGENCY'
});

export type LogSeverity = $Keys<typeof LOG_SEVERITY>;

export type LogEntry = {
  severity: LogSeverity;
  payload: string;
};

export type LogEntryRequest = {|
  data: {|
    type: string,
    description: string
  |}
|};
