import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Card } from '@material-ui/core';
import { ClassNameMap } from '@material-ui/styles/withStyles';
import Icon from '@material-ui/core/Icon';
import Box from '@material-ui/core/Box';

const styles = theme => ({
    root: {
        padding: theme.spacing(3, 2),
    },
    icon: {
        fontSize: '18px',

    }
});

type Props = {|
    classes: ClassNameMap,
|};
type State = {||};

class InvoiceDisclaimer extends React.Component<Props, State> {

    render() {
        const { classes } = this.props;

        return (
            <div>
                <Card className={classes.root}>
                    <Typography variant='caption' color={'textSecondary'}>

                        <Icon className={classes.icon} color={'secondary'}>info</Icon>

                        Membership in PPSWG requires payment in full of PPSWG Membership Dues and any applicable
                        MED-Project Program Fees. Your MED-Project Program Fees are based on the programs that
                        you selected during your Open Enrollment Period. Please review and notify PPSWG within
                        60 days of your invoice date to address any issues that may result in an invoice adjustment.
                        No adjustments will be made after the invoice due date.  In accordance with the PPSWG By-laws,
                        the PPSWG Invoice and Payment Policy, and the acceptance of your Terms & Conditions, your
                        company is liable for PPSWG Membership Dues and MED-Project Program Fees allocated and
                        invoiced for participation in the subsequent year's elected programs. Once payment is received,
                        payments for PPSWG Membership Dues and MED-Project Program Fees are non-refundable. Failure to
                        pay assessed PPSWG Membership Dues and MED-Project Program Fees in accordance with the PPSWG
                        Invoice and Payment Policy may result in termination of membership.

                        <Box fontWeight='fontWeightBold' mt={2}>
                            Contact PPSWG at (202) 495-3131 or finance@ppswg.org with questions about this invoice.
                        </Box>
                    </Typography>
                </Card>
            </div>
        );
    }
}

export default withStyles(styles)(InvoiceDisclaimer);
