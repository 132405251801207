import React, {useEffect} from 'react';
import MaterialTable from 'material-table';
import {makeStyles} from '@material-ui/core/styles';
import {conf} from '../../../config';
import {LoggerService} from '../../../services/LoggerService';
import {AdminEditService} from '../../../services/AdminEditService';
import {getColumns} from './AdminMemberColumns';
import SecondaryLineLoader from '../../../components/Utils/SecondaryLineLoader';
import {ProductVendor} from '../../../services/ProductService';
import type {VendorProduct} from '../../../services/Utility/ServiceUtil';
import {BatchStatuses} from '../../../services/BatchDataService';

const logger = LoggerService.getLogger('AdminEditTable');

const useStyles = makeStyles(theme => ({
  root: {
    padding: '0 !important',
  },
}));

function AdminEditTable(props) {
  const classes = useStyles();

  useEffect(() => {
    AdminEditService.getAdminByBatchId(ProductVendor[props.source], props.batchId, 0,conf.defaultRowsPerPage)
    .then(() => {
      props.setCanProgress(props.uploadStatus === BatchStatuses.complete);
    });
  }, [props.uploadStatus]);

  const onRowUpdate = (productMeta: VendorProduct) => {
    productMeta.IsAdmin = true;

    return AdminEditService.updateVendorData(ProductVendor[props.source], productMeta).catch((err) => {
      logger.error('Error updating vendor data:', err);
    });
  };

  if (props.uploadStatus === BatchStatuses.complete) {
    return (
        <MaterialTable
            options={{
              search: false,
              sorting: false,
              pageSize: conf.defaultRowsPerPage,
              pageSizeOptions: [20,50,100],
              toolbar: false,
              actionsColumnIndex: -1,
              emptyRowsWhenPaging: false
            }}
            title={''}
            columns={getColumns(props.source, true)}
            data={query => AdminEditService.getAdminByBatchId(ProductVendor[props.source], props.batchId, query.page, query.pageSize)}
            className={classes.root}
            editable={{
              onRowUpdate: onRowUpdate
            }}
        />
    );
  } else {
    return (<SecondaryLineLoader/>);
  }

}

export default AdminEditTable;
