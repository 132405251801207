/* @flow */

import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import {ClassNameMap} from '@material-ui/styles/withStyles';
import Box from '@material-ui/core/Box';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Button from '@material-ui/core/Button';
import type {InvoiceReleasedStatus} from '../../../services/InvoiceService';

const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    margin: '0 0 15px 0',
    maxWidth: 500,
  },
  button: {
    fontSize: '.7rem',
  }
});

type Props = {|
  classes: ClassNameMap,
  releasedStatus: InvoiceReleasedStatus,
  disabled: boolean,
  releaseInvoices: void => void
|};
type State = {|
  agreeInvoice: boolean
|};

class ReleaseInvoicesAction extends React.Component<Props, State> {
  state: State = {
    agreeInvoice: false
  };

  onCheckInvoice(e: Event, checked: boolean): void {
    this.setState({agreeInvoice: checked});
  };

  getMainText(releasedStatus: InvoiceReleasedStatus) {
    if (releasedStatus === null) {
      return 'Getting Invoice Released Status...';
    }

    if (releasedStatus.isReleased === null) {
      return 'There are no invoices to release.';
    }

    return releasedStatus.isReleased ? 'Invoices are released.' : 'Invoices are not released.';
  }

  render() {
    const {classes, releasedStatus, disabled, releaseInvoices} = this.props;
    const {agreeInvoice} = this.state;

    return <React.Fragment>
      <Box
          display='flex'
          justifyContent='space-around'
          m={2}>
        <p className={classes.toggleText}>{this.getMainText(releasedStatus)}</p>
      </Box>
      {releasedStatus && releasedStatus.isReleased === false ?
          <Box
              display='flex'
              justifyContent='space-around'
              m={2}
          >

            <p className={classes.toggleText}>I AGREE, Release Invoices</p>
            <FormControlLabel
                value='start'
                disabled={disabled}
                control={<Switch id='switchReleaseInvoice' onChange={(e, checked) => this.onCheckInvoice(e, checked)}
                                 color='primary'/>}
                label={''}
            />
            <Button
                id='btnReleaseInvoice'
                variant='contained'
                color='secondary'
                className={classes.button}
                disabled={disabled || !agreeInvoice}
                onClick={releaseInvoices}
            >
              Release Invoices
            </Button>
          </Box>
          : null
      }
    </React.Fragment>;
  }
}

export default withStyles(styles)(ReleaseInvoicesAction);
