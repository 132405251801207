import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

const useStyles = makeStyles(theme => ({
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
}));

function HistoryEndDatePicker(props) {
  const classes = useStyles();

  return (
      <form className={classes.container} noValidate={true}>
        <TextField
            id={props.id}
            label='End Date'
            type='date'
            defaultValue={props.date}
            className={classes.textField}
            onBlur={evt => props.setDate(evt.target.value)}
            InputLabelProps={{
              shrink: true,
            }}
        />
      </form>
  );
}

export default HistoryEndDatePicker;
