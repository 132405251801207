/* @flow */

import {conf} from '../config';
import {HttpService} from './HttpService';
import {paramsError} from './Utility/ServiceUtil';
import {LoggerService} from './LoggerService';
import type {SubmissionStatus} from './MemberEditService';

const logger = LoggerService.getLogger('SharpsService');

export type SharpsIqvia = {|
  companyId: number,
  id: number,
  iqviaDataId: number,
  categoryId: number,
  categoryName: string,
  productName: string,
  corporationName: string,
  manufacturerName: string,
  ndc: string,
  packSize: string,
  yearTRx: string,
  packQuantity: string,
  sectionStep: string,
  subStep: string,
|};

export type SharpsAdditional = {|
  id: number,
  productName: string,
  units: number,
  notes: string,
  sectionStep: string,
  subStep: string
|};

export type SharpsForm = {|
  allIqviaData: Array<SharpsIqvia>,
  step1A: Array<SharpsIqvia>,
  step1B: Array<SharpsIqvia>,
  step1C: Array<SharpsAdditional>,
  step2A: Array<SharpsAdditional>,
  step2B: Array<SharpsAdditional>,
  dueDate: string,
  dateSubmitted: Date,
  dateCreated: Date,
  submitted: boolean,
  companyId: number,
  companyName: string
|};

export default class SharpsService {
    static getSharpsProductsForCompany(): Promise<SharpsForm> {
        return HttpService.get(conf.api.getSharpsProductsForCompany).toPromise();
    };

    static getSelfReportedSharps(companyId: number, year: number): Promise<SharpsForm> {
        if (!(companyId > 0) || !(year > 0)) {
            logger.error('Missing required params.');
            return paramsError();
        }

        return HttpService.get(conf.api.memberSubmissionSharps, {
            queryParams: { companyId: companyId, year: year }
        }).toPromise();
    }

    static AdminSaveSharpsForm(sharpsForm: SharpsForm): Promise<void> {
        if (!sharpsForm) {
            logger.error('Missing required params.');
            return paramsError();
        }

        return HttpService.post(conf.api.memberSubmissionSaveSharps, { data: sharpsForm }).toPromise();
    };

    static AdminSubmitSharpsForm(sharpsForm: SharpsForm): Promise<void> {
        if (!sharpsForm) {
            logger.error('Missing required params.');
            return paramsError();
        }

        return HttpService.post(conf.api.memberSubmissionSubmitSharps, { data: sharpsForm }).toPromise();
    };

    static SaveSharpsForm(sharpsForm: SharpsForm): Promise<void> {
        if (!sharpsForm) {
            logger.error('Missing required params.');
            return paramsError();
        }

        return HttpService.post(conf.api.saveSharpsForm, { data: sharpsForm }).toPromise();
    };

    static SubmitSharpsForm(sharpsForm: SharpsForm): Promise<void> {
        if (!sharpsForm) {
            logger.error('Missing required params.');
            return paramsError();
        }

        return HttpService.post(conf.api.submitSharpsForm, { data: sharpsForm }).toPromise();
    };

    static GetReviewFormSubmitted(): Promise<Date> {
        return HttpService.get(conf.api.getReviewFormSubmitted).toPromise()
            .then((result: SubmissionStatus) => result !== null ? result.dateSubmitted : null);
    };

}
