import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import SharpsSelfReportingStepperTwoA from '../components/SharpsSelfReportingStepperTwoA';
import SharpsSelfReportingStepperTwoB from '../components/SharpsSelfReportingStepperTwoB';
import DateUtils from '../../../services/Utility/DateUtils';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '5%',
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
}));

function SharpsAccordionSectionTwo(props) {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = panel => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
      <div className={classes.root}>
        <ExpansionPanel expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
          <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon/>}
              aria-controls='panel1bh-content'
              id='panel1bh-header'
              href={''}>
            <Typography className={classes.heading}>2A.</Typography>
            <Typography className={classes.secondaryHeading}>
              Companies that produce sharps devices must report their unit sales (INDIVIDUAL units shipped NATIONALLY
              from 1/1/{DateUtils.getCurrentYear() - 1} – 12/31/{DateUtils.getCurrentYear() - 1}) in each of the
              categories listed below.
              This includes devices with built-in safety features or mechanisms (e.g. needles that retract into a
              syringe after use, needles with safety covers, or syringes with safety covers).
              *Note that "Individual units" is not necessarily equivalent to number of packages shipped. Do not report
              the number of packages shipped. For example, one unit = one pen needle, NOT one package/box of pen
              needles.

            </Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <SharpsSelfReportingStepperTwoA submitted={props.submitted} data={props.stepA}
                                            addAdditionalData={props.addAdditionalData}
                                            removeAdditionalData={props.removeAdditionalData}
                                            updateAdditionalData={props.updateAdditionalData}/>
          </ExpansionPanelDetails>
        </ExpansionPanel>
        <ExpansionPanel expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
          <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon/>}
              aria-controls='panel2bh-content'
              id='panel2bh-header'
              href={''}>
            <Typography className={classes.heading}>2B.</Typography>
            <Typography className={classes.secondaryHeading}>
              If your company is the producer of any sharp device not described in the above categories,
              please provide product name and individual units shipped nationally from 1/1/{DateUtils.getCurrentYear()
            - 1} through
              12/31/{DateUtils.getCurrentYear() - 1} in the space provided. Please report individual units shipped, not
              number of packages
              shipped. Any and all devices used to penetrate the skin for drawing blood, or the delivery of medications
              or drugs must be reported.
            </Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <SharpsSelfReportingStepperTwoB submitted={props.submitted} data={props.stepB}
                                            addAdditionalData={props.addAdditionalData}
                                            removeAdditionalData={props.removeAdditionalData}
                                            updateAdditionalData={props.updateAdditionalData}/>
          </ExpansionPanelDetails>
        </ExpansionPanel>
      </div>
  );
}

export default SharpsAccordionSectionTwo;
