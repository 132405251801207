/* @flow */

import {conf} from '../config';
import type {MaterialPage, MaterialPageQuery} from './Utility/ServiceUtil';
import {paramsError, resToTablePage} from './Utility/ServiceUtil';
import {HttpService} from './HttpService';
import {LoggerService} from './LoggerService';
import StringUtils from './Utility/StringUtils';

const logger = LoggerService.getLogger('ProductService');

export type Vendor = 'Iqvia' | 'Nielsen';

export const Vendors = (Object.freeze({
  Iqvia: 'Iqvia',
  Nielsen: 'Nielsen'
}): { [key: Vendor]: Vendor });

export type ProductType = 'Otc' | 'Rx';

export const ProductTypes = (Object.freeze({
  Otc: 'Otc',
  Rx: 'Rx'
}): { [key: ProductType]: ProductType });

export const ProductVendor: { [key: ProductType]: Vendor } = Object.freeze({
  Otc: Vendors.Nielsen,
  Rx: Vendors.Iqvia
});

export type NielsenProduct = {|
  id: number,
  bcSuperCategory: string,
  bcCategory: string,
  bcSubCategory: string,
  categoryId: number,
  bcSegment: string,
  bcSegmentId: number,
  name: string,
  isCovered: boolean
|};

export type IqviaProduct = {|
  id: number,
  prodForm1: string,
  prodForm2: string,
  prodForm3: string,
  categoryId: number,
  ndc: string,
  name: string,
  isCovered: boolean,
  prodForm3Id?: string,
  isInjectable: boolean,
  isBiologic: boolean
|};

export type CoveredProductUpsert = {|
  productId: number,
  name: string,
  isCovered: boolean,
  categoryId?: number,
  isInjectable?: boolean,
  ndc?: string
|};

export type CoveredProduct = IqviaProduct | NielsenProduct;

export type CategorySearchResults = {|
  categoryId: number,
  categoryName: string
|};

export default class ProductService {

  // Gets a page of Rx or Otc products.
  static getCoveredProducts(query: MaterialPageQuery, type: ProductType): Promise<MaterialPage<CoveredProduct>> {
    if (!query || !(type in ProductTypes)) {
      logger.error('Missing required params.');
      return paramsError();
    }

    return HttpService.get(conf.api.getProducts, {
      pathParams: {
        vendor: ProductVendor[type],
        page: query.page,
        rows: query.pageSize
      }
    })
    .toPromise()
    .then(resToTablePage(query.page));
  }

  // Adds or updates a product's name, category, and covered values.
  static upsertProduct(product: CoveredProduct, type: ProductType): Promise<void> {
    if (!product || !(type in ProductTypes)) {
      logger.error('Missing required params.');
      return paramsError();
    }

    const data: CoveredProductUpsert = {
      productId: product.id,
      name: product.name,
      isCovered: product.isCovered
    };

    if (type === ProductTypes.Otc) {
      data.categoryId = (product: any).bcSegmentId;
    } else {
      data.categoryId = (product: any).prodForm3Id;
      data.isInjectable = (product: any).isInjectable;
      data.ndc = (product: any).ndc;
    }

    return HttpService.post(conf.api.upsertProduct, {
      data: data,
      pathParams: {
        vendor: ProductVendor[type]
      }
    }).toPromise();
  }

  // Returns the product categories for the requested type (Rx or Otc) that contain the string, name.
  static searchProductCategory(searchText: string, type: ProductType): Promise<Array<CategorySearchResults>> {
    if (StringUtils.isBlank(searchText)) {
      return Promise.resolve([]);
    }

    if (!(type in ProductTypes)) {
      logger.error('Missing required params.');
      return paramsError();
    }

    return HttpService.get(conf.api.categorySearch, {
      pathParams: {
        type: type,
      },
      queryParams: {
        categoryName: searchText
      }
    }).toPromise()
    .then((res) => {
      return res || [];
    });

  }
}
