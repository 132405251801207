/* @flow */
import {
  DataTypeProvider,
  GroupingState,
  IntegratedGrouping,
  IntegratedSummary,
  SummaryState,
  EditingState
} from '@devexpress/dx-react-grid';
import {
  Grid,
  Table,
  TableGroupRow,
  TableHeaderRow,
  TableSummaryRow,
  TableEditRow,
  TableEditColumn, TableColumnResizing
} from '@devexpress/dx-react-grid-material-ui';
import React from 'react';
import Paper from '@material-ui/core/Paper';
import {ClassNameMap} from '@material-ui/styles/withStyles';
import {withStyles} from '@material-ui/core';
import type {InvoiceLineItem, SummaryItem} from '../../../services/InvoiceService';
import InvoiceHistoryService from '../../../services/InvoiceService';
import {HttpError} from '../../../services/HttpService';
import Button from '@material-ui/core/Button';
import NotificationService, {
  DefaultNotificationMessage,
  NotificationTypes
} from '../../../services/NotificationService';
import TableCell from '@material-ui/core/TableCell';
import TextField from '@material-ui/core/TextField';
import Icon from '@material-ui/core/Icon';
import InvoiceSummaryTable from './InvoiceSummaryTable';
import DateUtils from '../../../services/Utility/DateUtils';
import CurrencyUtils from '../../../services/Utility/CurrencyUtils';

const styles = theme => ({
  root: {
    width: '100%',
  },

  table: {
    minWidth: 450
  },
  saveButton: {
    margin: '10px 10px 0px 0px',
  }
});

type Props = {|
  classes: ClassNameMap,
  invoiceId: number,
  companyId: number,
  isAdmin: boolean,
  createdDate: any,
  year: any
|};

type State = {|
  open: boolean,
  invoiceItems: Array<InvoiceLineItem>,
  summaryItems: Array<SummaryItem>,
  defaultColumnWidths: Array<any>,
  columns: any,
  grouping: any,
  editingRowIds: Array<number>,
  addedRows: Array<any>,
  isSaving: boolean,
  isDownloading: boolean,
  tableColumnExtensions: any
|};

const CurrencyFormatter = ({value}) => CurrencyUtils.formatCurrency(value);
const CurrencyTypeProvider = props => (
    <DataTypeProvider
        formatterComponent={CurrencyFormatter}
        {...props}
    />
);
const editColumnMessages = {
  addCommand: 'Add Adjustment',
  editCommand: () => <Icon>edit</Icon>,
  deleteCommand: () => <Icon>delete</Icon>
};

const blankAdjustmentRow = {
  program: 'Adjustment',
  programCategory: 'Adjustment',
  tier: null,
  priorYearAdjustment: 0
};

const IsAdjustment = (row: InvoiceLineItem): boolean => {
  return row.program === 'Adjustment' && row.programCategory === 'Adjustment';
};

const editableColumns = ['fee', 'type'];

const AmountEditCellBase = ({
  value,
  onValueChange,
  classes
}) => (
    <TableCell>
      <TextField
          required
          type='number'
          error={value.length === 0}
          id='adjustment-amount'
          label='Amount'
          className={classes.textField}
          value={value}
          margin='normal'
          variant='outlined'
          onChange={e => onValueChange(e.target.value)}
      /> </TableCell>
);

const TextEditCellBase = ({
  value,
  onValueChange,
  classes
}) => (
    <TableCell>
      <TextField
          required
          error={value.length === 0}
          id='adjustment-name'
          label='Adjustment Description'
          className={classes.textField}
          value={value}
          margin='normal'
          variant='outlined'
          onChange={e => onValueChange(e.target.value)}
      />
    </TableCell>
);

export const AmountEditCell = withStyles(styles)(AmountEditCellBase);
export const TextEditCell = withStyles(styles)(TextEditCellBase);

const EditCell = (props) => {
  const {column} = props;
  if (column.name === 'fee') {
    return <AmountEditCell {...props} editingEnabled={false}/>;
  }
  if (column.name === 'type') {
    return <TextEditCell {...props} editingEnabled={false}/>;
  }
  // The other columns shouldn't be edited.
  return <TableCell />;
};

const EditCommandCell = (props) => {
  const {row} = props;
  if (IsAdjustment(row)) {
    return <TableEditColumn.Cell {...props} />;
  }
  props = {...props, ...{children: null}};
  return <TableEditColumn.Cell {...props}/>;
};

class InvoiceDialogTable extends React.Component<Props, State> {
  state: State = {
    invoiceItems: [],
    summaryItems: [],
    open: false,
    columns: [
      {name: 'programCategory', title: 'Category'},
      {name: 'program', title: 'Program'},
      {name: 'type', title: 'Product Category' },
      {name: 'priorYearAdjustment', title: 'Prior Year Adjustment' },
      {name: 'marketShareTier', title: `${this.props.year + 1} Tier, Market share by Volume` },
      {name: 'revenueTotalTier', title: `${this.props.year + 1} Tier, Revenue` },
      {name: 'fee', title: `${this.props.year + 1} Participation Fee`},
    ],
    defaultColumnWidths: [
      {columnName: 'programCategory', width: '1%'},
      {columnName: 'program', width: '1%'},
      {columnName: 'type', width: '20%'},
      {columnName: 'priorYearAdjustment', align: 'right', width: '20%' },
      {columnName: 'marketShareTier', align: 'right', width: '30%' },
      {columnName: 'revenueTotalTier', align: 'right', width: '20%' },
      {columnName: 'fee', align: 'right', width: '25%'},
    ],
    tableColumnExtensions: [
      {columnName: 'programCategory'},
      {columnName: 'program'},
      {columnName: 'type'},
      {columnName: 'priorYearAdjustment', align: 'right' },
      {columnName: 'marketShareTier', align: 'right' },
      {columnName: 'revenueTotalTier', align: 'right' },
      {columnName: 'fee', align: 'right'},
    ],
    grouping: [{columnName: 'programCategory'}, {columnName: 'program'}],
    editingRowIds: [],
    addedRows: [],
    isSaving: false,
    isDownloading: false
  };


  fetchInvoiceItems = (callback: () => void): void => {
    const companyId = this.props.isAdmin === true ? this.props.companyId : 0;
    InvoiceHistoryService.getInvoiceItems(this.props.invoiceId, companyId)
    .then((invoiceItemDetails) => {
      this.setState({
        invoiceItems: invoiceItemDetails.lineItems,
        summaryItems: invoiceItemDetails.summaries,
        columns: [
          { name: 'programCategory', title: 'Category' },
          { name: 'program', title: 'Program' },
          { name: 'type', title: 'Product Category' },
          { name: 'priorYearAdjustment', title: 'Prior Year Adjustment' },
          { name: 'marketShareTier', title: `${this.props.year + 1} Tier, Market share by Volume` },
          {name: 'revenueTotalTier', title: `${this.props.year + 1} Tier, Revenue` },
          { name: 'fee', title: `${this.props.year + 1} Participation Fee` },
        ],
        defaultColumnWidths: [
          { columnName: 'programCategory', width: '1%' },
          { columnName: 'program', width: '1%' },
          { columnName: 'type', width: '20%' },
          { columnName: 'priorYearAdjustment', align: 'right', width: '20%' },
          { columnName: 'marketShareTier', align: 'right', width: '30%' },
          {columnName: 'revenueTotalTier', align: 'right', width: '20%' },
          { columnName: 'fee', align: 'right', width: '25%' },
        ],
        tableColumnExtensions: [
          { columnName: 'programCategory' },
          { columnName: 'program' },
          { columnName: 'type' },
          { columnName: 'priorYearAdjustment', align: 'right' },
          { columnName: 'marketShareTier', align: 'right' },
          {columnName: 'revenueTotalTier', align: 'right' },
          { columnName: 'fee', align: 'right' },
        ]
      }, callback);
    })
    .catch((httpError: HttpError) => {
      // TODO handle err
    });
  };

  componentDidMount(): void {
    this.fetchInvoiceItems();
  }

  componentDidUpdate(prevProps: Props, prevState: State): void {
    if (this.props.invoiceId !== prevProps.invoiceId) {
      this.setState({editingRowIds: [], addedRows: [], isSaving: false, isDownloading: false});
      this.fetchInvoiceItems();
    }
  }

  commitChanges = ({added, changed, deleted}) => {
    let changedRows = [];
    const invoiceItems = this.state.invoiceItems;

    // Add rows
    if (added) {
      changedRows = invoiceItems.concat(added);
    }

    // Modify rows
    if (changed) {
      // Merge any changes with the current invoice item.
      changedRows = invoiceItems.map((item, index) => (changed[index] ? {...item, ...changed[index]} : item));
    }

    // Delete rows
    if (deleted) {
      const deletedSet = new Set(deleted);
      changedRows = invoiceItems.filter((row, index) => !IsAdjustment(row) || !deletedSet.has(index));
    }

    this.setState({invoiceItems: changedRows});
  };

  addEditingRows = (editingRows) => {
    let rowsToEdit = [];
    editingRows.forEach((index) => {
      let lineItem = this.state.invoiceItems[index];
      if (IsAdjustment(lineItem)) {
        rowsToEdit.push(index);
      }
    });
    this.setState({editingRowIds: rowsToEdit});
  };

  changeAddedRows = (value) => {
    const initialized = value.map(row => (Object.keys(row).length
        ? {...row, ...blankAdjustmentRow}
        : {...blankAdjustmentRow, ...{fee: 0, type: ''}}));

    this.setState({addedRows: initialized});
  };

  handleSave = (): void => {
    var adjustments = this.state.invoiceItems.filter(item => IsAdjustment(item))
    .map(item => {
      return {id: item.id, name: item.type, amount: item.fee};
    });

    this.setState({isSaving: true});

    InvoiceHistoryService.upsertInvoiceAdjustments(this.props.invoiceId, adjustments)
    .then(() => {
      NotificationService.fireNotification(NotificationTypes.success,
          DefaultNotificationMessage.saved);
      this.fetchInvoiceItems();
      this.setState({isSaving: false});
    })
    .catch((error: HttpError) => {
      this.setState({isSaving: false});
      NotificationService.fireNotification(NotificationTypes.error, 'Error Saving Invoice');
    });
  };

  httpPrintInvoice = (): void => {
    this.setState({isDownloading: true});
    InvoiceHistoryService.downloadInvoice(this.props.invoiceId, this.props.companyId).then(() => {
      this.setState({isDownloading: false});
    });
  };

  render() {
    const {classes} = this.props;
    const columnsToExpand = this.state.invoiceItems.map(item => item.programCategory);
    return (
        <div className={classes.root}>
          <Paper className={classes.paper}>
            <Grid
                rows={this.state.invoiceItems}
                columns={this.state.columns}
            >
              <CurrencyTypeProvider
                  for={['fee', 'priorYearAdjustment']}
              />
              <GroupingState
                  defaultGrouping={this.state.grouping}
                  defaultExpandedGroups={columnsToExpand}
              />
              <IntegratedGrouping/>
              <Table columnExtensions={this.state.tableColumnExtensions}/>
              <TableColumnResizing
                  defaultColumnWidths={this.state.defaultColumnWidths}
                  resizingMode={'nextColumn'}
              />
              <TableHeaderRow/>
              {this.props.isAdmin === true ? [
                    <EditingState
                        key='EditingState'
                        editingRowIds={this.state.editingRowIds}
                        onEditingRowIdsChange={this.addEditingRows}
                        addedRows={this.state.addedRows}
                        onAddedRowsChange={this.changeAddedRows}
                        onCommitChanges={this.commitChanges}
                    />,
                    <TableEditRow key='TableEditRow' cellComponent={EditCell}/>,
                    <TableEditColumn
                        key='TableEditColumn'
                        showAddCommand={!this.state.addedRows.length}
                        showDeleteCommand
                        showEditCommand
                        cellComponent={EditCommandCell}
                        messages={editColumnMessages}
                    />
                  ]
                  : null
              }
              <TableGroupRow              />
            </Grid>
            <div>
              <InvoiceSummaryTable summaryItems={this.state.summaryItems}/>
            </div>
          </Paper>

          <div className={classes.actionButtons}>

          {this.props.isAdmin === true ? <Button
              color='primary'
              variant={'contained'}
              className={classes.saveButton}
              disabled={this.state.isSaving || this.state.addedRows.length || this.state.editingRowIds.length}
              onClick={this.handleSave}
              href={''}>Save
          </Button> : null}
          {this.props.isAdmin ?
              <Button href={''} className={classes.saveButton} variant={'contained'} color={'secondary'}
                      disabled={this.state.isDownloading} onClick={this.httpPrintInvoice}>
                Print Invoice&nbsp;
                <Icon className={classes.rightIcon}>print</Icon>
              </Button> : null}

          </div>

          </div>
    );
  }
}

export default withStyles(styles)(InvoiceDialogTable);
