/* @flow */

import * as React from 'react';
import {Redirect, Route} from 'react-router';
import AuthService from '../../services/AuthService';
import {JSXElement} from '@babel/types';

type Props = {
  component: React.ElementType
};
type State = {};
export default class AdminRoute extends React.Component<Props, State> {
  render() {
    // De-structure component / props from <AdminRoute .../>
    const {component, ...routeProps} = this.props;
    const T: JSXElement = component;

    // Setup render vs redirect.
    const renderRoute = componentProps => {
      if (AuthService.isAdmin()) {
        return (
            <T {...componentProps} />
        );
      }

      const to = {
        pathname: '/member',
        state: {from: componentProps.location}
      };

      return (
          <Redirect to={to}/>
      );
    };

    return (
        <Route {...routeProps} render={renderRoute}/>
    );
  }
}
