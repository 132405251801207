/* @flow */

import React from 'react';
import {TablePagination} from '@material-ui/core';
import MaterialTable from 'material-table';
import {conf} from '../../../config';
import {LoggerService} from '../../../services/LoggerService';
import {JSXElement} from '@babel/types';
import type {HistoryRecordFilter, HistoryRecordResponse} from '../../../services/HistoryRecordService';
import HistoryRecordService from '../../../services/HistoryRecordService';
import PrimaryProgressLoader from '../../../components/Utils/PrimaryProgressLoader';

const logger = LoggerService.getLogger('HistoryRecordTable');

const columns = [
  {title: 'User', field: 'user', editable: 'never'},
  {title: 'Company', field: 'companyId', editable: 'never'},
  {title: 'Action', field: 'action', editable: 'never'},
  {title: 'Service', field: 'service', editable: 'never'},
  {title: 'Record', field: 'record', editable: 'never'},
  {title: 'Field', field: 'field', editable: 'never'},
  {title: 'Date', field: 'date', editable: 'never'},
  {title: 'Current Value', field: 'currentValue', editable: 'never'},
  {title: 'Previous Value', field: 'previousValue', editable: 'never'},
];

const pageSize = conf.defaultRowsPerPage;

type Props = {
  recordFilter: any,
  setTotalRecords: any
};

type State = {|
  totalRecords: number,
  historyRecords: Array<any>,
  currentPage: number,
  isLoading: boolean
|};

export default class HistoryRecordTable extends React.Component<Props, State> {
  state = {
    totalRecords: 0,
    historyRecords: [],
    currentPage: 0,
    isLoading: false
  };

  componentDidUpdate(prevProps: Props, prevState: State, snapshot: any): void {
    if (prevProps.recordFilter !== this.props.recordFilter) {
      this.fetchRecords();
    }
  }

  onChangePage = (e: Event, page: number): void => {
    this.setState({currentPage: page},() => {
      this.fetchRecords()
    });
  };

  fetchRecords = (): void => {
    const currentPage = this.state.currentPage;
    this.setState({isLoading: true});
    let filters = this.props.recordFilter;
    filters.page = currentPage;
    HistoryRecordService.postRecordFilter(filters)
    .then((historyRecord: HistoryRecordResponse) => {
      if (this.state.totalRecords !== historyRecord.count){
        this.props.setTotalRecords(historyRecord.count)
      }
      this.setState({
        historyRecords: historyRecord.data,
        totalRecords: historyRecord.count,
        currentPage: currentPage
      });
    }).catch((err) => {
      logger.error(`Unable to get record history for ${JSON.stringify(this.props.recordFilter)}`, err);
    }).finally(() => {
      this.setState({isLoading: false});
    });
  };

  render(): JSXElement {
    const pageLabel = this.state.currentPage === null ? '' : `${pageSize * (this.state.currentPage) + 1}-${pageSize * (this.state.currentPage + 1)} of ${this.state.totalRecords}`;
    return (
        this.state.isLoading ?
            <PrimaryProgressLoader/> :
            <MaterialTable
                title='History Records'
                columns={columns}
                data={this.state.historyRecords}
                options={{
                  search: false,
                  filtering: false,
                  sorting: false,
                  pageSize: pageSize,
                  pageSizeOptions: [],
                  emptyRowsWhenPaging: false,
                }}
                localization={{
                  pagination: {
                    labelDisplayedRows: pageLabel
                  }
                }}
                components={{
                  Pagination: paginationProps =>
                      <TablePagination
                          {...paginationProps}
                          rowsPerPageOptions={[]}
                          count={this.state.totalRecords}
                          page={this.state.currentPage}
                          onChangePage={this.onChangePage}
                      />
                }}
            />
    );
  }
}
