import React from 'react';
import SharpsAddProductList from '../components/SharpsAddProductList';
import {makeStyles} from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';

const useStyles = makeStyles({
  itemBox: {
    width: '97%',
  },
});

function getSteps() {
  return [
    'Lancet or Single-Use Lancet Device',
    'Pen Needle',
    'Butterfly Needle with Fixed or Removable Needle',
    'Catheter with Fixed or Removable Needle',
    'Imitrex-Style Syringe',
    'Pen-Like Device with Fixed or Retractable Needle for Single or Multiple Use',
    'Autoinjector with Permanently Fixed Needle',
    'Single Needle',
    'Transfer Set, Vial Adapter, Transfer Needle, Mix2Vial',
    'All-In-One Catheter (E.G. Mio, Quick-Set)',
    'Syringe with Integrated Needle'
  ];
}

export default function SharpsSelfReportingStepperTwoA(props) {

  const classes = useStyles();
  const step = '2A';

  function getSelectedData(subStep) {
    return props.data.filter((elm) => elm.subStep === subStep);
  }

  return (
      <React.Fragment>

        <Box
            className={classes.itemBox}
        >
          <Box

              m={1}
          >
            <SharpsAddProductList title={getSteps()[0]} submitted={props.submitted} subStep={getSteps()[0]} step={step}
                                  data={getSelectedData(getSteps()[0])} addAdditionalData={props.addAdditionalData}
                                  removeAdditionalData={props.removeAdditionalData}
                                  updateAdditionalData={props.updateAdditionalData}/>
          </Box>
          <Box
              m={1}
          >
            <SharpsAddProductList title={getSteps()[1]} submitted={props.submitted} subStep={getSteps()[1]} step={step}
                                  data={getSelectedData(getSteps()[1])} addAdditionalData={props.addAdditionalData}
                                  removeAdditionalData={props.removeAdditionalData}
                                  updateAdditionalData={props.updateAdditionalData}/>
          </Box>
          <Box
              m={1}
          >
            <SharpsAddProductList title={getSteps()[2]} submitted={props.submitted} subStep={getSteps()[2]} step={step}
                                  data={getSelectedData(getSteps()[2])} addAdditionalData={props.addAdditionalData}
                                  removeAdditionalData={props.removeAdditionalData}
                                  updateAdditionalData={props.updateAdditionalData}/>
          </Box>
          <Box
              m={1}
          >
            <SharpsAddProductList title={getSteps()[3]} submitted={props.submitted} subStep={getSteps()[3]} step={step}
                                  data={getSelectedData(getSteps()[3])} addAdditionalData={props.addAdditionalData}
                                  removeAdditionalData={props.removeAdditionalData}
                                  updateAdditionalData={props.updateAdditionalData}/>
          </Box>
          <Box
              m={1}
          >
            <SharpsAddProductList title={getSteps()[4]} submitted={props.submitted} subStep={getSteps()[4]} step={step}
                                  data={getSelectedData(getSteps()[4])} addAdditionalData={props.addAdditionalData}
                                  removeAdditionalData={props.removeAdditionalData}
                                  updateAdditionalData={props.updateAdditionalData}/>
          </Box>
          <Box
              m={1}
          >
            <SharpsAddProductList title={getSteps()[5]} submitted={props.submitted} subStep={getSteps()[5]} step={step}
                                  data={getSelectedData(getSteps()[5])} addAdditionalData={props.addAdditionalData}
                                  removeAdditionalData={props.removeAdditionalData}
                                  updateAdditionalData={props.updateAdditionalData}/>
          </Box>
          <Box
              m={1}
          >
            <SharpsAddProductList title={getSteps()[6]} submitted={props.submitted} subStep={getSteps()[6]} step={step}
                                  data={getSelectedData(getSteps()[6])} addAdditionalData={props.addAdditionalData}
                                  removeAdditionalData={props.removeAdditionalData}
                                  updateAdditionalData={props.updateAdditionalData}/>
          </Box>
          <Box
              m={1}
          >
            <SharpsAddProductList title={getSteps()[7]} submitted={props.submitted} subStep={getSteps()[7]} step={step}
                                  data={getSelectedData(getSteps()[7])} addAdditionalData={props.addAdditionalData}
                                  removeAdditionalData={props.removeAdditionalData}
                                  updateAdditionalData={props.updateAdditionalData}/>
          </Box>
          <Box
              m={1}
          >
            <SharpsAddProductList title={getSteps()[8]} submitted={props.submitted} subStep={getSteps()[8]} step={step}
                                  data={getSelectedData(getSteps()[8])} addAdditionalData={props.addAdditionalData}
                                  removeAdditionalData={props.removeAdditionalData}
                                  updateAdditionalData={props.updateAdditionalData}/>
          </Box>
          <Box
              m={1}
          >
            <SharpsAddProductList title={getSteps()[9]} submitted={props.submitted} subStep={getSteps()[9]} step={step}
                                  data={getSelectedData(getSteps()[9])} addAdditionalData={props.addAdditionalData}
                                  removeAdditionalData={props.removeAdditionalData}
                                  updateAdditionalData={props.updateAdditionalData}/>
          </Box>
          <Box
              m={1}
          >
            <SharpsAddProductList title={getSteps()[10]} submitted={props.submitted} subStep={getSteps()[10]}
                                  step={step}
                                  data={getSelectedData(getSteps()[10])} addAdditionalData={props.addAdditionalData}
                                  removeAdditionalData={props.removeAdditionalData}
                                  updateAdditionalData={props.updateAdditionalData}/>
          </Box>
        </Box>


      </React.Fragment>
  );
}
