import React from 'react';
import Grid from '@material-ui/core/Grid';
import CardHeader from '@material-ui/core/CardHeader';
import Button from '@material-ui/core/Button';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import Popover from '@material-ui/core/Popover';
import DateFnsUtils from '@date-io/date-fns';
import MemberSubmissionService from '../../../services/MemberSubmissionService';
import NotificationService, {
    NotificationTypes
} from '../../../services/NotificationService';

function MemberSubmissionsExport(props) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [year, setYear] = React.useState((new Date()).getFullYear());
    const [disabled, setDisabled] = React.useState(false);

    function handleClick(event) {
        setAnchorEl(event.currentTarget);
    }

    function handleClose() {
        setAnchorEl(null);
    }

    function exportFile() {
        setDisabled(true);
        MemberSubmissionService.exportMemberSubmissions(year)
            .then(() => {
                handleClose();
                setDisabled(false);
            })
            .catch((error) => {
                NotificationService.fireNotification(NotificationTypes.error, `Error ${error.message}`);
                setDisabled(false);
            });
    }

    const open = Boolean(anchorEl);
    return (
        <React.Fragment>
            <Button onClick={handleClick}>Export Member Submissions</Button>
            <Popover
                open={open}
                onClose={handleClose}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'center',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <CardHeader
                            title='Select Year'
                            action={<Button disabled={disabled} onClick={exportFile}>Export</Button>}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <DatePicker
                                views={['year']}
                                label='Year'
                                variant='inline'
                                value={new Date(year, 1, 1)}
                                minDate={new Date('1-1-2019')}
                                maxDate={new Date()}
                                onChange={e => setYear(e.getFullYear())}
                            />
                        </MuiPickersUtilsProvider>
                    </Grid>
                </Grid>
            </Popover>
        </React.Fragment>
    );
}

export default MemberSubmissionsExport;
