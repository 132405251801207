/* @flow */

import {conf} from '../config';
import {HttpService} from './HttpService';
import type {MaterialPage, MaterialPageQuery} from './Utility/ServiceUtil';
import {resToTablePage} from './Utility/ServiceUtil';
import StringUtils from './Utility/StringUtils';
import type {HttpParams} from './HttpTypes';
import type {ProgramView} from './ProgramService';

export type TotalPrograms = number;
export type ProgramName = string;
export type ProgramNames = Array<ProgramName>;

export type ProgramsAffiliates = {|
  id: number,
  name: string,
  totalPrograms: TotalPrograms,
  totalAffiliatedCompanies: number,
  programs: ProgramNames,
  affiliatedCompanies: ProgramNames
|};

export type Programs = {|
  id: number,
  name: string
|};

export type MarketShare = {|
  programName: string,
  percentage: number,
  category: string,
  year: number
|};

export type RevenueTotal = {|
  totalRevenue: number,
  category: string,
  year: number
|};

export type CompanySettings = {|
  id: number,
  name: string,
  ppswgId: number,
  address: string,
  city: string,
  state: string,
  zipCode: string,
  country: string,
  memberTypeId: number,
  affiliatedCompanies: Array<string>,
  programs: Array<ProgramView>,
  marketShares: Array < MarketShare >,
  revenueTotals: Array < RevenueTotal >
|};

export const ParticipationDisplayModes = Object.freeze({
  MarketShare: 'marketshare',
  Revenue: 'revenue'
});

export class ProgramParticipationService {

  static getProgramParticipation(query: MaterialPageQuery): Promise<MaterialPage<ProgramsAffiliates>> {
    if (!query || query.page < 0) {
      return Promise.reject(new Error('Missing required request params'));
    }

    const queryParams: HttpParams = {
      'page': query.page,
      'pageSize': query.pageSize,
    };

    if (!StringUtils.isBlank(query.search)) {
      queryParams['searchTerm'] = query.search;
    }

    return HttpService.get(conf.api.getProgramParticipation, {
      queryParams: queryParams
    })
    .toPromise().then(resToTablePage(query.page));
  }

  static getCompanySettings(companyId: number): Promise<CompanySettings> {
    if (companyId <= 0) {
      return Promise.reject(new Error('Missing required request params'));
    }

    return HttpService.get(conf.api.getCompanySettings, {
      queryParams: {
        'companyId': companyId
      }
    })
    .toPromise();
  }

  static upsertCompanySettings(settings: CompanySettings): Promise<null> {
      if (settings.ppswgId < 0) {
      return Promise.reject(new Error('Missing required request params'));
    }

    return HttpService.post(conf.api.upsertCompanySettings, {
      data: settings
    }).toPromise();
  }

}
