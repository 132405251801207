import DateUtils from '../../../services/Utility/DateUtils';
import Checkbox from '@material-ui/core/Checkbox';
import React from 'react';
import {ProductTypes} from '../../../services/ProductService';

const notesStyle = {
  width: '80px',
};
const headerStyle = {
  minWidth: 100
};
const checkHeaderStyle = {
  minWidth: 50
};

export function getColumns(source, isAdmin = false) {
  if (source === ProductTypes.Otc) {
    return [
      {title: 'Id', field: 'id', hidden: true},
      {title: 'Category', field: 'category', editable: 'never'},
      {title: 'Sub Category', field: 'subCategory', editable: 'never'},
      {title: 'Segment', field: 'segment', editable: 'never'},
      {title: 'Sub Segment', field: 'subSegment', editable: 'never'},
      {title: 'Brand Low', field: 'brandLow', editable: 'never'},
      {title: 'Brand Owner High', field: 'brandOwnerHigh', editable: 'never'},
      {title: 'Revenue', field: 'revenue', editable: 'never'},
      {title: 'Units', field: 'units', editable: 'never'},
      {
        title: 'Covered',
        field: 'isCovered',
        render: rowData => <Checkbox disabled checked={rowData.isCovered}/>,
        editComponent: rowData =>
            <Checkbox defaultChecked={rowData.rowData.isCovered}
                      onChange={e => {
                        rowData.rowData.isCovered = e.target.checked;
                        rowData.onRowDataChange(rowData.rowData);
                      }}/>
      },
      {
        title: 'Notes',
        field: isAdmin ? 'notes' : 'memberNotes',
        render: rowData => <div style={notesStyle}>{isAdmin ? rowData.notes : rowData.memberNotes}</div>
      }
    ];
  } else {
    return [
      {title: 'Id', field: 'id', hidden: true},
      {title: 'Corporation', field: 'corporation', editable: 'never', headerStyle: headerStyle},
      {title: 'Prod Form 1', field: 'prodForm1', editable: 'never', headerStyle: headerStyle},
      {title: 'Prod Form 2', field: 'prodForm2', editable: 'never', headerStyle: headerStyle},
      {title: 'Prod Form 3', field: 'prodForm3', editable: 'never', headerStyle: headerStyle},
      {title: 'NDC', field: 'ndc', editable: 'never', headerStyle: headerStyle},
      {title: 'Product', field: 'product', editable: 'never', headerStyle: headerStyle},
      { title: `${DateUtils.getCurrentYear() - 1} TRx`, field: 'units', editable: 'never', headerStyle: headerStyle },
      { title: `${DateUtils.getCurrentYear() - 1} Sales $`, field: 'revenue', editable: 'never', headerStyle: headerStyle },
      {
        title: 'Injectable',
        field: 'isInjectable',
        render: rowData =>
            <Checkbox disabled
                      checked={
                        rowData.isInjectable}/>,
        editComponent: rowData =>
            <Checkbox
                defaultChecked={rowData.rowData.isInjectable}
                onChange={e => {
                  rowData.rowData.isInjectable = e.target.checked;
                  rowData.onRowDataChange(rowData.rowData);
                }}/>,
        headerStyle: checkHeaderStyle
      },
      {
        title: 'Opioid / Benzo',
        field: 'isOpioidBenzo',
        render: rowData =>
          <Checkbox disabled
            checked={
              rowData.isOpioidBenzo} />,
        editComponent: rowData =>
          <Checkbox
            defaultChecked={rowData.rowData.isOpioidBenzo}
            onChange={e => {
              rowData.rowData.isOpioidBenzo = e.target.checked;
              rowData.onRowDataChange(rowData.rowData);
            }} />,
        headerStyle: checkHeaderStyle
      },
      {
        title: 'Biological Product',
        field: 'isBiologic',
        render: rowData =>
          <Checkbox disabled
            checked={
              rowData.isBiologic} />,
        editComponent: rowData =>
          <Checkbox
            defaultChecked={rowData.rowData.isBiologic}
            onChange={e => {
              rowData.rowData.isBiologic = e.target.checked;
              rowData.onRowDataChange(rowData.rowData);
            }} />,
        headerStyle: checkHeaderStyle
      },
      {
        title: 'Covered',
        field: 'isCovered',
        render: rowData =>
            <Checkbox disabled
                      checked={
                        rowData.isCovered}/>,
        editComponent: rowData =>
            <Checkbox
                defaultChecked={rowData.rowData.isCovered}
                onChange={e => {
                  rowData.rowData.isCovered = e.target.checked;
                  rowData.onRowDataChange(rowData.rowData);
                }}/>,
        headerStyle: checkHeaderStyle
      },
      {
        title: 'Notes',
        field: isAdmin ? 'notes' : 'memberNotes',
        render: rowData => <div style={notesStyle}>{isAdmin ? rowData.notes : rowData.memberNotes}</div>,
        headerStyle: headerStyle
      }
    ];
  }
}
