import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

function ServiceTypeSelect(props) {
  const classes = useStyles();

  return (
      <form className={classes.root} autoComplete='off'>
        <FormControl className={classes.formControl} component={'div'}>
          <InputLabel htmlFor='service'>Service</InputLabel>
          <Select
              value={props.service}
              onChange={evt => props.setService(evt.target.value)}
              inputProps={{
                name: 'service',
                id: 'service',
              }}
          >
            <MenuItem value={'Products (Admin & Member)'} button={false} component={'li'}>Products (Admin & Member)</MenuItem>
            <MenuItem value={'Rx Data (System & Member Notes)'} button={false} component={'li'}>Rx Data (System & Member Notes)</MenuItem>
            <MenuItem value={'OTC Data (System & Member Notes)'} button={false} component={'li'}>OTC Data (System & Member Notes)</MenuItem>
            <MenuItem value={'Exceptions'} button={false} component={'li'}>Exceptions</MenuItem>
            <MenuItem value={'Uploads'} button={false} component={'li'}>Uploads</MenuItem>
            <MenuItem value={'Due Dates'} button={false} component={'li'}>Due Dates</MenuItem>
            <MenuItem value={'NDAs'} button={false} component={'li'}>NDAs</MenuItem>
            <MenuItem value={'OTC Private Label'} button={false} component={'li'}>OTC Private Label</MenuItem>
            <MenuItem value={'Sharps Transfer List'} button={false} component={'li'}>Sharps Transfer List</MenuItem>
            <MenuItem value={'Sharps Additional Fields'} button={false} component={'li'}>Sharps Additional Fields</MenuItem>
            <MenuItem value={'Invoice Download'} button={false} component={'li'}>Invoice Download</MenuItem>
            <MenuItem value={'Invoice Line Items'} button={false} component={'li'}>Invoice Line Items</MenuItem>
            <MenuItem value={'Member Submissions'} button={false} component={'li'}>Member Submissions</MenuItem>
          </Select>
        </FormControl>

      </form>
  );
}

export default ServiceTypeSelect;
