import React from 'react';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

function ReleaseAgreeSwitch(props) {
  const [checked, setChecked] = React.useState(false);

  function handleChange(event) {
    setChecked(event.target.checked);
    props.handleChange(event.target.checked);
  }

  return (
      <FormGroup row>
        <FormControlLabel
            control={
              <Switch
                  id='switchAgreeRelease'
                  defaultChecked={checked}
                  onChange={(evt) => handleChange(evt)}
                  color='secondary'
              />
            }
            label='I AGREE, release data to members.'
        />
      </FormGroup>
  );
}

export default ReleaseAgreeSwitch;
