/* @flow */

import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import Dividers from '../../../components/DataDisplay/Divider';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import MarketShareService, {MarketShareCalculationStatuses} from '../../../services/MarketShareService';
import type {MarketShareCalculationStatus} from '../../../services/MarketShareService';
import InvoiceService, {InvoiceCalculationStatuses} from '../../../services/InvoiceService';
import type {InvoiceCalculationStatus, InvoiceReleasedStatus} from '../../../services/InvoiceService';
import RunMarketShareAction from './RunMarketShareAction';
import RunInvoicesAction from './RunInvoicesAction';
import ReleaseInvoicesAction from './ReleaseInvoicesAction';
import NotificationService, {
  NotificationTypes
} from '../../../services/NotificationService';

const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    margin: '0 0 15px 0',
    maxWidth: 500,
  },
});

const validStatusesForRunningMarketshare = [
  MarketShareCalculationStatuses.notStarted, MarketShareCalculationStatuses.completed,
  MarketShareCalculationStatuses.failed, MarketShareCalculationStatuses.unknown
];

const validStatusesForRunningInvoices = [
  InvoiceCalculationStatuses.notStarted, InvoiceCalculationStatuses.completed,
  InvoiceCalculationStatuses.failed, InvoiceCalculationStatuses.unknown
];

type Props = {|
  classes: ClassNameMap
|};
type State = {|
  marketshareStatus: string,
  invoiceStatus: string,
  invoiceReleasedStatus: InvoiceReleasedStatus,
  disabled: boolean
|};

class MarketShareInvoiceAction extends React.Component<Props, State> {
  state: State = {
    marketshareStatus: null,
    invoiceStatus: null,
    invoiceReleasedStatus: null,
    disabled: false
  };

  componentDidMount(): void {
    this.runInvoices = this.runInvoices.bind(this);
    this.runMarketShare = this.runMarketShare.bind(this);

    MarketShareService.getMarketShareCalculationStatus().then((response) => {
      this.setState({marketshareStatus: response.status});
    })
    .catch(() => this.setState({marketshareStatus: MarketShareCalculationStatuses.unknown}));

    InvoiceService.getInvoiceCalculationStatus().then((response) => {
      this.setState({invoiceStatus: response.status});
    })
    .catch(() => this.setState({invoiceStatus: InvoiceCalculationStatuses.unknown}));

    InvoiceService.getInvoiceReleasedStatus().then((response) => {
      this.setState({invoiceReleasedStatus: response});
    });
  }

  marketshareCanRun(): boolean {
    if (this.state.invoiceStatus === null ||
        this.state.invoiceStatus === MarketShareCalculationStatuses.inProgress) {
      return false;
    }
    return true;
  }

  invoicesCanRun(): boolean {
    if (this.state.marketshareStatus === MarketShareCalculationStatuses.completed ||
        this.state.marketshareStatus === MarketShareCalculationStatuses.unknown) {
      return true;
    }
    return false;
  }

  invoicesCanBeReleased(): boolean {
    if (this.state.invoiceReleasedStatus === null || this.state.invoiceReleasedStatus.isReleased === null) {
      return false;
    }

    return true;
  }

  runMarketShare = (): void => {
    if (this.state.disabled) {
      return;
    }
    this.setState({disabled: true},
        () => {
          MarketShareService.startMarketShareCalculation()
          .then(() => {
            NotificationService.fireNotification(NotificationTypes.success,
                'Marketshare Calculation Started Successfully.');
            this.setState({
              marketshareStatus: MarketShareCalculationStatuses.inProgress,
              invoiceStatus: InvoiceCalculationStatuses.notStarted,
              invoiceReleasedStatus: {isReleased: null},
              disabled: false
            });
          })
          .catch((error:
              HttpError) => {
            NotificationService.fireNotification(NotificationTypes.error, `Error ${error.message}`);
            this.setState({disabled: false});
          });
        });
  };

  runInvoices = (): void => {
    if (this.state.disabled || !this.invoicesCanRun()) {
      return;
    }
    this.setState({disabled: true},
        () => {
          InvoiceService.startInvoiceCalculation()
          .then(() => {
            NotificationService.fireNotification(NotificationTypes.success,
                'Invoice Calculation Started Successfully.');
            this.setState({
              invoiceStatus: InvoiceCalculationStatuses.inProgress,
              invoiceReleasedStatus: {isReleased: false},
              disabled: false
            });
          })
          .catch((error:
              HttpError) => {
            NotificationService.fireNotification(NotificationTypes.error, `Error ${error.message}`);
            this.setState({disabled: false});
          });
        });
  };

  releaseInvoices = (): void => {
    if (this.state.disabled || !this.invoicesCanBeReleased()) {
      return;
    }

    this.setState({disabled: true},
        () => {
          InvoiceService.releaseInvoices()
          .then(() => {
            NotificationService.fireNotification(NotificationTypes.success,
                'Invoices Released Successfully.');
            this.setState({invoiceReleasedStatus: {isReleased: true}, disabled: false});
          })
          .catch((error:
              HttpError) => {
            NotificationService.fireNotification(NotificationTypes.error, `Error ${error.message}`);
            this.setState({disabled: false});
          });
        });
  };

  render() {
    const {classes} = this.props;
    const {marketshareStatus, invoiceStatus, invoiceReleasedStatus, disabled} = this.state;

    return <Paper className={classes.paper}>
      <Typography variant='h5' component='h3' color='secondary'>
        Actions: Run MarketShare & Run Invoices
      </Typography>
      <Dividers/>
      <RunMarketShareAction calculationStatus={marketshareStatus} disabled={disabled || !this.marketshareCanRun()}
                            runMarketshare={this.runMarketShare}/>
      <Dividers/>
      <RunInvoicesAction calculationStatus={invoiceStatus} disabled={disabled || !this.invoicesCanRun()}
                         runInvoices={this.runInvoices}/>
      <Dividers/>
      <ReleaseInvoicesAction releasedStatus={invoiceReleasedStatus} disabled={disabled || !this.invoicesCanBeReleased()}
                             releaseInvoices={this.releaseInvoices}/>
    </Paper>;
  }
}

export default withStyles(styles)(MarketShareInvoiceAction);
