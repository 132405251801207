import React, {useEffect} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import ReleaseAgreeSwitch from './ReleaseAgreeSwitch';
import ReleaseDatepicker from './ReleaseDatepicker';
import SecondaryProgressLoader from '../../../components/Utils/SecondaryProgressLoader';
import BatchExceptionService from '../../../services/BatchExceptionService';
import type {DueType} from '../../../services/DueDateService';
import DueDateService, {DueTypes} from '../../../services/DueDateService';
import {ProductTypes} from '../../../services/ProductService';
import {HttpError} from '../../../services/HttpService';
import NotificationService, {
  DefaultNotificationMessage,
  NotificationTypes
} from '../../../services/NotificationService';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';

const useStyles = makeStyles({
  card: {
    minWidth: '100%',
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  dateCard: {
    backgroundColor: '#f5f5f5',
    textAlign: 'center',
    margin: "30px"
  },
  confirmButton: {
    minWidth: '800px',
    minHeight: '100px',
    margin: '20px 0px',
  },
  boxMargin: {
    margin: '20px',
  },
  switch: {
    margin: '20px',
  }

});

function AdminReleaseCard(props) {
  const classes = useStyles();
  const [agree, setAgree] = React.useState(false);
  const [exceptions, setExceptions] = React.useState(null);
  const [source] = React.useState(props.source);

  function handleConfirmReleaseSwitch(checked) {
    setAgree(checked);
  }

  useEffect(() => {
    BatchExceptionService.getNumberOfBatchExceptions(props.batchId).then(r => {
      setExceptions(r);
    });
  }, [props.batchId]);

  function handleDate(dueType: DueType, value) {
    DueDateService.upsertDueDate(dueType, value).then(() => {
      NotificationService.fireNotification(NotificationTypes.success, DefaultNotificationMessage.saved);
    }).catch((error: HttpError) => {
      NotificationService.fireNotification(NotificationTypes.error, `Error ${error.message}`);
    });
  }

  function handleConfirmReleaseButton() {
    props.handleConfirm();
  }

  if (exceptions !== null) {
    return (
        <Box
            display="flex"
            flexDirection='column'
            alignItems="center"

        >
          <Grid container item xs={12} sm={12}>
            <Paper className={classes.paper}>
              <Typography variant='h4' component='h2'
                          className='flex-center pad-top-15'>
                Approve and Submit Data for Member Review
              </Typography>
              <p className='pad-top-15'>If you would like to save your work and
                continue working on it later, please use the "SAVE DRAFT/SAVE FOR
                LATER". When you return to this section, you can continue where you
                left off.
                Prior to data release, confirm the following has been accomplished:

                All data sets have been uploaded and all rows and columns are
                correct.

                Nielsen

                IQVIA

                All exceptions have been cleared
              </p>
            </Paper>
          </Grid>
          <Grid item xs={6} sm={12}>
            <Box
                display="flex"
                alignItems="center"
            >
              <Paper className={classes.dateCard}>
                <p>{source === ProductTypes.Otc ? 'OTC' : 'Rx'} Data</p>
                <ReleaseDatepicker
                    handleChange={handleDate}
                    dueType={source === ProductTypes.Otc ? DueTypes.otcData : DueTypes.rxData}/>
              </Paper>
              <Paper className={classes.dateCard}>
                <p>{source === ProductTypes.Otc ? 'OTC Private Label' : 'Sharps Self Reporting'}</p>
                <ReleaseDatepicker
                    handleChange={handleDate}
                    dueType={source === ProductTypes.Otc ? DueTypes.otcPrivateLabel
                        : DueTypes.sharpsSelfReport}/>
              </Paper>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box
                display="flex"
                flexDirection='column'
                alignItems="center"
            >
                <ReleaseAgreeSwitch handleChange={handleConfirmReleaseSwitch}/>
                <Button className={classes.confirmButton} variant='contained' size='large' color='primary'  id='confirmRelease'
                        onClick={() => handleConfirmReleaseButton()}
                        disabled={!agree || exceptions > 0}
                        href={''}>
                  Confirm Release
                </Button>
            </Box>
          </Grid>
        </Box>
    );

  } else {
    return (<div><SecondaryProgressLoader/></div>);
  }
}

export default AdminReleaseCard;
