/* @flow */

import React from 'react';
import {Redirect, Route, Switch} from 'react-router';
import {ThemeProvider} from '@material-ui/styles';
import AdminLayout from './layouts/Admin/AdminLayout';
import MemberLayout from './layouts/Member/MemberLayout';
import theme from './theme';
import './assets/ppswgStyles.css';
import {JSXElement} from '@babel/types';
import {HashRouter} from 'react-router-dom';
import {conf} from './config';
import NotFound from './layouts/NotFound';
import Error from './layouts/Error';
import FakeAuth from './layouts/FakeAuth';
import AuthService from './services/AuthService';

type Props = {};
type State = {|
  assembly: string,
|};

export default class App extends React.Component<Props, State> {
  displayName = App.name;
  state = {
    assembly: '',
  };

  static rootRedirect(): JSXElement {
    if (AuthService.isLoggedIn()) {
      if (AuthService.isAdmin()) {
        return <Redirect to={conf.hash.admin.home}/>;
      } else {
        return <Redirect to={conf.hash.member.home}/>;
      }
    }

    AuthService.ssoLogin();

    return null;
  }

  render(): JSXElement {
    return (
        <ThemeProvider theme={theme}>
          <HashRouter>
            <Switch>
              <Route exact path={conf.pathRoot} render={App.rootRedirect}/>
              <Route path={conf.hash.admin.home} component={AdminLayout}/>
              <Route path={conf.hash.member.home} component={MemberLayout}/>
              <Route path={conf.hash.errorWithException} render={(props)=> <Error error={props.match.params.error}/>} />
              <Route path={conf.hash.error} render={(props)=> <Error error={''}/>} />
              {!conf.isProduction ? <Route path={conf.hash.fakeAuth} component={FakeAuth}/> : null}
              <Route component={NotFound}/>
            </Switch>
          </HashRouter>
        </ThemeProvider>
    );
  }
}
