/* @flow */

import {AxiosResponse} from 'axios';

export const HTTP_ERROR_CODE = {
  SYSTEM: 500,
  NOT_FOUND: 404,
  BAD_REQUEST: 400,
  UNAUTHORIZED: 401
};

export type HttpMethod = 'get' | 'post' | 'put' | 'delete';

export type HttpHeaders = {|
  [header: string]: string | string[];
|};

export type HttpParams = {
  [key: string]: any;
};

export type HttpRequestOptions = {|
  headers?: HttpHeaders,
  pathParams?: HttpParams,
  queryParams?: HttpParams,
  externalUrl?: string,
  httpRetryLimit?: number,
  httpTimeout?: number,
  responseType?: 'arraybuffer' | 'blob',
  useMockApi?: boolean,
  parser?: (AxiosResponse) => any,
  data?: Object
|};
