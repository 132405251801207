/* @flow */

import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import {ClassNameMap} from '@material-ui/styles/withStyles';
import Box from '@material-ui/core/Box';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Button from '@material-ui/core/Button';
import MarketShareService, {MarketShareCalculationStatuses} from '../../../services/MarketShareService';
import type {MarketShareCalculationStatus} from '../../../services/MarketShareService';

const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    margin: '0 0 15px 0',
    maxWidth: 500,
  },
  button: {
    fontSize: '.7rem',
  }
});

const validStatusesForRunningCalculation = [
  MarketShareCalculationStatuses.notStarted, MarketShareCalculationStatuses.completed,
  MarketShareCalculationStatuses.failed, MarketShareCalculationStatuses.unknown
];

type Props = {|
  classes: ClassNameMap,
  calculationStatus: string,
  disabled: boolean,
  runMarketshare: void => void
|};
type State = {|
  agreeMarketShare: boolean
|};

class RunMarketShareAction extends React.Component<Props, State> {
  state: State = {
    agreeMarketShare: false
  };

  onCheckMarketShare(e: Event, checked: boolean): void {
    this.setState({agreeMarketShare: checked});
  }

  render() {
    const {classes, calculationStatus, disabled, runMarketshare} = this.props;
    const {agreeMarketShare} = this.state;

    return <React.Fragment>
      <Box
          display='flex'
          justifyContent='space-around'
          m={2}>
        {calculationStatus ?
            <p className={classes.toggleText}>Marketshare Calculation Status: {calculationStatus}</p>
            : <p className={classes.toggleText}>Getting Marketshare Calculation Status...</p>
        }
      </Box>
      {validStatusesForRunningCalculation.includes(calculationStatus) ?
          <Box
              display='flex'
              justifyContent='space-around'
              m={2}
          >

            <p className={classes.toggleText}>I AGREE, Run Marketshare</p>
            <FormControlLabel
                value='start'
                disabled={disabled}
                control={<Switch id='switchRunMarketShare'
                                 onChange={(e, checked) => this.onCheckMarketShare(e, checked)} color='primary'/>}
                label={''}
            />
            <Button
                id='btnRunMarketShare'
                variant='contained'
                color='secondary'
                className={classes.button}
                disabled={disabled || !agreeMarketShare}
                onClick={runMarketshare}
            >
              Run Marketshare
            </Button>
          </Box>
          : null
      }
    </React.Fragment>;
  }
}

export default withStyles(styles)(RunMarketShareAction);
