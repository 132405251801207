import React from 'react';
import {createMuiTheme, makeStyles, MuiThemeProvider} from '@material-ui/core/styles';
import type {BatchStatus} from '../../../services/BatchDataService';
import BatchDataService, {BatchStatuses, BatchSteps} from '../../../services/BatchDataService';
import StyledDropzone from '../../../components/CustomComps/FileUpload';
import StaticTable from '../components/StaticTable';
import AdminEditTable from './AdminEditTable';
import AdminReleaseCard from './AdminReleaseCard';
import ExceptionManagement from './ExceptionManagement';
import {LoggerService} from '../../../services/LoggerService';
import NotificationService, {
  DefaultNotificationMessage,
  NotificationTypes
} from '../../../services/NotificationService';
import {JSXElement} from '@babel/types';
import {interval} from 'rxjs';
import {map} from 'rxjs/operators';
import {AdminEditService} from '../../../services/AdminEditService';
import {ProductVendor, ProductTypes} from '../../../services/ProductService';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

const logger = LoggerService.getLogger('AdminStepper');

const styles = theme =>({
  root: {
    // width: '90%',
  },
  icons: {
    backgroundColor: '#4a90e2',
  },
  button: {
    marginRight: theme.spacing(1),
  },
  space: {
    paddingBottom: '30px',
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
});

class IntervalSingleton {
  static instance = null;
}

const steps = ['Upload Data', 'Confirm Uploaded Data', 'Update Processed Data', 'Exceptions', 'Approve & Release'];

const batchSteps = {
  Upload: {
    Step: 0
  },
  ViewRawData: {
    Step: 1
  },
  EditProcessedData: {
    Step: 2
  },
  ExceptionManagement: {
    Step: 3
  },
  ApproveAndRelease: {
    Step: 4
  },
  Complete: {
    Step: 5
  }
};

type State = {|
  activeStep: number,
  canProgress: boolean,
  uploadedFile: any,
  uploadDate: any,
  batchId: number,
  uploadStatus: string,
  hasSubmittedThisYear: boolean,
  previousSubmissionAcknowledge: boolean
|};

type Props = {|
  source: string
|};

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#4a90e2'
    }
  },
});

export default class CustomStepper extends React.Component<Props, State> {
  state: State = {
    activeStep: 0,
    canProgress: false,
    uploadedFile: null,
    uploadDate: null,
    batchId: 0,
    uploadStatus: null,
    hasSubmittedThisYear: false,
    previousSubmissionAcknowledge: false
  };

  getStepContent(step:number, props) {
    switch (step) {
      case 0:
        return <StyledDropzone {...props} />;
      case 1:
        return <StaticTable {...props} />;
      case 2:
        return <AdminEditTable {...props} />;
      case 3:
        return <ExceptionManagement {...props} />;
      case 4:
        return <AdminReleaseCard {...props} />;
      default:
        return 'Unknown step';
    }
  }

  getBatchErrorMessage() {
    return (
        <span>There was a problem processing the uploaded file. Please click "Cancel" to return to Step 1.</span>
    );
  }

  isProcessing(): boolean {
    return this.state.uploadStatus !== null && this.state.uploadStatus !== BatchSteps.complete
        && this.state.uploadStatus !== BatchStatuses.error;
  }

  preventProgress(): boolean {
    return (this.state.activeStep === (steps.length - 1) ||
        !this.state.canProgress ||
        (this.state.hasSubmittedThisYear && !this.state.previousSubmissionAcknowledge && this.state.activeStep === 0) ||
        this.hasBatchError());
  }

  shouldRefresh(): boolean {
    return this.isProcessing() && (this.state.activeStep === 2 || this.state.activeStep === 1);
  }

  hasBatchError(): boolean {
    return this.state.uploadStatus === BatchStatuses.error;
  }

  refreshInterval = interval(30000).pipe(map(() => {
    this.getMostRecentInProgBatch();
  }));

  componentDidMount(): void {
    this.setCanProgress = this.setCanProgress.bind(this);
    this.handleBack = this.handleBack.bind(this);
    this.handleNext = this.handleNext.bind(this);
    this.handleFileRemoved = this.handleFileRemoved.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.handleFilesAccepted = this.handleFilesAccepted.bind(this);
    this.getMostRecentInProgBatch();
    this.stopRefreshInterval();
    if (this.shouldRefresh()) {
      IntervalSingleton.instance = this.refreshInterval.subscribe();
    }
  }

  componentDidUpdate(prevProps: Props, prevState: State): void {
    if (prevProps.source !== this.props.source) {
      this.getMostRecentInProgBatch();
    }
    this.stopRefreshInterval();
    if (this.shouldRefresh()) {
      IntervalSingleton.instance = this.refreshInterval.subscribe();
    }
  }

  componentWillUnmount() {
    logger.info('unmounted component, checking to stop refresh interval.');
    // fires when component unmounts
    this.stopRefreshInterval();
  }

  stopRefreshInterval(): void {
    if (IntervalSingleton.instance) {
      IntervalSingleton.instance.unsubscribe();
      IntervalSingleton.instance = null;
    }
  }

  getMostRecentInProgBatch(): void {

    BatchDataService.getMostRecentInProgBatch(this.props.source).then(
        (batch: BatchStatus) => {
          if (batch && batch.batchDataId && batch.step !== BatchSteps.complete) {
            this.setState({
              batchId: batch.batchDataId,
              uploadStatus: batch.uploadStatus,
              activeStep: batchSteps[batch.step].Step,
              previousSubmissionAcknowledge: !batch.previousBatchThisYear,
              hasSubmittedThisYear: batch.previousBatchThisYear
            });
          } else {
            this.setState({
              batchId: 0,
              uploadStatus: null,
              activeStep: 0,
              previousSubmissionAcknowledge: !batch.previousBatchThisYear,
              hasSubmittedThisYear: batch.previousBatchThisYear
            });
          }
        }).catch((err) => {
      logger.error(err);
      NotificationService.fireNotification(NotificationTypes.error, err.message);
    });
  }

  noBatchError(): boolean {
    return this.state.uploadStatus !== BatchStatuses.error;
  }

  handleNext(): void {
    const noBatchError = this.noBatchError();
    switch (this.state.activeStep) {
      case 0:
        BatchDataService.createBatchData(this.props.source).then(createdBatchId => {
          if (createdBatchId > 0) {
            this.setState({
              batchId: createdBatchId,
              uploadStatus: BatchStatuses.initialized
            }, () => {
              this.uploadFile(createdBatchId);
              this.goToNextStep(createdBatchId);
            });
          } else {
            throw Error('Failed to create batch');
          }
        })
        .catch(e => {
          logger.error(e);
          NotificationService.fireNotification(NotificationTypes.error, 'Failed to upload file.');
        });
        break;
      case 4:
          if (noBatchError) {
            this.goToNextStep(this.state.batchId);
          }
        break;
      default:
          if (noBatchError) {
            this.goToNextStep(this.state.batchId);
          }
    }
  }

  goToNextStep(batch): void {
    BatchDataService.updateBatchDataStep(true, batch).then(() => {
      if (this.state.activeStep === steps.length - 1) {
        this.handleFileRemoved();
        this.setState({activeStep: 0, batchId: 0});
        NotificationService.fireNotification(NotificationTypes.success, DefaultNotificationMessage.submitted);
      } else {
        this.setState({activeStep: this.state.activeStep + 1});
      }
    });
  }

  handleBack(): void {
    if (this.state.activeStep - 1 !== 0) {
        if (this.noBatchError()) {
          BatchDataService.updateBatchDataStep(false, this.state.batchId).then(() => {
            this.setState({activeStep: this.state.activeStep - 1});
          });
        }
    }
  }

  handleFilesAccepted(files): void {
    if (files != null && files.length > 0) {
      this.setState({
        uploadedFile: files[0],
        uploadDate: new Date(),
        canProgress: true
      });
    }
  }

  handleFileRemoved(): void {
    this.setState({
      uploadedFile: null,
      uploadDate: null,
      canProgress: false
    });
  }

  handleCancel(): void {
    BatchDataService.deleteBatchData(this.state.batchId);
    this.handleFileRemoved();
    this.setState({
      batchId: 0,
      uploadStatus: null,
      activeStep: 0
    });
  }

  uploadFile(batch): Promise {
    return AdminEditService.uploadVendorFile(this.state.uploadedFile, ProductVendor[this.props.source], batch);
  }

  setCanProgress(canProgress): void {
    this.setState({ canProgress: canProgress });
  }

  render(): JSXElement {
    const fileUploadLabel = this.props.source === ProductTypes.Rx ?
				  'For Rx products PPSWG uses IQVIA data from the previous full year.  '
				  + 'It is sourced from the online tool provided by IQVIA, in an Excel format.  '
				  + 'This source data is located on the PPSWG Share Drive in the cost allocation folder.'
				  :
				  'For Over the Counter Branded products PPSWG uses Nielsen Data from the prior calendar '
				  + 'year which is provided by Nielsen in an Excel format.  '
				  + 'This source data is located on the PPSWG Share Drive in the cost allocation folder.'

    return (
        <div className={styles.root}>
          <Stepper activeStep={this.state.activeStep}>
            {
              steps.map((label, index) => {
                const stepProps = {};
                const labelProps = {};
                return (
                    <Step key={label} {...stepProps}>
                      <StepLabel {...labelProps}>{label}</StepLabel>
                    </Step>
                );
              })
            }
          </Stepper>
          <Typography className={styles.instructions} component={'div'}>
            {
              this.hasBatchError() ? this.getBatchErrorMessage() : this.getStepContent(this.state.activeStep,
                  {
                    file: this.state.uploadedFile,
                    date: this.state.uploadDate,
                    handleFilesAccepted: this.handleFilesAccepted,
                    handleFileRemoved: this.handleFileRemoved,
                    source: this.props.source,
                    label: fileUploadLabel,
                    batchId: this.state.batchId,
                    uploadStatus: this.state.uploadStatus,
                    handleConfirm: this.handleNext,
                    setCanProgress: this.setCanProgress
                  })
            }
          </Typography>
          {this.state.activeStep === 1 || this.hasBatchError() ? <Button onClick={this.handleCancel}
                                                                       className={styles.button} href={''}>
                Cancel
              </Button>
              : <Button disabled={this.state.activeStep === 0} onClick={this.handleBack} className={styles.button} href={''}>
                Back
              </Button>
          }
            {this.preventProgress() ? '' : <Button
                id='btnAdminStepperNext'
                variant='contained'
                color='secondary'
                onClick={this.handleNext}
                className={styles.button}
                href={''}>
              Next
            </Button>
            }
          {this.state.hasSubmittedThisYear && this.state.activeStep === 0 ? <FormControlLabel
                  control={
                    <Switch
                        onChange={evt => this.setState({previousSubmissionAcknowledge: evt.target.checked})}
                        color='primary'
                    />
                  }
                  label="This year's data set has already been submitted. Please confirm that you would like to submit another data set for the same year. This will override the currently uploaded data and all user revisions."
              />
              : null
          }
        </div>
    );
  }
}
