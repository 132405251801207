/* @flow */

import type { MaterialPage, MaterialPageQuery, PagedResponse } from './Utility/ServiceUtil';
import { paramsError, resToTablePage, resToPageResponse } from './Utility/ServiceUtil';
import { HttpService } from './HttpService';
import { conf } from '../config';

export type InvoiceHistory = {|
    id: number,
        companyId: string,
|};

export type BankingInfo = {|
    routing: string,
        account: string,
            tax: string
                |};

export type InvoiceItem = {|
    companyId: number,
        invoiceId: number,
            createdDate: string,
                dueDate: string,
                    customerPoNumber: string
                        |};
export type SummaryItem = {|
    description: string,
        amount: number
            |}

export type InvoiceLineItem = {|
    id: number,
        invoiceId: number,
            programCategory: string,
                program: string,
                    type: string,
                        fee: ?number,
                            priorYearAdjustment: ?number,
                                marketShareTier: string,
                                    revenueTotalTier: string,
|}

export type InvoiceLineItemDetails = {|
    lineItems: Array < InvoiceLineItem >,
        summaries: Array < SummaryItem >
|}

export type InvoiceSearchFilters = {|
    keyword: string,
        year: number,
            page: number,
                pageSize: number
                    |}

export type InvoiceSearchResult = {|
    companyId: number,
        invoiceId: number,
            companyName: string,
                year: number,
                    createdDate: string,
    dueDate: string,
                            customerPoNumber: string
|}

export type InvoiceAdjustment = {|
    id: number,
        name: string,
            amount: number
                |}

export type InvoiceReleasedStatus = {|
    isReleased: ?boolean
        |}

export const InvoiceCalculationStatuses = Object.freeze({
    notStarted: 'Not Started',
    inProgress: 'In Progress',
    completed: 'Completed',
    failed: 'Failed',
    unknown: 'Unknown'
});

export type InvoiceCalculationStatus = $Values<typeof InvoiceCalculationStatuses>

export default class InvoiceService {
    static getInvoiceItems(invoiceId: number, companyId: number): Promise<InvoiceLineItemDetails> {
        // Use the admin endpoint if the company id is provided.
        var endpoint;
        var params;
        if (companyId > 0) {
            endpoint = conf.api.invoiceItemsAdmin;
            params = { invoiceId: invoiceId, companyId: companyId };
        } else {
            endpoint = conf.api.invoiceItems;
            params = { invoiceId: invoiceId };
        }
        return HttpService.get(endpoint, {
            queryParams: params
        })
            .toPromise()
            .then((res) => {
                return res || { lineItems: [], summaries: [] };
            });
    }

    static getBankingInfo(): Promise<BankingInfo> {
        return HttpService.get(conf.api.getBankingInfo, {})
            .toPromise()
            .then((res) => {
                return res;
            });
    }

    static downloadInvoice(invoiceId: number, companyId: number): Promise<void> {
        // Use the admin endpoint if the company id is provided.
        var endpoint;
        var params;
        if (companyId > 0) {
            endpoint = conf.api.adminDownloadInvoice;
            params = { invoiceId: invoiceId, companyId: companyId };
        } else {
            endpoint = conf.api.downloadInvoice;
            params = { invoiceId: invoiceId };
        }

        return HttpService.getFileDownload(endpoint, {
            queryParams: params,
            httpTimeout: conf.httpPrintInvoiceTimeout
        }).toPromise();
    }

    static updateInvoicePoNumber(invoiceId: number, companyId: number, poNumber: string): Promise<void> {
        var endpoint = conf.api.updateInvoicePoNumber;
        var data = { invoiceId: invoiceId, companyId: companyId, CustomerPoNumber: poNumber };

        return HttpService.post(endpoint, {
            data: data
        }).toPromise();
    }

    static getInvoiceHistory(query: MaterialPageQuery): Promise<MaterialPage<InvoiceHistory>> {
        return HttpService.get(conf.api.invoiceHistory, {
            queryParams: {
                page: query.page,
                pageSize: query.pageSize
            }
        })
            .toPromise()
            .then(resToTablePage(query.page));
    }

    static searchInvoices(query: InvoiceSearchFilters): Promise<PagedResponse<InvoiceSearchResult>> {

        if (!query) {
            return paramsError();
        }

        return HttpService.post(conf.api.getInvoicesByFilters,
            {
                data: query
            })
            .toPromise()
            .then(resToPageResponse());
    }

    static upsertInvoiceAdjustments(invoiceId: number, adjustments: Array<InvoiceAdjustment>): Promise<void> {
        return HttpService.post(conf.api.upsertInvoiceAdjustments, {
            data: {
                invoiceId: invoiceId,
                adjustments: adjustments
            }
        })
            .toPromise();
    }

    static getInvoiceCalculationStatus(): Promise<InvoiceCalculationStatus> {

        return HttpService.get(conf.api.getInvoiceCalculationStatus, {
            httpTimeout: conf.httpGetInvoiceStatusTimeout,
            httpRetryLimit: 3
        }).toPromise();
    }

    static startInvoiceCalculation(): Promise<void> {
        return HttpService.post(conf.api.startInvoiceCalculation, {
            data: {},
            httpTimeout: conf.httpStartInvoiceCalculationTimeout,
            httpRetryLimit: 1
        }).toPromise();
    }

    static getInvoiceReleasedStatus(): Promise<InvoiceReleasedStatus> {
        return HttpService.get(conf.api.getInvoiceReleasedStatus, {}).toPromise();
    }

    static releaseInvoices(): Promise<void> {
        return HttpService.post(conf.api.releaseInvoices,
            {
                data: {}
            }).toPromise();
    }

    static exportInvoicesToExcel(year: number): Promise<void> {
        return HttpService.getFileDownload(conf.api.exportInvoicesToExcel, {
            queryParams: {
                year: year
            },
            httpTimeout: conf.httpInvoiceExcelTimeout
        }).toPromise();
    }
}
