import React from 'react';
import Grid from '@material-ui/core/Grid';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import {ProductTypes} from '../../../services/ProductService';

function SwitchButtons(props) {
  const children = [
    <ToggleButton key={'1'} value={ProductTypes.Otc} href={''}>
      <p>OTC</p>
    </ToggleButton>,
    <ToggleButton id='uploadRxToggle' key={'2'} value={ProductTypes.Rx} href={''}>
      <p>Rx</p>
    </ToggleButton>,
  ];

  return (
      <Grid container spacing={2} direction='column' alignItems='center'>
        <Grid item>
          <ToggleButtonGroup size='large' value={props.value} exclusive
                             onChange={props.onChange} className='marg-bot-20'>
            {children}
          </ToggleButtonGroup>
        </Grid>
      </Grid>
  );
}

export default SwitchButtons;
