import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

function RxOtcSelect(props) {
  const classes = useStyles();

  return (
      <form className={classes.root} autoComplete='off'>
        <FormControl className={classes.formControl} component={'div'}>
          <Select
              value={props.type}
              onChange={props.handleChange}
              inputProps={{
                name: 'type',
                id: 'age-simple'
              }}
          >
            <MenuItem value={'Otc'}>OTC</MenuItem>
            <MenuItem value={'Rx'}>RX</MenuItem>
          </Select>
        </FormControl>

      </form>
  );
}

export default RxOtcSelect;
