import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import DateUtils from '../../../services/Utility/DateUtils.js';
import StringUtils from '../../../services/Utility/StringUtils';

const useStyles = makeStyles(theme => ({
  root: {
    maxWidth: 500,
  },
  snackbar: {
    margin: theme.spacing(1),
    backgroundColor: '#d32f2f'
  },
}));

function formatSubmitDate(date) {
  if (date) {
    return DateUtils.utcToLocalTime(date);
  } else {
    return '';
  }
}

function OTCDateNotification(props) {
  const classes = useStyles();

  const getContent = () => {
    if (props.isSubmitted) {
      const completedDate = props.dateSubmitted ? DateUtils.formatDate('MM-DD-YYYY',
          StringUtils.toDate(props.dateSubmitted)) : '';

      return <SnackbarContent className={classes.snackbar} message={`Form submitted on ${completedDate}.`}/>;
    } else if (props.isPastDueDate) {
      return <SnackbarContent
          className={classes.snackbar}
          message='Cannot complete form since the due date has passed.'/>;
    }

    const dueDate = props.dueDate ? ` Due by ${DateUtils.formatDate('MM-DD-YYYY HH:mm', props.dueDate)}` : '';
    return <SnackbarContent className={classes.snackbar} message={`Form not submitted.` + (dueDate)}/>;
  };

  return (
      <div className={classes.root}>{getContent()}</div>
  );
}

export default OTCDateNotification;
