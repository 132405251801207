import React, {useEffect} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import {Button} from '@material-ui/core';
import Dividers from '../../../components/DataDisplay/Divider';
import CardHeader from '@material-ui/core/CardHeader';
import DateUtils from '../../../services/Utility/DateUtils.js';
import ToDoService, {ToDoNames} from '../../../services/ToDoService';
import PrimaryProgressLoader from '../../../components/Utils/PrimaryProgressLoader';
import {withRouter} from 'react-router';
import {conf} from '../../../config';
import StringUtils from '../../../services/Utility/StringUtils';
import {LoggerService} from '../../../services/LoggerService';
import Box from '@material-ui/core/Box';
import Icon from '@material-ui/core/Icon';
import Typography from '@material-ui/core/Typography';

const logger = LoggerService.getLogger('MemberDashboardToDo');

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    maxHeight: '500px',
    overflow: 'scroll',
  },

  infoText: {
    margin: '5px 5px 15px 5px',
    color: '#000000',
  },
}));

// The mapping from toDoList item names to methods that will be called when the items are selected.
const itemViewMap = {};
itemViewMap[ToDoNames.RxReviewForm] = {route: conf.hash.member.rxData, title: 'Rx Data Review'};
itemViewMap[ToDoNames.OtcReviewForm] = {route: conf.hash.member.otcData, title: 'OTC Data Review'};
itemViewMap[ToDoNames.SelfReportedOtcForm] = {
  route: conf.hash.member.otcPrivateLabel,
  title: 'OTC Private Label Self-Report Form'
};
itemViewMap[ToDoNames.SelfReportedSharpsForm] = {route: conf.hash.member.sharps, title: 'Sharps Self Report Form'};

const formatToDos = (todoList) => {
  return (todoList || []).map((todo) => {
    const itemView = itemViewMap[todo.name];
    if (itemView) {
      let dueDate = '';
      if (todo.dueDate) {
        dueDate = DateUtils.utcToLocalTime(StringUtils.toDate(todo.dueDate));
        dueDate = DateUtils.formatDate('MM/DD/YYYY HH:mm', dueDate);
      }

      return {
        ...todo,
        displayName: itemView.title,
        dueDate: dueDate,
        route: itemView.route
      };
    }

    logger.error('Unknown todo: ' + todo.name);
    return {...todo};
  });
};

function MemberDashboardToDoComponent() {
  const classes = useStyles();
  const [toDoList, setToDoList] = React.useState(null);

  useEffect(() => {
    ToDoService.getToDoList().then((toDoListRes) => {
      setToDoList(formatToDos(toDoListRes));
    });
  }, []);

  return (
      <React.Fragment>
        <CardHeader title="To Do's"/>
        <Box
            display='flex'
        >
          <Icon color={'secondary'}>info</Icon>
          <Typography className={classes.infoText} variant='caption' gutterBottom>
            This list will be updated as you complete the tasks in the following screens.
          </Typography>

        </Box>

        <Dividers/>
        {
          toDoList ? <List className={classes.root} component={'ul'}>
            {toDoList.length > 0 ? toDoList.map((item, i) => {
              return (
                  <div key={i}>
                    <ListItem component={'li'} role={undefined} dense>
                      <ListItemText id={item.name} primary={item.displayName} secondary={item.dueDate}/>
                      <ListItemSecondaryAction>
                        <Button color='secondary' href={conf.hash.root + item.route}> VIEW </Button>
                      </ListItemSecondaryAction>

                    </ListItem>
                    <Dividers/>
                  </div>
              );
            }) : 'You have no outstanding To Do items at this time.'}
          </List> : <PrimaryProgressLoader/>
        }
      </React.Fragment>
  );
}

export default withRouter(MemberDashboardToDoComponent);
