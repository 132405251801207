/* @flow */

import {conf} from '../config';
import {HttpService} from './HttpService';
import {LoggerService} from './LoggerService';

const logger = LoggerService.getLogger('MarketShareService');

export const MarketShareCalculationStatuses = Object.freeze({
  notStarted: 'Not Started',
  inProgress: 'In Progress',
  completed: 'Completed',
  failed: 'Failed',
  unknown: 'Unknown'
});

export type MarketShareCalculationStatus = $Values<typeof MarketShareCalculationStatuses>

export default class MarketShareService {
  static getMarketShareCalculationStatus(): Promise<MarketShareCalculationStatus> {

    return HttpService.get(conf.api.getMarketShareCalculationStatus, {
      httpTimeout: conf.httpGetMarketShareStatusTimeout,
      httpRetryLimit: 3
    }).toPromise();
  }

  static startMarketShareCalculation(): Promise<void> {
    return HttpService.post(conf.api.startMarketShareCalculation, {
      data: {},
      httpTimeout: conf.httpStartMarketShareCalculationTimeout,
      httpRetryLimit: 1
    }).toPromise();
  }
}
