import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Icon from '@material-ui/core/Icon';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
}));

function DatePicker(props) {
  const classes = useStyles();

  function handleChange(event) {
    props.handle(props.dueType, event.target.value);
  }

  return (
      <form className={classes.container} noValidate>
        <Icon>calendar_today</Icon>
        <TextField
            id='date'
            label={props.dueType}
            type='date'
            defaultValue={props.date}
            onBlur={evt => handleChange(evt)}
            className={classes.textField}
            InputLabelProps={{
              shrink: true,
            }}
        />
      </form>
  );
}

export default DatePicker;
