import React from 'react';
import {makeStyles, useTheme} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import DateUtils from '../../../services/Utility/DateUtils';
import useMediaQuery from '@material-ui/core/useMediaQuery';

const useStyles = makeStyles(theme => ({
  root: {
    margin: 'auto',
    display: 'inline - block',
    float: 'none'
  },
  cardHeader: {
    padding: theme.spacing(1, 2),
  },
  list: {
    height: 'auto',
    backgroundColor: theme.palette.background.paper,
    overflow: 'auto',
  },
  button: {
    margin: theme.spacing(0.5, .5),
  },
  transferCard: {
    height: '800px',
    overflow: 'scroll',
  },
  listItemHeight: {
    height: '800px',
  },
  itemSpacing: {
    margin: '15px',
  }

}));

function not(a, b) {
  return a.filter(value => b.findIndex(item => item.iqviaDataId === value.iqviaDataId) === -1);
}

function intersection(a, b) {
  return a.filter(value => b.findIndex(item => item.iqviaDataId === value.iqviaDataId) !== -1);
}

function union(a, b) {
  return [...a, ...not(b, a)];
}

function SharpsTransferList(props) {
  const classes = useStyles();
  const [checked, setChecked] = React.useState([]);
  const leftChecked = intersection(checked, props.iqviaData);
  const rightChecked = intersection(checked, props.stepSelections);
  const theme = useTheme();
  const reorganizeLists = useMediaQuery(theme.breakpoints.down('md'));

  const handleToggle = value => () => {
    const currentIndex = checked.findIndex(item => item.iqviaDataId === value.iqviaDataId);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setChecked(newChecked);
  };

  const numberOfChecked = items => intersection(checked, items).length;

  const handleToggleAll = items => () => {
    if (numberOfChecked(items) === items.length) {
      setChecked(not(checked, items));
    } else {
      setChecked(union(checked, items));
    }
  };

  const handleCheckedRight = () => {
    props.addSelectedData(props.step, props.subStep, leftChecked);
    setChecked([]);
  };

  const handleCheckedLeft = () => {
    props.removeSelectedData(props.step, rightChecked);
    setChecked([]);
  };

  const customList = (title, items) => (
      items && <Card className={classes.transferCard}>
        <CardHeader
            className={classes.cardHeader}
            avatar={
              <Checkbox
                  onClick={handleToggleAll(items)}
                  checked={numberOfChecked(items) === items.length && items.length !== 0}
                  indeterminate={numberOfChecked(items) !== items.length && numberOfChecked(items) !== 0}
                  disabled={items.length === 0 || props.submitted}
                  inputProps={{'aria-label': 'all items selected'}}
              />
            }
            title={title}
            subheader={`${numberOfChecked(items)}/${items.length} selected`}
        />
        <Divider component={'hr'}/>
        <List className={classes.list} dense component='div' role='list'>
          {items.map((value, index) => {
            const labelId = `transfer-list-all-item-${value.iqviaDataId}-label`;

            return (
                <Card className={classes.itemSpacing} key={index}>
                  <ListItem  key={value.iqviaDataId} role='listitem' onClick={handleToggle(value)} component={'li'}>
                    <ListItemIcon>
                      <Checkbox
                          checked={checked.findIndex(item => item.iqviaDataId === value.iqviaDataId) !== -1}
                          tabIndex={-1}
                          disableRipple
                          disabled={props.submitted}
                          inputProps={{'aria-labelledby': labelId}}
                      />
                    </ListItemIcon>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <ListItemText
                            primary={value.productName}
                            secondary='PRODUCT'
                        />
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <ListItemText
                            primary={value.corporationName}
                            secondary='CORPORATION'
                        />
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <ListItemText
                            primary={value.manufacturerName}
                            secondary='MANUFACTURER'
                        />
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <ListItemText
                            primary={value.categoryName}
                            secondary='CATEGORY'
                        />
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <ListItemText
                            primary={value.ndc}
                            secondary='NDC'
                        />
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <ListItemText
                            primary={value.packSize}
                            secondary='PACK SIZE'
                        />
                      </Grid>
                      <Grid item xs={12} md={2}>
                        <ListItemText
                            primary={value.packQuantity}
                            secondary='PACK QUANTITY'
                        />
                      </Grid>
                      <Grid item xs={12} md={2}>
                        <ListItemText
                            primary={value.yearTRx}
                            secondary={(DateUtils.currentYear - 1) + 'TRx'}
                        />
                      </Grid>
                    </Grid>
                  </ListItem>
                </Card>
            );
          })}
          <ListItem component={'li'}/>
        </List>
      </Card>
  );
  if (!props) {
    return <div/>;
  }
  return (
      <Grid container spacing={2} justify='center' alignItems='center' className={classes.root}>
        <Grid md={12} lg={5} item>{customList('Injectable Products from IQVIA Data', props.iqviaData)}</Grid>
        <Grid xs={reorganizeLists ? 12 : 1} item>
          <Grid container direction={reorganizeLists ? 'row' : 'column'} alignItems='center' justify='center'>
            <Button
                variant='outlined'
                size='large'

                className={classes.button}
                onClick={handleCheckedRight}
                disabled={leftChecked.length === 0 || props.submitted}
                aria-label={reorganizeLists ? 'move selected down' : 'move selected right'}
                href={''}>
              {reorganizeLists ? '\u25BC' : '\u25B6'}
            </Button>
            <Button
                variant='outlined'
                size='large'

                className={classes.button}
                onClick={handleCheckedLeft}
                disabled={rightChecked.length === 0 || props.submitted}
                aria-label={reorganizeLists ? 'move selected up' : 'move selected left'}
                href={''}>
              {reorganizeLists ? '\u25B2' : '\u25C0'}
            </Button>
          </Grid>
        </Grid>
        <Grid md={12} lg={5} item>{customList(`Selections for ${props.subStep} Category`, props.stepSelections)}</Grid>
      </Grid>
  );
}

export default SharpsTransferList;
