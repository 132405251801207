import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Badge from '@material-ui/core/Badge';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import MailIcon from '@material-ui/icons/Mail';
import NotificationsIcon from '@material-ui/icons/Notifications';
import MoreIcon from '@material-ui/icons/MoreVert';
import { Button } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import AuthService from '../../../services/AuthService';

const useStyles = makeStyles(theme => ({
  grow: {
    flexGrow: 1,
  },

  root: {
    background: '#ffffff',
    border: 0,
    borderRadius: 3,
    boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
    color: '#000000',
    height: 48,
    padding: '0 30px',
  },

  image: {
    height: '34px',
    padding: '12px 0 25px 0'
  },

  menuButton: {
    marginRight: theme.spacing(2),
  },
  widthFull: {
    width: '100%',
  },
  title: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },

  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 7),
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: 200,
    },
  },
}));

function TopBarAdmin() {
  const classes = useStyles();

  return (
    <React.Fragment>
    <AppBar className={classes.root} position='static'>
      <Toolbar>
        <Grid
          justify="space-between"
          container
          spacing={24}
        >
          <Grid item>
            <img
              alt='Under development'
              className={classes.image}
              src='ppswg-logo.svg'
            />
          </Grid>
          <Grid item>
            <Button  variant={'contained'} color='secondary' onClick={() => AuthService.logoutNimble()} href={''}>
              Logout
            </Button>
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  </React.Fragment>
  );
}

export default TopBarAdmin;
