import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
}));

function Dividers() {
  const classes = useStyles();

  return (
      <List component='nav' className={classes.root}>


        <Divider component={'hr'}/>

      </List>
  );
}

export default Dividers;
