import React, {useEffect} from 'react';
import SharpsService from '../services/SharpsService';
import type {SharpsForm} from '../services/SharpsService';
import {HttpError} from '../services/HttpService';
import NotificationService, {DefaultNotificationMessage, NotificationTypes} from '../services/NotificationService';
import SharpsFormView from './SharpsFormView';
import ValidationService from '../services/ValidationService';

type Props = {||};

type State = {|
  checked: boolean,
  index: number,
  form: SharpsForm,
  disabled: boolean
|};

class MemberSharpsSelfReporting extends React.Component<Props, State> {
  state: State = {
    checked: false,
    index: 1,
    form: {
      allIqviaData: [],
      step1A: [],
      step1B: [],
      step1C: [],
      step2A: [],
      step2B: [],
      dueDate: null,
      submitted: false,
      isLocked: false,
      companyId: 0
    },
    disabled: false
  };

  fetchForm = (): void => {
    SharpsService.getSharpsProductsForCompany().then(formState => {
      if (!formState) {
        return;
      }

      this.setState({
        form: {...formState}
      });

    });
  };

  componentDidMount(): void {
    this.fetchForm();
  };

  handleSave = (): void => {
    this.postForm(false);
  };

  handleSubmit = (): void => {
    this.postForm(true);
  };

  postForm = (isSubmitting: boolean): void => {
    const message = isSubmitting ? DefaultNotificationMessage.submitted : DefaultNotificationMessage.saved;
    const method = isSubmitting ? SharpsService.SubmitSharpsForm : SharpsService.SaveSharpsForm;

    this.setState({disabled: true}, () => {
      method(this.state.form).then(() => {
        // Re-enable form unless submitting.
        if (isSubmitting !== true) {
          this.setState({disabled: false});
        }
        this.fetchForm();
        NotificationService.fireNotification(NotificationTypes.success, message);
      }).catch((error: HttpError) => {
        this.setState({disabled: false});
        NotificationService.fireNotification(NotificationTypes.error, `Error ${error.message}`);
      });
    });
  };

  addSelectedData = (step, substep, data) => {
    let f = this.state.form;
    let steps = this.getStep(f, step);
    data.forEach((elm) => {
      elm.sectionStep = step;
      elm.subStep = substep;
    });
    steps = steps.concat(
        data.filter(a => steps.map(b => b.iqviaDataId).indexOf(a.iqviaDataId) < 0));
    f = this.assignToStep(f, steps, step);
    this.setNewForm(f);
  };

  removeSelectedData = (step, data) => {
    let f = this.state.form;
    let steps = this.getStep(f, step);
    steps = steps.filter(a => data.map(b => b.iqviaDataId).indexOf(a.iqviaDataId) < 0);
    f = this.assignToStep(f, steps, step);
    this.setNewForm(f);
  };

  addAdditionalData = (step, substep, data) => {

    // Check data
    if (data === null ) {
      return false;
    }

    let f = this.state.form;
    let steps = this.getStep(f, step);
    steps.push({
      id: null,
      productName: data.productName,
      units: data.units,
      notes: data.notes,
      sectionStep: step,
      subStep: substep,
      index: this.state.index
    });
    const newIndex = this.state.index + 1;
    this.setState({index: newIndex});
    steps = [].concat(steps);
    f = this.assignToStep(f, steps, step);
    this.setNewForm(f);
    return true;
  };

  removeAdditionalData = (item, step) => {
    let f = this.state.form;
    let steps = this.getStep(f, step);
    steps.splice(this.getAdditionalDataItemPosition(item, steps), 1);
    steps = [].concat(steps);
    f = this.assignToStep(f, steps, step);
    this.setNewForm(f);
  };

  updateAdditionalData = (val, item, field, step) => {
    let f = this.state.form;
    let steps = this.getStep(f, step);

    if (!ValidationService.SharpsAdditionalDataIsValid(field, val, true)) {
        return;
    }

    steps[this.getAdditionalDataItemPosition(item, steps)][field] = val;
    f = this.assignToStep(f, steps, step);
    this.setNewForm(f);
  };

  setNewForm = (f: SharpsForm): void => {
    this.setState({
      form: {
        allIqviaData: f.allIqviaData,
        step1A: f.step1A,
        step1B: f.step1B,
        step1C: f.step1C,
        step2A: f.step2A,
        step2B: f.step2B,
        dueDate: f.dueDate,
        dateCreated: f.dateCreated,
        submitted: f.submitted,
        isLocked: f.isLocked,
        companyId: f.companyId,
        companyName: f.companyName
      }
    });
  };

  getAdditionalDataItemPosition = (item, data) => {
    //as we are passing out different data to the different steps, we cannot just use
    //an index to the array.
    //and as we are dealing with two types of data (data from db and created locally),
    //we check based on which one is populated
    if (item.id !== null) { //meaning not created on this page
      //use id to query
      return data.findIndex(elm => elm.id === item.id);
    } else {
      //use index to query
      return data.findIndex(elm => elm.index === item.index);
    }
  };

  getStep = (f, step) => {
    switch (step) {
      case '1A':
        return f.step1A;
      case '1B':
        return f.step1B;
      case '1C':
        return f.step1C;
      case '2A':
        return f.step2A;
      case '2B':
        return f.step2B;
      default:
        return null;
    }
  };

  assignToStep = (f, steps, step) => {
    switch (step) {
      case '1A':
        f.step1A = steps;
        break;
      case '1B':
        f.step1B = steps;
        break;
      case '1C':
        f.step1C = steps;
        break;
      case '2A':
        f.step2A = steps;
        break;
      case '2B':
        f.step2B = steps;
        break;
      default:
        break;
    }
    return f;
  };

  setChecked = (value: boolean): void => {
    this.setState({checked: value});
  };

  render() {
    return (
        <SharpsFormView
            form={this.state.form}
            checked={this.state.checked}
            setChecked={this.setChecked}
            readOnly={false}
            addSelectedData={this.addSelectedData}
            removeSelectedData={this.removeSelectedData}
            addAdditionalData={this.addAdditionalData}
            removeAdditionalData={this.removeAdditionalData}
            updateAdditionalData={this.updateAdditionalData}
            handleSave={this.handleSave}
            handleSubmit={this.handleSubmit}
            disabled={this.state.disabled}
            adminView={false}
        />
    );
  }
}

export default MemberSharpsSelfReporting;
