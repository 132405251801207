import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import ProgramManageTable from './ProgramManageTable';
import ProgramParticipateTable from './ProgramParticipateTable';
import TabContainer from '../../../components/Navigation/TabContainer';

const tabStyle = {
  padding: '0px'
};

const useStyles = makeStyles({
  root: {
    flexGrow: 1
  },
});

function SettingsTabs() {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  function handleChange(event, newValue) {
    setValue(newValue);
  }

  return (
      <div className={classes.root}>
        <AppBar position='static' style={tabStyle}>
          <Tabs value={value} onChange={handleChange} component={'div'}>
            <Tab label='PROGRAM MANAGMENT' href={''}/>
            <Tab id='navProgramParticipation' label='PROGRAM PARTICIPATION' href={''}/>
          </Tabs>
        </AppBar>
        {value === 0 && <TabContainer>
          <ProgramManageTable/>
        </TabContainer>}
        {value === 1 && <TabContainer>
          <ProgramParticipateTable/>
        </TabContainer>}
      </div>
  );
}

export default SettingsTabs;
