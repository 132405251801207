import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import SharpsTransferList from './SharpsTransferList';

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
    },
    button: {
        marginTop: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    actionsContainer: {
        marginBottom: theme.spacing(2),
    },
    resetContainer: {
        padding: theme.spacing(3),
    },
    stepperBorder: {
        border: '0px !important',
        padding: '0px'
    },
    stepContent: {
        padding: '0px'
    }
}));

function SharpsSelfReportingStepperOneB(props) {
    const classes = useStyles();
    const [activeStep, setActiveStep] = React.useState(0);
    const steps = getSteps();

    function getSteps() {
        return props.useSimpleForm ? ['Device'] :
            ['Pre-filled autoinjector with permanently attached sharp (sharp fixed OR retractable; device may be intended for single or multiple use)',
            'Pre-filled pen device or pen-like device with permanently attached sharp (sharp fixed OR retractable; device may be intended for single or multiple use)',
            'Pre-filled syringe with permanently attached sharp',
            'Pre-filled IMITREX-style syringe with permanently attached sharp',
            'Other presentations of pre-filled devices with a permanently attached sharp'];
    }

    function getStepContent(step) {
        if (step >= 0 && step < steps.length) {
            return <SharpsTransferList submitted={props.submitted} subStep={steps[step]} step={'1B'}
                iqviaData={props.iqvia} stepSelections={getSelectedData(steps[step])}
                addSelectedData={props.addData} removeSelectedData={props.removeData} />;
        }

        return <div />;
    }

    function getSelectedData(subStep) {
        if (props.data) {
            return props.data.filter((elm) => elm.subStep === subStep);
        }
    }

    function handleNext() {
        setActiveStep(prevActiveStep => prevActiveStep + 1);
    }

    function handleBack() {
        setActiveStep(prevActiveStep => prevActiveStep - 1);
    }

    function handleReset() {
        setActiveStep(0);
    }

    return (
        <div className={classes.root}>
            {
                props.useSimpleForm ?
                    <Typography component={'div'}>{getStepContent(0)}</Typography> :
                    <Stepper activeStep={activeStep} orientation='vertical' className={classes.stepperBorder}>
                        {steps.map((label, index) => (
                            <Step key={label}>
                                <StepLabel>{label}</StepLabel>
                                <StepContent className={classes.stepContent}>
                                    <Typography component={'div'}>{getStepContent(index)}</Typography>
                                    <div className={classes.actionsContainer}>
                                        <div>
                                            <Button
                                                disabled={activeStep === 0}
                                                onClick={handleBack}
                                                className={classes.button}
                                                href={''}>
                                                Back
                                            </Button>
                                            <Button
                                                variant='contained'
                                                color='primary'
                                                onClick={handleNext}
                                                className={classes.button}
                                                href={''}>
                                                {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                                            </Button>
                                        </div>
                                    </div>
                                </StepContent>
                            </Step>
                        ))}
                    </Stepper>
            }
            
            {activeStep === steps.length && props.useSimpleForm && (
                <Paper square elevation={0} className={classes.resetContainer}>
                    <Button onClick={handleReset} className={classes.button} href={''}>
                        Edit
                    </Button>
                </Paper>
            )}
        </div>
    );
}

export default SharpsSelfReportingStepperOneB;
