import React, { useEffect } from 'react';
import MaterialTable from 'material-table';
import { makeStyles } from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';
import { conf } from '../../../config';
import MemberSubmissionService from '../../../services/MemberSubmissionService';
import DateUtils from '../../../services/Utility/DateUtils';
import { ProductTypes, ProductVendor } from '../../../services/ProductService';
import { CardActions } from '@material-ui/core';
import { HttpError } from '../../../services/HttpService';
import NotificationService, {
    DefaultNotificationMessage,
    NotificationTypes
} from '../../../services/NotificationService';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import MemberExcelOptions from '../../Member/components/MemberExcelOptions';

const useStyles = makeStyles(theme => ({
    root: {
        padding: '0 !important',
    },
}));
const headerStyle = {
    minWidth: 100
};
const checkHeaderStyle = {
    minWidth: 50
};

function MemberEditTable(props) {
    const [isSubmitting, setIsSubmitting] = React.useState(false);
    const classes = useStyles();
    const pageSize = conf.defaultRowsPerPage;
    const isSubmitted = props.isSubmitted;
    let tableRef = React.createRef();

    const notesStyle = {
        width: '80px',
    };

    function getColumns(source) {
        if (source === ProductTypes.Otc) {
            return [
                { title: 'Id', field: 'id', hidden: true },
                { title: 'Category', field: 'category', editable: 'never'},
                { title: 'Sub Category', field: 'subCategory', editable: 'never' },
                { title: 'Segment', field: 'segment', editable: 'never' },
                { title: 'Sub Segment', field: 'subSegment', editable: 'never' },
                { title: 'Brand Low', field: 'brandLow', editable: 'never' },
                { title: 'Brand Owner High', field: 'brandOwnerHigh', editable: 'never' },
                { title: 'Revenue', field: 'revenue', editable: 'never' },
                { title: 'Units', field: 'units', editable: 'never' },
                {
                    title: 'Covered',
                    field: 'isCovered',
                    render: rowData => <Checkbox disabled checked={rowData.isCovered} />,
                    editComponent: row =>
                        <Checkbox
                            defaultChecked={row.rowData.isCovered}
                            onChange={e => {
                                row.rowData.isCovered = e.target.checked;
                                row.onRowDataChange(row.rowData);
                            }}
                        />
                },
                { title: 'Notes', field: 'memberNotes', render: rowData => <div style={notesStyle}>{rowData.memberNotes}</div> }
            ];
        } else {
            return [
                { title: 'Id', field: 'id', hidden: true },
                { title: 'Corporation', field: 'corporation', editable: 'never', headerStyle: headerStyle },
                { title: 'Prod Form 1', field: 'prodForm1', editable: 'never', headerStyle: headerStyle },
                { title: 'Prod Form 2', field: 'prodForm2', editable: 'never', headerStyle: headerStyle },
                { title: 'Prod Form 3', field: 'prodForm3', editable: 'never', headerStyle: headerStyle },
                { title: 'NDC', field: 'ndc', editable: 'never', headerStyle: headerStyle },
                { title: 'Product', field: 'product', editable: 'never', headerStyle: headerStyle },
                { title: `${DateUtils.getCurrentYear() - 1} TRx`, field: 'units', editable: 'never', headerStyle: headerStyle },
                { title: `${DateUtils.getCurrentYear() - 1} Sales $`, field: 'revenue', editable: 'never', headerStyle: headerStyle },
                {
                    title: 'Injectable',
                    field: 'isInjectable',
                    render: rowData => <Checkbox disabled checked={rowData.isInjectable} />,
                    editComponent: rowData =>
                        <Checkbox
                            defaultChecked={rowData.rowData.isInjectable}
                            onChange={e => {
                                rowData.rowData.isInjectable = e.target.checked;
                                rowData.onRowDataChange(rowData.rowData);
                            }} />,
                    headerStyle: checkHeaderStyle
                },
                {
                    title: 'Opioid / Benzo',
                    field: 'isOpioidBenzo',
                    render: rowData => <Checkbox disabled checked={rowData.isOpioidBenzo} />,
                    editComponent: rowData =>
                        <Checkbox
                            defaultChecked={rowData.rowData.isOpioidBenzo}
                            onChange={e => {
                                rowData.rowData.isOpioidBenzo = e.target.checked;
                                rowData.onRowDataChange(rowData.rowData);
                            }} />,
                    headerStyle: checkHeaderStyle
                },
                {
                    title: 'Biological Product',
                    field: 'isBiologic',
                    render: rowData => <Checkbox disabled checked={rowData.isBiologic} />,
                    editComponent: rowData =>
                        <Checkbox
                            defaultChecked={rowData.rowData.isBiologic}
                            onChange={e => {
                                rowData.rowData.isBiologic = e.target.checked;
                                rowData.onRowDataChange(rowData.rowData);
                            }} />,
                    headerStyle: checkHeaderStyle
                },
                {
                    title: 'Covered',
                    field: 'isCovered',
                    render: rowData => <Checkbox disabled checked={rowData.isCovered} />,
                    editComponent: rowData =>
                        <Checkbox
                            defaultChecked={rowData.rowData.isCovered}
                            onChange={e => {
                                rowData.rowData.isCovered = e.target.checked;
                                rowData.onRowDataChange(rowData.rowData);
                            }} />,
                    headerStyle: checkHeaderStyle
                },
                {
                    title: 'Notes',
                    field: 'memberNotes',
                    render: rowData => <div style={notesStyle}>{rowData.memberNotes}</div>,
                    headerStyle: headerStyle
                }
            ];
        }
    }

    useEffect(() => {
        tableRef.current.onQueryChange();
    },
        [tableRef, props.companyId, props.year]);

    async function handleSubmitForm() {
        if (props.companyId > 0) {

            setIsSubmitting(true);

            MemberSubmissionService.submitMemberReviewForm(ProductVendor[props.source], props.year, props.companyId)
                .then(() => {
                    NotificationService.fireNotification(NotificationTypes.success, DefaultNotificationMessage.submitted);
                    setIsSubmitting(false);
                    props.refresh();
                })
                .catch((error: HttpError) => {
                    setIsSubmitting(false);
                    NotificationService.fireNotification(NotificationTypes.error, `Error ${error.message}`);
                });
        }
    }

    function handleUploadFile(file: any): Promise<boolean> {

        return MemberSubmissionService.uploadMemberFile(file, ProductVendor[props.source], props.year, props.companyId)
            .then(() => {
                NotificationService.fireConfirmationNotification(NotificationTypes.success, 'Your file has been imported successfully.');

                if (tableRef.current !== null) {
                    tableRef.current.onQueryChange(); //Refresh the table
                }

                return true;
            })
            .catch((error: HttpError) => {
                NotificationService.fireConfirmationNotification(NotificationTypes.error, `Error ${error.message}`);
                throw error;
            });
    }

    return (<React.Fragment>
        <MaterialTable
            tableRef={tableRef}
            options={{
                search: false,
                filtering: false,
                sorting: false,
                toolbar: false,
                pageSize: pageSize,
                actionsColumnIndex: -1,
                emptyRowsWhenPaging: false
            }}
            title={''}
            columns={getColumns(props.source)}
            data={
                query => MemberSubmissionService.getMemberByCompanyId(
                    ProductVendor[props.source],
                    props.year,
                    props.companyId,
                    query)
            }
            className={classes.root}
            editable={props.editable ? {
                onRowUpdate: (newData, oldData) => {
                    newData.companyId = props.companyId;
                    return MemberSubmissionService.updateMemberData(ProductVendor[props.source], newData).then(() => {
                        NotificationService.fireNotification(NotificationTypes.success, DefaultNotificationMessage.saved);
                    }).catch((error: HttpError) => {
                        NotificationService.fireNotification(NotificationTypes.error, `Error ${error.message}`);
                    })
                }
            } : null}
        />
        {
            props.editable && <CardActions>
                <Grid container spacing={2}>
                    <Grid item xs={1}>
                        <Button color='primary' variant='contained' disabled={isSubmitted || isSubmitting}
                            onClick={handleSubmitForm} href={''}>Submit</Button>
                    </Grid>

                    <Grid item xs={12}>
                        <MemberExcelOptions
                            onExcelExport={() => MemberSubmissionService.exportToExcel(ProductVendor[props.source], props.year, props.companyId)}
                            onExcelImport={handleUploadFile}
                            noImport={isSubmitted}
                        />
                    </Grid>
                </Grid>
            </CardActions>
        }
    </React.Fragment>);
}

export default MemberEditTable;
