/* @flow */

import {JSXElement} from '@babel/types';
import SwitchButtons from '../layouts/Admin/components/SwitchButtons';
import AdminPortalDashboard from './AdminPortalDashboard';
import * as React from 'react';
import type {ProductType} from '../services/ProductService';
import {ProductTypes} from '../services/ProductService';

type Props = {};
type State = {|
  productType: ProductType
|};
export default class AdminUpload extends React.Component<Props, State> {
  state = {
    productType: ProductTypes.Otc
  };

  handleSwitchButtonChange = (event: Event, newSwitchButtonValue: ProductType) => {
    if (newSwitchButtonValue) {
      this.setState({
        productType: newSwitchButtonValue
      });
    }
  };

  render(): JSXElement {
    return (
        <React.Fragment>
          <SwitchButtons value={this.state.productType} onChange={this.handleSwitchButtonChange}/>
          <AdminPortalDashboard source={this.state.productType}/>
        </React.Fragment>
    );
  }
}
