import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import DateUtils from '../../../services/Utility/DateUtils.js';
import StringUtils from '../../../services/Utility/StringUtils.js';
import DueDateService from '../../../services/DueDateService.js';
import {DueTypes} from '../../../services/DueDateService';

const useStyles = makeStyles(theme => ({

  snackbar: {
    margin: theme.spacing(1),
    backgroundColor: '#d32f2f',
  },
}));

function SharpsDataDateNotification(props) {
  const classes = useStyles();
  const [dueDate, setDueDate] = useState(null);
  const isSubmitted = props.isSubmitted;
  const isPastDueDate = dueDate && dueDate < new Date();

  useEffect(() => {
    DueDateService.getDueDateByType(DueTypes.sharpsSelfReport)
    .then(result => {
      setDueDate(result);
    });
  }, []);

  const completedDate = props.dateSubmitted ? DateUtils.formatDate('MM-DD-YYYY',
      StringUtils.toDate(props.dateSubmitted)) : '';

  return (
      <div className={classes.root}>
        {isSubmitted ?
            (<SnackbarContent className={classes.snackbar}
                              message={`Form submitted on ${completedDate}.`}/>) :
            isPastDueDate ?
                (<SnackbarContent className={classes.snackbar} message='Cannot edit since the due date has passed.'/>) :
                (<SnackbarContent className={classes.snackbar}
                                  message={`Form not submitted.` + (dueDate ? ` Due by ${DateUtils.formatDate(
                                      'MM-DD-YYYY HH:mm', dueDate)}` : '')}/>)
        }
      </div>
  );
}

export default SharpsDataDateNotification;
