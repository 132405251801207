import React, { useEffect, useState } from 'react';
import MemberSubmissionOTCSelfReporting from './MemberSubmissionOTCSelfReporting';
import MemberSubmissionSharpsSelfReporting from './MemberSubmissionSharpsSelfReporting';
import MemberSubmissionsMemberEditTable from './MemberSubmissionsMemberEditTable';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import VisibilityIcon from '@material-ui/icons/Visibility';
import MemberSubmissionService from '../../../services/MemberSubmissionService';
import InvoiceService from '../../../services/InvoiceService';
import { LoggerService } from '../../../services/LoggerService';
import NotificationService, { NotificationTypes } from '../../../services/NotificationService';
import { HttpError } from '../../../services/HttpService';

const logger = LoggerService.getLogger('MemberSubmissionsView');

function MemberSubmissionView(props) {
    const [invoiceReleasedStatus, setInvoiceReleasedStatus] = useState(null);
    const [editing, setEditing] = useState(false);
    const [reloadToggle, setReloadToggle] = useState(false);
    const isSubmitted = props.status === 'Submitted';
    const editButtonText = editing ? 'Editing Form' : 'Click to Edit';

    useEffect(() => {
        InvoiceService.getInvoiceReleasedStatus()
            .then(setInvoiceReleasedStatus)
            .catch((err) => {
                logger.error(`Unable to get invoiceReleased status for ${props.currentYear}`, err);
            });
    }, [props.currentYear]);

    useEffect(() => {
        setEditing(false);
    }, [props.currentId, props.currentYear, props.currentType]);

    function getData() {
        switch (props.currentType) {
            case 'OTC Private Label':
                return <MemberSubmissionOTCSelfReporting
                    companyId={props.currentId}
                    year={props.currentYear}
                    editable={editing}
                    refresh={props.refresh}
                />;
            case 'Sharps':
                return <MemberSubmissionSharpsSelfReporting
                    companyId={props.currentId}
                    year={props.currentYear}
                    editable={editing}
                    refresh={props.refresh}
                    reloadToggle={reloadToggle}
                />;
            case 'Rx':
                return <MemberSubmissionsMemberEditTable
                    source='Rx'
                    companyId={props.currentId}
                    year={props.currentYear}
                    isSubmitted={isSubmitted}
                    editable={editing}
                    refresh={props.refresh}
                />;
            case 'OTC':
                return <MemberSubmissionsMemberEditTable
                    source='Otc'
                    companyId={props.currentId}
                    year={props.currentYear}
                    isSubmitted={isSubmitted}
                    editable={editing}
                    refresh={props.refresh}
                />;
            default:
                return null;
        }
    }

    function unsubmitForm() {
        MemberSubmissionService.unsubmitForm(props.currentId, props.currentYear, props.currentType)
            .then(() => {
                setReloadToggle(!reloadToggle);
                props.refresh();
                NotificationService.fireNotification(NotificationTypes.success, 'Success!');
            })
            .catch((err: HttpError) => {
                logger.error(`Unable to unsubmit form for ${props.currentId}`, err);
                NotificationService.fireNotification(NotificationTypes.error, `Error ${err.message}`);
            });
    }

    function updatesAllowed() {
        if (invoiceReleasedStatus) {
            var invoicesAreReleased = invoiceReleasedStatus.isReleased ?? false;

            return props.currentYear === (new Date()).getFullYear()     // Can only update forms from the current year
                && !invoicesAreReleased;                                // Cannot update after releasing invoices
        }

        return false;
    }

    return (
        <React.Fragment>
            <Grid container>
                <Grid item xs={2}>
                    <Button disabled={!updatesAllowed()} onClick={() => setEditing(!editing)}>{editButtonText}</Button>
                </Grid>

                <Grid item xs={7}>
                    {(updatesAllowed() && isSubmitted) ? <Button onClick={unsubmitForm}>Unsubmit</Button> : null}
                </Grid>

                {editing ? null :
                    <Grid item xs={3}>
                        <Button disabled={true}><VisibilityIcon /><p class='eye-icon'>Read Only</p></Button>
                    </Grid>
                }
            </Grid>
            {getData()}
        </React.Fragment>
    );
}

export default MemberSubmissionView;