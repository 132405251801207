/* @flow */
import React from 'react';
import {SnackbarProvider} from 'notistack';
import Paper from '@material-ui/core/Paper';
import palette from '../../../theme/palette';
import {AppBar, Divider, TablePagination} from '@material-ui/core';
import type {BatchException, BatchExceptionRes} from '../../../services/BatchExceptionService';
import BatchExceptionService from '../../../services/BatchExceptionService';
import Toolbar from '@material-ui/core/Toolbar';
import InputModal from './InputModal';
import {styled} from '@material-ui/styles';
import Box from '@material-ui/core/Box';
import {LoggerService} from '../../../services/LoggerService';
import {JSXElement} from '@babel/types';
import SecondaryLineLoader from '../../../components/Utils/SecondaryLineLoader';
import {conf} from '../../../config';

const logger = LoggerService.getLogger('ExceptionManagement');

const ExceptionAppBar = styled(AppBar)({
  padding: '0px',
  margin: '0px',
  width: '100%',
  color: palette.primary,
});

type State = {|
  page: number,
  batchId: number,
  resolvedCount: number,
  pendingCount: number,
  unresolvedCount: number,
  exceptions: Array<BatchException>,
  isLoading: boolean
|};

type Props = {|
  setCanProgress: boolean => void,
  batchId: number
|};

export default class ExceptionManagement extends React.Component<Props, State> {
  state: State = {
    batchId: 0,
    exceptions: [],
    isLoading: true,
    page: 0,
    resolvedCount: 0,
    pendingCount: 0,
    unresolvedCount: 0
  };
  
  componentDidMount(): void {
    this.setState({batchId: this.props.batchId});
    this.props.setCanProgress(false);
    this.fetchExceptions();
  }

  componentDidUpdate(prevProps: Props, prevState: State): void {
    if (this.props.batchId !== prevProps.batchId || this.state.page !== prevState.page || this.state.pendingCount
        !== prevState.pendingCount) {
      this.fetchExceptions();
    }
  }

  fetchExceptions(): void {
    BatchExceptionService.getBatchExceptions(this.props.batchId, this.state.page, conf.defaultRowsPerPage).then(
        (batch: (BatchExceptionRes | null)) => {
          if (!batch) {
            logger.error('Got null batch exceptions');
            return;
          }

          this.setState({
            exceptions: batch.batchExceptions,
            resolvedCount: batch.resolvedCount,
            pendingCount: batch.pendingCount,
            unresolvedCount: batch.unresolvedCount,
            isLoading: false,
          });
          this.props.setCanProgress(this.state.pendingCount === 0);
        });
  }

  onChangePage(e: Event, page: number): void {
    this.setState({page: page});
  };

  renderExceptions(exceptions: Array<BatchException>): JSXElement {
    return exceptions.map((e: BatchException, idx: number) => {
      return (
          <div key={idx} style={{width: '100%', height: '70px',}}>
            <Box
                display='flex'
                flexWrap='wrap'
            >
              <Box
                  display='flex'
                  justifyContent='space-between'
                  style={{
                    width: '100%',
                  }}
                  m={1}
              >
                <Box
                    display='flex'
                    justifyContent='space-between'
                    style={{
                      width: '100%',
                    }}
                    m={1}
                >
                  <ExceptionAppBar position='static'>
                    <Toolbar
                        className='exception-toolbar'
                    >
                      <Box
                          display='flex'
                          justifyContent='space-around'
                          m={1}
                      >
                        <i className="material-icons exception-alert-icon">
                          error_outline
                        </i>
                        <p>
                        {e.reason} {e.companyName}
                        </p>
                      </Box>
                      <InputModal exception={e}
                                  onIgnore={(e, idx) => this.ignoreException(e, idx)}
                                  onResolve={(e, companyId, idx) => this.resolveException(e, companyId, idx)}
                                  idx={idx}/>

                    </Toolbar>
                  </ExceptionAppBar>
                </Box>
              </Box>
            </Box>
          </div>
      );
    });
  }

  ignoreException(e: BatchException, idx: number): void {
    BatchExceptionService.ignoreBatchException(e.companyName,
        e.batchExceptionId).then(() => {
      this.setState({
        exceptions: this.state.exceptions.filter((_, i) => i !== idx),
        unresolvedCount: this.state.unresolvedCount + 1,
        pendingCount: this.state.pendingCount - 1
      });
      this.props.setCanProgress(this.state.pendingCount === 0);
    });
  };

  resolveException(e: BatchException, companyId: number, idx: number): void {
    BatchExceptionService.resolveBatchException(companyId, e.companyName,
        e.batchExceptionId).then(() => {
      this.setState({
        exceptions: this.state.exceptions.filter((_, i) => i !== idx),
        resolvedCount: this.state.resolvedCount + 1,
        pendingCount: this.state.pendingCount - 1
      });
      this.props.setCanProgress(this.state.pendingCount === 0);
    });
  };

  render(): JSXElement {
    if (this.state.isLoading) {
      return <SecondaryLineLoader/>;
    }

    return (
        <SnackbarProvider>

          <p className='flex-center pad-15'>If the company is not found, try
            typing an alternate spelling of the name below to see if there is a
            match. For example, if "ABA Co." is not found, try typing in
            just "ABC" or "ABC Company"
            If a match is still not found, try again a different spelling.
            If a match is not found, make a note of the company to determine the
            issue, and then select "IGNORE EXCEPTION". This does not resolve the
            exception, but it will remove the entry from the market share
            calculation.</p>


          <Paper>
            <div className='flex-end'>
              <p className='pending-txt'>{this.state.pendingCount}</p> <p className='exception-txt'>Pending</p>
              <div className='divider'/>
              <p className='unresolved-txt'>{this.state.unresolvedCount}</p> <p className='exception-txt'> Unresolved</p>
              <div className='divider'/>
              <p className='resolved-txt'>{this.state.resolvedCount}</p> <p className='exception-txt'> Resolved</p>
            </div>
            <Divider component={'hr'}/>
            {this.renderExceptions(this.state.exceptions)}
          </Paper>
          <TablePagination
              component={'div'}
              rowsPerPageOptions={[]}
              count={this.state.pendingCount}
              page={this.state.page}
              onChangePage={(e, page) => this.onChangePage(e, page)}
              rowsPerPage={conf.defaultRowsPerPage}
          />
        </SnackbarProvider>
    );
  }
}
