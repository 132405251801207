import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import CardHeader from '@material-ui/core/CardHeader';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import {DatePicker, MuiPickersUtilsProvider} from '@material-ui/pickers';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';
import FilterIcon from '@material-ui/icons/FilterList';
import Divider from '@material-ui/core/Divider';
import SearchIcon from '@material-ui/icons/Search';
import Popover from '@material-ui/core/Popover';
import DateFnsUtils from '@date-io/date-fns';
import Chip from '@material-ui/core/Chip';

const useStyles = makeStyles(theme => ({
  root: {
    margin: 'auto',
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  cardHeader: {
    padding: theme.spacing(1, 2),
  },
  list: {
    height: 230,
    backgroundColor: theme.palette.background.paper,
    overflow: 'auto',
  },
  button: {
    margin: theme.spacing(0.5, 0),
  },
  input: {
    marginLeft: 8,
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    width: 1,
    height: 28,
    margin: 4,
  },
}));

function MemberSubmissionFilters(props) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [statusChecked, setStatusChecked] = React.useState(props.statusChecked);
  const [typeChecked, setTypeChecked] = React.useState(props.typeChecked);
  const [keyword, setKeyword] = React.useState(props.keyword);
  const [startDate, setStartDate] = React.useState(props.startDate);
  const [endDate, setEndDate] = React.useState(props.endDate);

  //filters

  function handleClick(event) {
    setAnchorEl(event.currentTarget);
  }

  function handleClose() {
    setAnchorEl(null);
  }

  function saveFilters() {
    props.saveFilters({
      startDate: startDate,
      endDate: endDate,
      statusChecked: statusChecked,
      typeChecked: typeChecked,
      keyword: keyword
    });
  }

  function resetFilters() {
    setStartDate((new Date()).getFullYear());
    setEndDate((new Date()).getFullYear());
    setStatusChecked([]);
    setTypeChecked([]);
    setKeyword('');
  }

  const handleToggleStatus = value => () => {
    const currentIndex = statusChecked.indexOf(value);
    const newChecked = [...statusChecked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setStatusChecked(newChecked);
  };
  const keywordKeyDown = () => event => {
    if (event.type === 'keydown' && (event.key !== 'Enter')) {
      return;
    }
    saveFilters();
  };

  const handleToggleType = value => () => {
    const currentIndex = typeChecked.indexOf(value);
    const newChecked = [...typeChecked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setTypeChecked(newChecked);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  return (
      <React.Fragment>
        <Paper className={classes.root}>
          <InputBase
              id='inputSearchCompanies'
              className={classes.input}
              placeholder='Search Companies'
              value={keyword}
              inputProps={{'aria-label': 'search companies'}}
              onChange={e => setKeyword(e.target.value)}
              onKeyDown={keywordKeyDown()}
          />
          <Divider className={classes.divider}/>
          <IconButton id='btnSearchCompanies' onClick={saveFilters} color='primary' className={classes.iconButton} aria-label='filter'>
            <SearchIcon/>
          </IconButton>
          <Divider className={classes.divider}/>
          <IconButton onClick={handleClick} color='primary' className={classes.iconButton} aria-label='filter'>
            <FilterIcon/>
          </IconButton>

        </Paper>
        {props.typeChecked.concat(props.statusChecked).map((name, index) => {
          return (<Chip
              variant='outlined'
              size='small'
              color='secondary'
              key={index}
              label={name}
              className={classes.chip}
              component={'div'}/>);
        })}
        <Popover
            id={id}
            open={open}
            onClose={handleClose}
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <CardHeader
                  title='FILTERS'
                  action={<Button onClick={() => resetFilters()}>RESET</Button>}/>
            </Grid>
            <Grid item xs={6}>
              <CardHeader
                  title='Form/Data Type'/>
              <List className={classes.list}>
                {['Sharps', 'OTC Private Label', 'OTC', 'Rx'].map(value => {
                  const labelId = `checkbox-list-label-${value}`;
                  return (
                      <ListItem key={value} role={undefined} dense button onClick={handleToggleType(value)}>
                        <ListItemIcon>
                          <Checkbox
                              edge='start'
                              checked={typeChecked.indexOf(value) !== -1}
                              tabIndex={-1}
                              disableRipple
                              inputProps={{'aria-labelledby': labelId}}
                          />
                        </ListItemIcon>
                        <ListItemText id={labelId} primary={value}/>
                      </ListItem>
                  );
                })}
              </List>
            </Grid>
            <Grid item xs={6}>
              <CardHeader
                  title='Status'/>
              <List className={classes.list} component={'ul'}>
                {['Submitted', 'Not Started', 'Saved'].map(value => {
                  const labelId = `checkbox-list-label-${value}`;
                  return (
                      <ListItem key={value}
                                role={undefined}
                                dense
                                button
                                onClick={handleToggleStatus(value)}
                                component={'li'}>
                        <ListItemIcon>
                          <Checkbox
                              edge='start'
                              checked={statusChecked.indexOf(value) !== -1}
                              tabIndex={-1}
                              disableRipple
                              inputProps={{'aria-labelledby': labelId}}
                          />
                        </ListItemIcon>
                        <ListItemText id={labelId} primary={value}/>
                      </ListItem>
                  );
                })}
              </List>

              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DatePicker
                    views={['year']}
                    label='Start Year'
                    variant='inline'
                    value={new Date(startDate, 1, 1)}
                    minDate={new Date('1-1-2018')}
                    maxDate={new Date()}
                    onChange={e => setStartDate(e.getFullYear())}
                />
                <DatePicker
                    views={['year']}
                    label='End Year'
                    variant='inline'
                    value={new Date(endDate, 1, 1)}
                    minDate={new Date('1-1-2018')}
                    maxDate={new Date()}
                    onChange={e => setEndDate(e.getFullYear())}
                />
              </MuiPickersUtilsProvider>
            </Grid>
          </Grid>
        </Popover>
      </React.Fragment>
  );
}

export default MemberSubmissionFilters;
