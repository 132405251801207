/* @flow */

import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Dividers from '../../../components/DataDisplay/Divider';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import {ClassNameMap} from '@material-ui/styles/withStyles';
import {JSXElement} from '@babel/types';
import Grid from '@material-ui/core/Grid';
import InvoiceHistoryService from '../../../services/InvoiceService';
import {HttpError} from '../../../services/HttpService';
import type {BankingInfo} from '../../../services/InvoiceService';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

const styles = theme => ({
  root: {
    padding: theme.spacing(3, 2),
  },
  paper: {
    backgroundColor: '#f8fafc',
  },
  border: {
    border: '1px solid #DFE3E8;',
    borderRadius: '4px',
    boxShadow: '0 0 0 0 rgba(255, 105, 135, .3)',
    backgroundColor: '#ffffff',
  },
  info: {
    color: '#66788A',
    fontWeight: '400',
    lineHeight: '14px',
  },
  padding: {
    padding: '5px 0px'
  },

});

type Props = {|
  classes: ClassNameMap,
|};
type State = {|
  routing:string,
  account: string,
  tax: string
|};

class InvoiceBankAccount extends React.Component<Props, State> {
  state: State = {
    routing:'',
    account: '',
    tax: ''
  };
  componentDidMount(){
    InvoiceHistoryService.getBankingInfo()
    .then((info) => {
      this.setState({
        ...info
      });
    });
  }

  componentDidMount() {
    InvoiceHistoryService.getBankingInfo()
    .then((info) => {
      this.setState({
        ...info
      });
    });
  }

  render(): JSXElement {
    const {classes} = this.props;

    return (



        <ListItem className={classes.border}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <ListItemText
                  primary='Bank Account Information'
              />
            </Grid>
            <Dividers/>
            <Grid item xs={6}>
              <ListItemText
                  secondary='Pharmaceutical Product Stewardship Work Group 1800 M Street, NW | Suite 400 South | Washington, DC 20036 Phone: (202)495-3131 | Fax: (202)530-0659 finance@ppswg.org'
              />
            </Grid>
            <Grid item xs={6}>
              <div className={classes.info}>
                <p className={classes.padding}>Routing #: {this.state.routing}</p>
                <p className={classes.padding}>Account #: {this.state.account}</p>
                <p className={classes.padding}>Tax ID: {this.state.tax}</p>
              </div>
            </Grid>
          </Grid>
        </ListItem>

    );
  }
}

export default withStyles(styles)(InvoiceBankAccount);
