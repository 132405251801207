import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import SharpsSelfReportingStepperOneA from '../components/SharpsSelfReportingStepperOneA';
import SharpsSelfReportingStepperOneB from '../components/SharpsSelfReportingStepperOneB';
import SharpsSelfReportingStepperOneC from '../components/SharpsSelfReportingStepperOneC';
import DateUtils from '../../../services/Utility/DateUtils';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '5%',
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
}));

function SharpsAccordionSectionOne(props) {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);
  const handleChange = panel => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  if (!props) {
    return <div/>;
  }

  const availableData = props.iqviaData.filter(elm => props.stepB.map(b => b.iqviaDataId).indexOf(elm.iqviaDataId) < 0)
  .filter(elm => props.stepA.map(b => b.iqviaDataId).indexOf(elm.iqviaDataId) < 0);

  return (
      <div className={classes.root}>
        <ExpansionPanel expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
          <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon/>}
              aria-controls='panel1bh-content'
              id='panel1bh-header'
              href={''}>
            <Typography className={classes.heading}>1A.</Typography>
            <Typography className={classes.secondaryHeading}>
                      <b>Please check the box next to the products "without" a permanently attached sharp to move it to the right.</b>
                      <span> (E.g, vials, cartridges, ampules, pre-filled devices with sharp <u>not</u> permanently attached and other presentations of
                      Medicine Leading to the Use of a Sharp or medicine packaged with a sharp, but the sharp is <u>not</u> permanently attached.)</span>
            </Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <SharpsSelfReportingStepperOneA submitted={props.submitted}
                                            data={props.stepA}
                                            addData={props.addData}
                                            removeData={props.removeData}
                                            iqvia={availableData}
                                            useSimpleForm={props.useSimpleForm}
            />
          </ExpansionPanelDetails>
        </ExpansionPanel>
        <ExpansionPanel expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
          <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon/>}
              aria-controls='panel2bh-content'
              id='panel2bh-header'
              href={''}>
            <Typography className={classes.heading}>1B.</Typography>
            <Typography className={classes.secondaryHeading}>
                      <b>Please check the box next to the products "with" a permanently attached sharp to move it to the right.</b>
                      <span> (E.g., pre-filled autoinjector with permanently attached sharp, pre-filled pen devices or pen-like devices with
                      permanently attached sharp, pre-filled syringe with permanently attached sharp, pre-filled IMITREX-style syringe with
                      permanently attached sharp and other presentations of pre-filled devices with permanently attached sharp.</span>
            </Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
                  <SharpsSelfReportingStepperOneB submitted={props.submitted}
                                                  data={props.stepB}
                                                  addData={props.addData}
                                                  removeData={props.removeData}
                                                  iqvia={availableData}
                                                  useSimpleForm={props.useSimpleForm}
            />
          </ExpansionPanelDetails>
        </ExpansionPanel>
        <ExpansionPanel expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
          <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon/>}
              aria-controls='panel4bh-content'
              id='panel4bh-header'
              href={''}>
            <Typography className={classes.heading}>1C</Typography>
            <Typography className={classes.secondaryHeading}>
              If your company is the producer of any medicine leading to the use of a sharp or packaged
              with a sharp (sharp NOT permanently attached) but your products were not captured in the IQVIA data
              provided, please indicate product name and TRx units (e.g. 100,000 prescriptions) dispensed nationally
              from 1/1/{DateUtils.getCurrentYear() - 1} through 12/31/{DateUtils.getCurrentYear() - 1}.
            </Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <Typography component={'div'}>
              <SharpsSelfReportingStepperOneC submitted={props.submitted}
                                              data={props.stepC}
                                              addAdditionalData={props.addAdditionalData}
                                              removeAdditionalData={props.removeAdditionalData}
                                              updateAdditionalData={props.updateAdditionalData}
              />
            </Typography>
          </ExpansionPanelDetails>
        </ExpansionPanel>
      </div>
  );
}

export default SharpsAccordionSectionOne;
