import React from 'react';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Dividers from '../components/DataDisplay/Divider';
import OTCReportFormChecks from '../layouts/Member/components/OTCReportFormChecks';
import OTCDateNotification from '../layouts/Member/components/OTCDateNotification';
import StringUtils from '../services/Utility/StringUtils.js';
import DateUtils from '../services/Utility/DateUtils.js';
import DueDateService from '../services/DueDateService.js';
import SecondaryProgressLoader from '../components/Utils/SecondaryProgressLoader';
import { DueTypes } from '../services/DueDateService';
import type { OtcForm } from '../services/MemberEditService';
import { MemberEditService } from '../services/MemberEditService';
import { HttpError } from '../services/HttpService';
import DialogContentText from '@material-ui/core/DialogContentText';
import NotificationService, { DefaultNotificationMessage, NotificationTypes } from '../services/NotificationService';
import { Typography, withStyles } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import TextBox from '../components/Input/TextBox';

const styles = theme => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    margFive: {
        margin: '10px 0 0 0'
    },
    redType: {
        color: '#F6174D'
    },
    lightFont: {
        fontWeight: 300,
    },
    infoMargin: {
        margin: '10px 0px 10px 0px',
    },
    boldType: {
        fontWeight: 'bold'
    },
    boldRedType: {
        color: '#F6174D',
        fontWeight: 'bold',
        padding: '20px 0px 0px 0px',

    },
    downloadButton: {
        width: '100%'
    },
    formControl: {
        margin: '24px 0px 24px 0px',
    }
});

const defaultOtcForm: OtcForm = {
    hasPrivateLabel: null,
    isSubmitted: false,
    dateSubmittedUtc: false,
    companyId: 0
};

const maxDecimal = 1000000000000000;

type Props = {|
    classes: ClassNameMap
        |}

type State = {|
    form: OtcForm,
        dueDate: Date,
            disabled: boolean,
                checkedConfirmation: boolean
                    |}

class MemberOTCSelfReporting extends React.Component<Props, State> {
    state: State = {
        form: defaultOtcForm,
        dueDate: null,
        disabled: false,
        checkedConfirmation: false
    };

    loadData = (): void => {
        MemberEditService.getForm().then((form: OtcForm) => {
            this.setState({ form: form });
        });

        DueDateService.getDueDateByType(DueTypes.otcPrivateLabel)
            .then((result: Date) => {

                this.setState({
                    dueDate: result
                });
            });
    };

    componentDidMount(): void {
        this.loadData();
    }

    //Handles changes to the form question.
    handleFormCheckChange = (event, value): void => {

        this.setState({
            form: { ...this.state.form, hasPrivateLabel: value === 'yes' }
        });
    };

    //Handles changes to the revenue question.
    handleRevenueChange = (value: string): void => {
        let revenue;
        if (value === '') {
            revenue = null;
        } else {
            revenue = StringUtils.toNumber(value);
        }

        if (!isNaN(revenue) && revenue >= 0 && revenue <= maxDecimal) {
            this.setState({
                form: { ...this.state.form, revenue: revenue }
            });
        }
    }

    //Handles changes to the total covered units question.
    handleTotalChange = (value: string): void => {
        let totalCoveredUnits;
        if (value === '') {
            totalCoveredUnits = null;
        } else {
            totalCoveredUnits = StringUtils.toNumber(value);
        }

        if (!isNaN(totalCoveredUnits) && totalCoveredUnits >= 0 && totalCoveredUnits <= maxDecimal) {
            this.setState({
                form: { ...this.state.form, totalCoveredUnits: totalCoveredUnits }
            });
        }
    }

    //Sweet alert
    handlePrintButton = (): void => {
        NotificationService.fireNotification(NotificationTypes.success, 'Downloaded!');
    };

    //Handles when the submit button is clicked.
    //Disables form and reloads it on successful submit.
    handleSubmit = (): void => {
        this.postForm(true);
    };

    handleSave = (): void => {
        this.postForm(false);
    };

    postForm = (isSubmitting: boolean): void => {
        const message = isSubmitting ? DefaultNotificationMessage.submitted : DefaultNotificationMessage.saved;
        this.setState({ disabled: true },
            () => {

                MemberEditService.postSelfReportedOtc({
                    ...this.state.form,
                    isSubmitted: isSubmitting
                })
                    .then(() => {
                        if (isSubmitting !== true) {
                            this.setState({ disabled: false });
                        }
                        //Triggers reload of form after successful post.
                        this.loadData();
                        NotificationService.fireNotification(NotificationTypes.success, message);
                    })
                    .catch((error:
                        HttpError) => {
                        //Re-enables form after failed post.
                        this.setState({ disabled: false });
                        NotificationService.fireNotification(NotificationTypes.error, `Error ${error.message}`);
                    });
            });
    };

    render() {
        const { classes } = this.props;
        const { form, dueDate, checkedConfirmation } = this.state;
        const isPastDueDate = dueDate && dueDate < new Date();
        let emptyWhenShouldHaveData = false;
        if (form && form.hasPrivateLabel && (form.revenue === null || form.revenue <= 0
            || form.totalCoveredUnits === null || form.totalCoveredUnits <= 0)) {
            emptyWhenShouldHaveData = true;
        }
        const disabled = isPastDueDate || this.state.disabled;

        const currentYear = DateUtils.getCurrentYear();
        if (form.companyId > 0) {
            return (
                <div className={classes.root}>
                    <Grid container spacing={3}>
                        <Grid item xs={8}>
                            <Card className={classes.card}>
                                <Grid
                                    container
                                    direction='row'
                                    justify='space-between'
                                    alignItems='center'
                                >
                                    <Grid container>
                                        <Grid item xs={12}>
                                            <Typography variant={'h4'}>
                                                OTC Private Label Self-Reporting Form
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} className={classes.infoMargin}>
                                            <Typography className={classes.lightFont}>
                                                Data provided by program participants will be seen only by authorized staff
                                                and used only for the purpose of the cost allocation under the terms of the
                                                non-disclosure agreement. This information will be used to determine your
                                                company's share portion of take-back program costs that will be allocated
                                                to private label/store-brand over the counter (OTC) producers.
                                            </Typography>
                                            <Typography className={classes.boldRedType}>
                                                If you update any information on this form and need to return later to complete the form,
                                                you must click the "Save" button at the bottom of the page, or all progress will be lost.
                                                When you have completed the form, you must click the "Submit" button.
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    <OTCDateNotification
                                        isSubmitted={form.isSubmitted}
                                        dateSubmitted={
                                            form.dateSubmittedUtc
                                        }
                                        dueDate={dueDate}
                                        isPastDueDate={isPastDueDate}
                                    />
                                </Grid>
                                <Dividers />
                                <CardContent>
                                    <p>1. Does your company manufacture covered products under a store-brand or private label?</p>
                                    <OTCReportFormChecks value={form.hasPrivateLabel} disabled={disabled || form.isSubmitted} onChange={
                                        this.handleFormCheckChange} />
                                    {
                                        form.hasPrivateLabel
                                            ? (
                                                <React.Fragment>
                                                    <div className={classes.formControl}>
                                                        <p> 2. Provide the consolidated revenue associated with covered OTC Private Label
                                                            units (UPC) shipped nationally between 1/1/{currentYear - 1} and 12/31/{currentYear - 1}.
                                                            Revenue is defined as the indirect (wholesalers) and direct (manufacturers) sales of
                                                            product to an outlet (retail - pharmacy, mail, etc.). The figure reported should
                                                            represent the combined dollar sales volume paid by all outlets for all covered products,
                                                            inclusive of on-invoice discounts and excluding rebates or prompt-payment discounts. <b>Please
                                                            enter a whole dollar amount. Do not include decimals.</b>
                                                        </p>

                                                        <TextBox
                                                            id='revenue_input'
                                                            label='Total Revenue'
                                                            value={form.revenue ?? ''}
                                                            onChange={this.handleRevenueChange}
                                                            disabled={disabled || form.isSubmitted}
                                                        />
                                                    </div>
                                                    <div>
                                                        <p> 3. Provide the number of covered units shipped nationally (UPC) for sale under a
                                                            store-brand or private label between 1/1/{
                                                                currentYear - 1} and 12/31/{currentYear - 1} by your company and affiliates.</p>

                                                        <TextBox
                                                            id='total_units_input'
                                                            label='Total Covered Units'
                                                            value={form.totalCoveredUnits ?? ''}
                                                            onChange={this.handleTotalChange}
                                                            disabled={disabled || form.isSubmitted}
                                                        />
                                                    </div>
                                                </React.Fragment>
                                            )
                                            : form.hasPrivateLabel === false
                                                ? (
                                                    <p>Please click submit and then move to the next section.</p>
                                                )
                                                : null
                                    }
                                </CardContent>
                                <CardActions>
                                    {form.isSubmitted ? null :
                                        <React.Fragment>
                                            <Checkbox onChange={e => {
                                                this.setState({ checkedConfirmation: e.target.checked });
                                            }} />
                                            <span>
                                                On behalf of my Company, I attest that the information provided in the Invoicing Portal is true, complete
                                                and accurate to the best of my knowledge and belief.
                                            </span>
                                        </React.Fragment>
                                    }
                                    <Button
                                        color='primary'
                                        disabled={disabled || !checkedConfirmation || form.isSubmitted || emptyWhenShouldHaveData
                                            || form.hasPrivateLabel === null}
                                        onClick={this.handleSubmit}
                                        href={''}>Submit</Button>
                                    <Button
                                        color='primary'
                                        disabled={disabled || form.isSubmitted}
                                        href={''}
                                        onClick={this.handleSave}>Save</Button>
                                </CardActions>
                                {emptyWhenShouldHaveData ? <DialogContentText color='error' className={classes.margFive}>
                                    Please input total revenue and covered units
                                </DialogContentText> : null}
                            </Card>
                        </Grid>
                        <Grid item xs={4}>
                            <Card className={classes.card}>
                                <Grid container>
                                    <Grid item xs={12}>
                                        <Typography variant={'h4'}>
                                            Guidelines
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} className={classes.infoMargin}>
                                        <Typography className={classes.lightFont}>
                                            The following are "covered" and "not covered" product categories to assist you in completing the
                                            OTC private label self-reporting form. This information is provided only for your guidance and
                                            is
                                            not a substitute for your own interpretation of the regulations and ordinances.
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Dividers />
                                <CardContent>
                                </CardContent>
                                <CardActions>
                                    <Grid container spacing={2}>
                                        <Grid item xs={6}>
                                            <a download href='/files/PPSWG OTC Private Label Covered Products List.pdf'>
                                                <Button className={classes.downloadButton} variant={'contained'} color='primary'
                                                    onClick={this.handlePrintButton} href={''}>Covered Private Label
                                                    Products</Button>
                                            </a>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <a download
                                                href='/files/PPSWG OTC Private Label Products Not Covered List.pdf'>
                                                <Button className={classes.downloadButton} variant={'contained'} color='primary'
                                                    onClick={this.handlePrintButton} href={''}>Not Covered Private Label
                                                    Products</Button>
                                            </a>
                                        </Grid>
                                    </Grid>
                                </CardActions>
                            </Card>
                        </Grid>
                    </Grid>
                </div>
            );
        } else {
            return <SecondaryProgressLoader />;
        }
    }
}

export default withStyles(styles)(MemberOTCSelfReporting);
