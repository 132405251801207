import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import CustomStepper from '../layouts/Admin/components/AdminStepper';
import Dividers from '../components/DataDisplay/Divider';
import {ProductTypes} from '../services/ProductService';

const useStyles = makeStyles({
  card: {
    minWidth: 275,
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
});

function StepperCardAdmin(props) {
  const classes = useStyles();

  function getTitle(source) {
    if (source === ProductTypes.Rx) {
      return 'Rx Data';
    }
    if (source === ProductTypes.Otc) {
      return 'OTC Data';
    }
  }

  return (
      <Card className={classes.card}>
        <CardHeader
            title={getTitle(props.source)}
            subheader='Purchased Data'
        />
        <Dividers/>
        <CardContent>
          <CustomStepper source={props.source}/>
        </CardContent>
        <CardActions/>
      </Card>
  );
}

export default StepperCardAdmin;
