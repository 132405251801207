/* @flow */

import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import CardHeader from '@material-ui/core/CardHeader';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import {DatePicker, MuiPickersUtilsProvider} from '@material-ui/pickers';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';
import FilterIcon from '@material-ui/icons/FilterList';
import Divider from '@material-ui/core/Divider';
import SearchIcon from '@material-ui/icons/Search';
import Popover from '@material-ui/core/Popover';
import DateFnsUtils from '@date-io/date-fns';
import Chip from '@material-ui/core/Chip';
import InvoiceResults from './InvoiceResults';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import DateUtils from '../../../services/Utility/DateUtils';
import InvoiceService from '../../../services/InvoiceService';
import type {InvoiceSearchResult} from '../../../services/InvoiceService';
import type {PagedResponse} from '../../../services/Utility/ServiceUtil';
import {HttpError} from '../../../services/HttpService';
import NotificationService, {
  DefaultNotificationMessage,
  NotificationTypes
} from '../../../services/NotificationService';

const styles = theme => ({
  root: {
    margin: 'auto',
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  cardHeader: {
    padding: theme.spacing(1, 2),
  },
  list: {
    height: 230,
    backgroundColor: theme.palette.background.paper,
    overflow: 'auto',
  },
  button: {
    margin: theme.spacing(0.5, 0),
  },
  input: {
    marginLeft: 8,
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    width: 1,
    height: 28,
    margin: 4,
  }
});

const startYear = 2019;

type Props = {|
  classes: ClassNameMap,
  results: Array<InvoiceSearchResult>,
  onSelectInvoice: InvoiceSearchResult => void,
  onUpdateResults: Array<InvoiceSearchResult> => void,
|};
type State = {|
  keyword: string,
  year: number,
  isLoading: boolean,
  hasNextPage: boolean,
  page: number,
  pageSize: number
|}

class InvoiceSearch extends React.Component<Props, State> {
  state: State = {
    keyword: '',
    year: DateUtils.currentYear,
    isLoading: false,
    hasNextPage: false,
    page: 0,
    pageSize: 10
  };

  getYearOptions = () => {
    return Array((DateUtils.currentYear + 1) - startYear)
    .fill()
    .map((_, i) =>
        <MenuItem key={i} value={startYear + i}>{startYear + i}</MenuItem>
    );
  };

  searchInvoices = (): void => {
    const {keyword, year, page, pageSize, isLoading} = this.state;

    if (isLoading) {
      return;
    }

    // Set page to zero
    this.setState({isLoading: true, page: 0}, () => {
      InvoiceService.searchInvoices({keyword, year, page: 0, pageSize})
      .then((result: PagedResponse<InvoiceSearchResult>) => {
        this.setState({
          hasNextPage: result.hasNextPage,
          isLoading: false
        });
        this.props.onUpdateResults(result.data);
      })
      .catch((error: HttpError) => {
        NotificationService.fireNotification(NotificationTypes.error, `Error ${error.message}`);
        // Reset state on error.
        this.setState({
          isLoading: false,
          page: page
        });
      });
    });
  };

  loadMore = (): void => {
    const results = this.props.results;
    const {keyword, year, page, pageSize, isLoading} = this.state;

    if (isLoading) {
      return;
    }

    // Increment page and append results.
    this.setState({isLoading: true, page: page + 1}, () => {
      InvoiceService.searchInvoices({keyword, year, page: page + 1, pageSize})
      .then((result: PagedResponse<InvoiceSearchResult>) => {
        this.setState({
          hasNextPage: result.hasNextPage,
          isLoading: false
        });
        this.props.onUpdateResults(results.concat(result.data));
      })
      .catch((error: HttpError) => {
        NotificationService.fireNotification(NotificationTypes.error, `Error ${error.message}`);
        // Reset state on error
        this.setState({
          isLoading: false,
          page: page
        });
      });
    });
  };

  keywordKeyDown = (): void => event => {
    if (event.type === 'keydown' && (event.key !== 'Enter')) {
      return;
    }
    this.searchInvoices();
  };

  downloadExcel = () => {
    InvoiceService.exportInvoicesToExcel(this.state.year);
  }

  render() {
    const {classes, onSelectInvoice, results} = this.props;
    const {keyword, year, isLoading, hasNextPage} = this.state;

    return (
        <React.Fragment>
          <Paper className={classes.root}>
            <InputBase
                id='inputSearchInvoicesByCompany'
                className={classes.input}
                placeholder='Enter Company Name'
                value={keyword}
                inputProps={{'aria-label': 'search invoices'}}
                onChange={e => this.setState({keyword: e.target.value})}
                onKeyDown={this.keywordKeyDown()}
            />
            <Divider className={classes.divider}/>
            <IconButton id='btnSearchInvoices' onClick={this.searchInvoices} color='primary'
                        className={classes.iconButton} aria-label='filter'>
              <SearchIcon/>
            </IconButton>
          </Paper>
          <Paper className={classes.root}>
            <Select
                className={classes.input}
                value={year}
                onChange={e => this.setState({year: e.target.value})}
            >
              {this.getYearOptions()}
            </Select>
            <Divider className={classes.divider}/>
            <Button  className={classes.confirmButton} variant='contained' size='large' color='primary' onClick={this.downloadExcel}>
              Download
            </Button>
          </Paper>
          <InvoiceResults
              results={results}
              loading={isLoading}
              hasNextPage={hasNextPage}
              selectInvoice={onSelectInvoice}
              loadMore={this.loadMore}
          />
        </React.Fragment>
    );
  }
}

export default (withStyles(styles)(InvoiceSearch));
