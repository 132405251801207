/* @flow */

import React from 'react';
import {JSXElement} from '@babel/types';
import TopBarMember from './components/TopBarMember';
import NavTabsMember from './components/NavTabsMember';
import AuthService from '../../services/AuthService';
import {Redirect} from 'react-router';
import {conf} from '../../config';

type Props = {|
  setUser: (user: Object) => void
|};
type State = {|
  user: any
|};

export default class MemberLayout extends React.Component<Props, State> {
  state: State = {
    user: null
  };

  render(): JSXElement {
    if (!AuthService.isLoggedIn()) {
      return <Redirect to={conf.pathRoot}/>;
    }

    return (
        <React.Fragment>
          <TopBarMember/>
          <NavTabsMember user={AuthService.getUser()}/>
        </React.Fragment>
    );
  }
}
