/* @flow */

import React from 'react';
import {Select, withStyles} from '@material-ui/core';
import InvoiceCompanyName from '../layouts/Member/components/InvoiceCompanyName';
import InoviceDataPoints from '../layouts/Member/components/InoviceDataPoints';
import InvoiceBankAccount from '../layouts/Member/components/InvoiceBankAccount';
import InvoiceDialogTable from '../layouts/Member/components/InvoiceDialogTable';
import InvoiceDisclaimer from '../layouts/Member/components/InvoiceDisclaimer';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import FilterIcon from '@material-ui/icons/FilterList';
import SearchIcon from '@material-ui/icons/Search';
import ViewInvoice from './ViewInvoice';
import InvoiceSearch from '../layouts/Admin/components/InvoiceSearch';
import type {InvoiceSearchResult} from '../services/InvoiceService';
import { ClassNameMap } from '@material-ui/styles/withStyles';

const styles = theme => ({});

type Props = {|
  classes: ClassNameMap
|};

type State = {|
  results: Array<InvoiceSearchResult>,
  selectedCompanyId: number,
  selectedInvoiceId: number,
  selectedCreatedDate: any,
  selectedDueDate: any,
  year: any,
  customerPoNumber: string
|}

class AdminInvoices extends React.Component<Props, State> {
  state: State = {
    invoiceResults: [],
    selectedCompanyId: 0,
    selectedInvoiceId: 0,
    selectedCreatedDate: '',
    selectedDueDate: '',
    year: '',
    customerPoNumber: null
  };

  onSelectInvoice = (selectedInvoice: InvoiceSearchResult) => {
    this.setState({
      selectedCompanyId: selectedInvoice.companyId,
      selectedInvoiceId: selectedInvoice.invoiceId,
      selectedCreatedDate: selectedInvoice.createdDate,
      selectedDueDate: selectedInvoice.dueDate,
      year: selectedInvoice.year,
      customerPoNumber: selectedInvoice.customerPoNumber
    });
  };

  onUpdateResults = (results: Array<InvoiceSearchResult>) => {
    this.setState({
      invoiceResults: results
    });
  }

  onPoNumberChange = (poNumber: string) => {
      let newResults = [...this.state.invoiceResults];
      const index = newResults.findIndex(i => { return i.invoiceId === this.state.selectedInvoiceId });

      if (index < 0) {
          return;
      }

      let newResult = { ...newResults[index] };
      newResult.customerPoNumber = poNumber;
      newResults[index] = newResult;
      this.setState({ invoiceResults: newResults });
  }

  render() {
    const {classes} = this.props;
    const {selectedCompanyId, selectedInvoiceId, selectedDueDate, selectedCreatedDate, year, customerPoNumber, invoiceResults} = this.state;

    return (<div>
      <Grid container direction='row' spacing={1}>
        <Grid
            item
            xs={3}>
          <InvoiceSearch
              results={invoiceResults}
              onSelectInvoice={this.onSelectInvoice}
              onUpdateResults={this.onUpdateResults}
          />
        </Grid>
        <Grid item xs={9}>
          {selectedInvoiceId > 0 ? (<ViewInvoice
              isAdmin={true}
              companyId={selectedCompanyId}
              invoiceId={selectedInvoiceId}
              dueDate={selectedDueDate}
              createdDate={selectedCreatedDate}
              year={year}
              customerPoNumber={customerPoNumber}
              onPoNumberChange={this.onPoNumberChange}
          />) : null}
        </Grid>
      </Grid>
    </div>);

  }
}

export default (withStyles(styles)(AdminInvoices));
