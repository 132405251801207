/* @flow */

import {conf} from '../config';
import {HttpService} from './HttpService';
import {LoggerService} from './LoggerService';
import {paramsError, stripNullKeyVals} from './Utility/ServiceUtil';

const logger = LoggerService.getLogger('CompanyService');

export type CompanyProfile = {|
  name: string,
  addressLine1: string,
  addressLine2: string,
  city: string,
  state: string,
  zipCode: string,
  country: string,
|};

export const companyProfileDefault: CompanyProfile = {
  name: '',
  addressLine1: '',
  addressLine2: '',
  city: '',
  state: '',
  zipCode: '',
  country: '',
};

export type CompanySearchResult = {|
  companyId: number,
  companyName: string
|};

export default class CompanyService {

  static getCompanyProfile(companyId: number): Promise<CompanyProfile> {
    let endpoint = companyId > 0 ? conf.api.getCompanyInfoAdmin : conf.api.getCompanyInfo;
    return HttpService.get(endpoint, {
      queryParams: {companyId: companyId}
    })
    .toPromise()
    .then((profile: CompanyProfile) => {
      return {
        ...companyProfileDefault,
        ...stripNullKeyVals(profile)
      };
    });
  }

  static getMemberCompanyPrimary(companyId: number):
      Promise<any> {
    const endpoint = companyId > 0 ? conf.api.getMemberCompanyPrimaryAdmin : conf.api.getMemberCompanyPrimary;
    return HttpService.get(endpoint, {
      queryParams: {companyId: companyId}
    }).toPromise();
  }

  static searchCompanies(searchText: string): Promise<Array<CompanySearchResult>> {
    if (!searchText) {
      logger.error('Missing required params.');
      return paramsError();
    }

    return HttpService.get(conf.api.searchCompanies, {
      pathParams: {
        query: encodeURIComponent(searchText)
      }
    }).toPromise()
    .then((res) => {
      return res || [];
    });
  }

}
