/* @flow */
import Swal from 'sweetalert2';
import palette from '../theme/palette';

export const NotificationTypes = Object.freeze({
    error: 'error',
    success: 'success'
});

export const DefaultNotificationMessage = Object.freeze({
    saved: 'Saved!',
    submitted: 'Successfully Submitted!'
});

const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000
});

const ConfirmToast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: true,
    confirmButtonColor: palette.primary.main,
    timer: 0
})

export default class NotificationService {
    static fireNotification(type: string, message: string): void {
        Toast.fire({
            type: type,
            title: message
        });
    }

    static fireConfirmationNotification(type: string, message: string): void {
        ConfirmToast.fire({
            type: type,
            title: message
        });
    }
}
