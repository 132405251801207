import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  formControl: {
    margin: theme.spacing(3),
  },
  group: {
    margin: theme.spacing(1, 0),
  },
}));

function OTCReportFormChecks(props) {
  const classes = useStyles();

  return (
      <div className={classes.root}>
        <FormControl component='fieldset' className={classes.formControl}>
          <RadioGroup
              aria-label=''
              name=''
              className={classes.group}
              value={props.value === null ? '' : (props.value ? 'yes' : 'no')}
              onChange={props.onChange}
          >
            <FormControlLabel
                value='no'
                control={<Radio color='primary'/>}
                label='No'
                labelPlacement='end'
                disabled={props.disabled}
            />
            <FormControlLabel
                value='yes'
                control={<Radio color='primary'/>}
                label='Yes'
                labelPlacement='end'
                disabled={props.disabled}
            />
          </RadioGroup>
        </FormControl>
      </div>
  );
}

export default OTCReportFormChecks;
