import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import AsyncSelect from 'react-select/async';
import ProductService from '../../../services/ProductService';
import {makeStyles} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  dialogPaper: {
    height: '60vh',
    width: '45vh'
  }
}));

function CategorySearch(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const [selectedOption, setSelectedOptions] = React.useState(
      props.initialValue);

  const promiseOptions = (value) => {
    return ProductService.searchProductCategory(value, props.type).then((res) => {
      return res.map(val => {
        return {value: val.categoryId, label: val.categoryName};
      });
    });
  };

  function handleClickOpen() {
    setOpen(true);
  }

  function handleClose() {
    setOpen(false);
  }

  function resolveClicked() {
    setOpen(false);
    props.onSelect(selectedOption.value, selectedOption.label);
  }

  function setCategory(e) {
    setSelectedOptions(e);
  }

  return (
      <div>
        <Button color='primary' onClick={handleClickOpen} href={''}>
          {props.buttonName ? props.buttonName : 'Select Category'}
        </Button>
        <Dialog classes={{paper: classes.dialogPaper}} open={open} maxWidth='lg'
                onClose={handleClose} aria-labelledby='form-dialog-title'>
          <DialogTitle id='form-dialog-title'>Search Category</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Please enter a category name.
            </DialogContentText>
            <AsyncSelect
                autoFocus
                margin='dense'
                label='Type at least 2 characters to search...'
                placeholder='Type at least 2 characters to search...'
                cacheOptions
                defaultOptions
                loadOptions={promiseOptions}
                onChange={setCategory}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={resolveClicked}
                    disabled={selectedOption === undefined} color='primary' href={''}>
              Ok
            </Button>
          </DialogActions>
        </Dialog>
      </div>
  );
}

export default CategorySearch;
