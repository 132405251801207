/* @flow */

import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import {ClassNameMap} from '@material-ui/styles/withStyles';
import {JSXElement} from '@babel/types';
import type {CompanyProfile} from '../../../services/CompanyService';
import CompanyService, {companyProfileDefault} from '../../../services/CompanyService';
import PrimaryProgressLoader from '../../../components/Utils/PrimaryProgressLoader';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

const styles = () => ({
  title: {
    fontSize: 18,

  },
  pos: {
    marginBottom: 2,
  },
  border: {
    border: '1px solid #DFE3E8;',
    borderRadius: '4px',
    boxShadow: '0 0 0 0 rgba(255, 105, 135, .3)',
    backgroundColor: '#ffffff',
  },
  padding: {
    padding: '10px 0px'
  }
});

type Props = {|
  classes: ClassNameMap,
  companyId: number,
  isAdmin: boolean
|};

type State = {|
  name: string,
  companyPrimary: string,
  addressLine1: string,
  addressLine2: string,
  city: string,
  state: string,
  zipCode: string,
  country: string,
  loading: boolean
|};

class InvoiceCompanyName extends React.Component<Props, State> {
  state = {
    loading: true,
    ...companyProfileDefault,
    companyPrimary: ''
  };

  componentDidMount():
      void {
    const companyId = this.props.isAdmin === true ? this.props.companyId : 0;
    CompanyService.getCompanyProfile(companyId).then((profile: CompanyProfile) => {
      CompanyService.getMemberCompanyPrimary(companyId).then((companyPrimary) => {
        this.setState({...profile, companyPrimary: companyPrimary.name, loading: false});
      });
    });
  }

  componentDidUpdate(prevProps: Props): void {
    if (this.props.companyId !== prevProps.companyId) {
      const companyId = this.props.isAdmin === true ? this.props.companyId : 0;
      CompanyService.getCompanyProfile(companyId).then((profile: CompanyProfile) => {
        CompanyService.getMemberCompanyPrimary(companyId).then((companyPrimary) => {
          this.setState({...profile, companyPrimary: companyPrimary.name, loading: false});
        });
      });
    }
  }

  render(): JSXElement {
    const {classes} = this.props;

    return (
        this.state.loading ?
            <PrimaryProgressLoader/> :

            <ListItem className={classes.border} alignItems="flex-start">
              <ListItemText
                  primary={this.state.name}
                  secondary={
                    <React.Fragment>
                      <Typography
                          component="span"
                          variant="body2"
                          className={classes.inline}
                          color="textPrimary"
                      >
                      </Typography>
                      <div className={classes.info}>
                        <p className={classes.padding}>Contact Name: {this.state.companyPrimary}</p>
                        <p className={classes.padding}>Address: {this.state.addressLine1}</p>
                        <p className={classes.padding}>City, State, Zip: {this.state.city + ','} {this.state.state} {this.state.zipCode}</p>
                      </div>
                    </React.Fragment>
                  }
              />
            </ListItem>
    );
  }
}

export default withStyles(styles)(InvoiceCompanyName);
