/* @flow */

import {conf} from '../config';
import {HttpService} from './HttpService';

export const FormTypes = Object.freeze({
  RxReviewForm: 'RxReviewForm',
  OtcReviewForm: 'OtcReviewForm',
  SelfReportedOtcForm: 'SelfReportedOtcForm',
  SelfReportedSharpsForm: 'SelfReportedSharpsForm'
});

export type FormType = $Keys<typeof FormTypes>

export default class NavTabsService {
  // Gets the applicable forms for the current user's company.
  static getApplicableForms(): Promise<Array<FormType>> {
    return HttpService.get(conf.api.getApplicableForms)
    .toPromise()
    .then((res) => {
      return res || [];
    });
  }
}
