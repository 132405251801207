/* @flow */

import * as React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import AdminRecordHistory from '../../../views/AdminRecordHistory';
import AdminSettingsTab from '../../../views/AdminSettingsTab';
import AdminViewSelfReport from '../../../views/AdminViewSelfReport';
import AdminInvoices from '../../../views/AdminInvoices';
import {conf} from '../../../config';
import {Redirect, Switch, withRouter} from 'react-router';
import NotFound from '../../NotFound';
import {withStyles} from '@material-ui/core';
import AdminRoute from '../../../components/Routes/AdminRoute';
import AdminUpload from '../../../views/AdminUpload';
import {getDerivedStateFromProps, routerTab} from '../../../components/Routes/RouteUtil';
import TabContainer from '../../../components/Navigation/TabContainer';
import {ClassNameMap} from '@material-ui/styles/withStyles';

const styles = () => ({
  root: {
    flexGrow: 1,
    backgroundColor: '#f99900',
    padding: '0px'
  },
});

type Props = {|
  classes: ClassNameMap,
  location: any
|};
type State = {|
  selectedTab: string
|};

class NavTable extends React.Component<Props, State> {
  static getDerivedStateFromProps = getDerivedStateFromProps;

  state = {
    selectedTab: ''
  };

  componentDidMount(): void {
    if (this.props.location.pathname === conf.hash.admin.home) {
      this.setState({
        selectedTab: conf.hash.admin.settings
      });
    }
  }

  changeTab = (e, tabRoute) => {
    this.setState({
      selectedTab: tabRoute
    });
  };

  render() {
    const {classes} = this.props;

    let selectedTab = this.state.selectedTab;
    if (!selectedTab) {
      return null;
    }

    selectedTab = selectedTab === conf.hash.admin.home ? conf.hash.admin.settings : selectedTab;

    return (
        <React.Fragment>
          <AppBar className={classes.root} position='static'>
            <Tabs onChange={this.changeTab} value={selectedTab} variant='fullWidth' component={'div'}>
              {routerTab('Settings', conf.hash.admin.settings)}
              {routerTab('Upload Data', conf.hash.admin.upload)}
              {routerTab('View Member Submissions', conf.hash.admin.viewSubmissions)}
              {routerTab('Record History', conf.hash.admin.recordHistory)}
              {routerTab('Invoice(s)', conf.hash.admin.invoices)}

            </Tabs>
          </AppBar>
          <TabContainer>
            <Switch>
              <Redirect exact from={conf.hash.admin.home} to={conf.hash.admin.settings}/>
              <AdminRoute exact path={conf.hash.admin.settings} component={AdminSettingsTab}/>
              <AdminRoute exact path={conf.hash.admin.upload} component={AdminUpload}/>
              <AdminRoute exact path={conf.hash.admin.viewSubmissions} component={AdminViewSelfReport}/>
              <AdminRoute exact path={conf.hash.admin.recordHistory} component={AdminRecordHistory}/>
              <AdminRoute exact path={conf.hash.admin.invoices} component={AdminInvoices}/>
              <AdminRoute component={NotFound}/>
            </Switch>
          </TabContainer>
        </React.Fragment>
    );
  }
}

export default withRouter<any>(withStyles(styles)(NavTable));
