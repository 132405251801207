/* @flow */

import Cookies from 'universal-cookie';
import {LoggerService} from './LoggerService';
import {conf} from '../config';
import {HttpService} from './HttpService';

export const USER_COOKIE_NAME = 'ppswg_user';
const ADMIN_ROLE = 'STAFF';
const logger = LoggerService.getLogger('AuthService');

type UserRole = 'MI' | 'STAFF';

export type User = {|
  companyId: number,
  effectiveDate: string,
  expirationDate: string,
  isAnonymous: boolean,
  isDisable: boolean,
  name: string,
  isStaff: boolean,
  userId: string,
  userName: string
|} | null;

export default class AuthService {
  static #user: User = null;
  static #cookies = new Cookies();

  static getUser(): User | null {
    if (!this.#user && this.#cookies.get(USER_COOKIE_NAME)) {
      this.#user = this.#cookies.get(USER_COOKIE_NAME);
    }

    return this.#user;
  }

  static ssoLogin(): void {
    window.location.assign(conf.authRedirectUrl);
  }

  static fakeLogin(isAdmin: boolean): Promise<void> {
    logger.info('Faking login request...');

    this.logout();
    return HttpService.get(conf.api.fakeAuthCallback, {
      queryParams: {
        isAdmin: isAdmin === true
      }
    }).toPromise();
  }

  static logout(): void {
    this.#cookies.remove(USER_COOKIE_NAME);
    this.#user = null;
  }

  static logoutNimble(): void {
    this.logout();
    window.location.assign(conf.authLogoutUrl);
  }

  static isLoggedIn(): boolean {
    return !!this.getUser();
  }

  static isAdmin(): boolean {
    const user = this.getUser();
    if (user != null) {
      return user.isStaff;
    }

    return false;
  }

}
