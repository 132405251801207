/* @flow */

import {conf} from '../config';
import {HttpService} from './HttpService';

export const ToDoNames = Object.freeze({
  RxReviewForm: 'RxReviewForm',
  OtcReviewForm: 'OtcReviewForm',
  SelfReportedOtcForm: 'SelfReportedOtcForm',
  SelfReportedSharpsForm: 'SelfReportedSharpsForm'
});

export type ToDoName = $Values<typeof ToDoNames>

export type ToDoItem = {|
  name: ToDoName,
  dueDate: string,
|};

export default class ToDoService {

  static getToDoList(): Promise<Array<ToDoItem>> {
    return HttpService.get(conf.api.getTodoList).toPromise()
    .then((res) => {
      return res || [];
    });
  }

}
