/* @flow */

import { conf } from '../config';
import { HttpService } from './HttpService';
import type { MaterialPage, MaterialPageQuery, VendorProduct } from './Utility/ServiceUtil';
import { paramsError, resToTablePage } from './Utility/ServiceUtil';
import type { Vendor } from './ProductService';
import { Vendors } from './ProductService';
import { LoggerService } from './LoggerService';

const logger = LoggerService.getLogger('MemberEditService');

export type OtcForm = {|
    id?: number,
        companyId: number,
            hasPrivateLabel ?: boolean,
            revenue ?: number,
            totalCoveredUnits ?: number,
            isSubmitted: boolean,
                dateSubmittedUtc: string
                    |};

export type SubmissionStatus = {|
    dateSubmitted?: Date
        |};

export class MemberEditService {

    static getMemberByCompanyId(vendor: Vendor, query: MaterialPageQuery): Promise<MaterialPage<VendorProduct>> {
        if (!(vendor in Vendors) || !query) {
            logger.error('Missing required params.');
            return paramsError();
        }

        return HttpService.get(conf.api.getMemberData, {
            pathParams: {
                'vendor': vendor,
                'page': query.page,
                'rows': query.pageSize
            }
        })
            .toPromise()
            .then(resToTablePage(query.page));
    }

    static updateVendorData(vendor: Vendor, product: VendorProduct): Promise<void> {
        if (!(vendor in Vendors) || !product) {
            logger.error('Missing required params.');
            return paramsError();
        }

        return HttpService.put(conf.api.memberUpdateVendorData, {
            data: product,
            pathParams: {
                'vendor': vendor
            }
        }).toPromise();
    }

    static getTotalUnits(vendor: Vendor): Promise<number> {
        if (!(vendor in Vendors)) {
            logger.error('Missing required params.');
            return paramsError();
        }

        return HttpService.get(conf.api.getTotalUnits, {
            pathParams: {
                'vendor': vendor
            }
        }).toPromise();
    }

    static getTotalSales(vendor: Vendor): Promise<number> {
        if (!(vendor in Vendors)) {
            logger.error('Missing required params.');
            return paramsError();
        }

        return HttpService.get(conf.api.getTotalSales, {
            pathParams: {
                'vendor': vendor
            }
        }).toPromise();
    }

    static getForm(): Promise<OtcForm> {
        return HttpService.get(conf.api.getForm).toPromise();
    }

    static postSelfReportedOtc(selfReportOtcForm: OtcForm): Promise<void> {
        return HttpService.post(conf.api.selfReportedOtc, {
            data: selfReportOtcForm
        }).toPromise();
    }

    static getReviewFormSubmitted(vendor: Vendor): Promise<Date> {
        if (!(vendor in Vendors)) {
            logger.error('Missing required params.');
            return paramsError();
        }

        return HttpService.get(conf.api.reviewFormSubmitted, {
            pathParams: {
                vendor: vendor
            }
        }).toPromise()
            .then((result: SubmissionStatus) => result !== null ? result.dateSubmitted : null);
    }

    static putReviewForm(vendor: Vendor): Promise<void> {
        if (!(vendor in Vendors)) {
            logger.error('Missing required params.');
            return paramsError();
        }

        return HttpService.put(conf.api.submitReviewForm, {
            data: {},
            pathParams: {
                vendor: vendor
            }
        }).toPromise();
    }

    static exportToExcel(vendor: Vendor): Promise<void> {
        if (!vendor) {
            logger.error('Missing required params.');
            return paramsError();
        }
        return HttpService.getFileDownload(conf.api.downloadMember, {
            pathParams: {
                'vendor': vendor
            }
        }).toPromise();
    }

    static uploadMemberFile(file: File, vendor: Vendor): Promise<void> {
        if (!file || !(vendor in Vendors)) {
            logger.error('Missing required params.');
            return paramsError();
        }

        const formData = new FormData();
        formData.append('body', file);

        return HttpService.put(conf.api.uploadMemberFile, {
            data: formData,
            pathParams: {
                vendor: vendor
            },
            httpTimeout: conf.httpUploadTimeout,
            httpRetryLimit: 0
        }).toPromise();
    }

}
