import React, {useEffect} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import SettingsTabs from '../layouts/Admin/components/SettingsTabs';
import CoveredProductsManagement from '../layouts/Admin/components/CoveredProductsManagement';
import Card from '@material-ui/core/Card';
import DueDateDatePicker from '../layouts/Admin/components/DueDateDatePicker';
import DateUtils from '../services/Utility/DateUtils.js';
import StringUtils from '../services/Utility/StringUtils.js';
import type {DueType} from '../services/DueDateService';
import DueDateService, {DueTypes} from '../services/DueDateService';
import SecondaryProgressLoader from '../components/Utils/SecondaryProgressLoader';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import {HttpError} from '../services/HttpService';
import NotificationService, {DefaultNotificationMessage, NotificationTypes} from '../services/NotificationService';
import Dividers from '../components/DataDisplay/Divider';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Button from '@material-ui/core/Button';
import MarketShareInvoiceActions from '../layouts/Admin/components/MarketShareInvoiceActions';

const useStyles = makeStyles({
  card: {
    minWidth: 275,
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  dateCard: {
    padding: '11px',
    margin: '0px 0px 20px 0px'
  },

  padNone: {
    padding: '0px',
  },
  paper: {
    margin: '15px 0 0 0',
  },
  toggleText: {
    fontSize: '14px',
    margin: '0 3px 0 0',
  },
  dateBackground: {
    backgroundColor: '#f5f5f5',
    margin: '0 0 10px 0'
  }
});

function AdminSettingsTab() {
  const classes = useStyles();
  const [rx, setRx] = React.useState(null);
  const [otc, setOtc] = React.useState(null);
  const [otcPrivate, setOtcPrivate] = React.useState(null);
  const [sharps, setSharps] = React.useState(null);
  const [loaded, setLoaded] = React.useState(false);
  const [agreeInvoices, setAgreeInvoices] = React.useState(false);

  function handleDate(dueType: DueType, value) {
    if (value !== undefined && dueType !== undefined) {
      DueDateService.upsertDueDate(dueType, value).then(() => {
        NotificationService.fireNotification(NotificationTypes.success, DefaultNotificationMessage.saved);
      }).catch((error: HttpError) => {
        NotificationService.fireNotification(NotificationTypes.error, `Error ${error.message}`);
      });
    }
  }

  useEffect(() => {
    DueDateService.getAllDueDates().then(r => {
      r.forEach(function (item) {
        switch (item.type) {
          case DueTypes.rxData:
            let rxDate = DateUtils.utcToLocalTime(StringUtils.toDate(item.date));
            rxDate.setHours(0, 0, 0, 0);
            setRx(rxDate);
            break;
          case DueTypes.otcData:
            let otcDate = DateUtils.utcToLocalTime(StringUtils.toDate(item.date));
            otcDate.setHours(0, 0, 0, 0);
            setOtc(otcDate);
            break;
          case DueTypes.otcPrivateLabel:
            let privateDate = DateUtils.utcToLocalTime(StringUtils.toDate(item.date));
            privateDate.setHours(0, 0, 0, 0);
            setOtcPrivate(privateDate);
            break;
          case DueTypes.sharpsSelfReport:
            let sharpsDate = DateUtils.utcToLocalTime(StringUtils.toDate(item.date));
            sharpsDate.setHours(0, 0, 0, 0);
            setSharps(sharpsDate);
            break;
          default:
            break;
        }
      });
      setLoaded(true);
    });
  }, []);
  if (loaded) {
    return (
        <React.Fragment>
          <Grid container spacing={2} direction='row' justify='center' display='flex'>
            <Grid item xs={3}>
              <Card className={classes.dateCard}>
                <Box
                    display='flex'
                    flexWrap='nowrap'
                    justifyContent='space-between'
                    p={1}
                    m={1}
                    bgcolor='background.paper'
                    css={{maxWidth: 300}}>
                  <Typography variant='h4' color='secondary'>
                    Due Date Management
                  </Typography>
                </Box>

                <Dividers/>
                <Card
                    className={classes.dateBackground}>
                  <DueDateDatePicker dueType={DueTypes.rxData} handle={handleDate}
                                     date={rx ? DateUtils.formatDate('YYYY-MM-DD', rx) : rx}/>
                </Card>
                <Card className={classes.dateBackground}>
                  <DueDateDatePicker dueType={DueTypes.otcData} handle={handleDate}
                                     date={otc ? DateUtils.formatDate('YYYY-MM-DD', otc) : otc}/>
                </Card>
                <Card className={classes.dateBackground}>
                  <DueDateDatePicker dueType={DueTypes.otcPrivateLabel} handle={handleDate}
                                     date={otcPrivate ? DateUtils.formatDate('YYYY-MM-DD', otcPrivate) : otcPrivate}/>
                </Card>
                <Card className={classes.dateBackground}>
                  <DueDateDatePicker dueType={DueTypes.sharpsSelfReport} handle={handleDate}
                                     date={sharps ? DateUtils.formatDate('YYYY-MM-DD', sharps) : sharps}/>
                </Card>
              </Card>
              <MarketShareInvoiceActions/>
            </Grid>
            <Grid item xs={9}>
              <SettingsTabs/>
            </Grid>
            <Grid item xs={12}>
              <Paper>
                <CoveredProductsManagement/>
              </Paper>
            </Grid>
          </Grid>
        </React.Fragment>
    );
  } else {
    return <SecondaryProgressLoader/>;
  }
}

export default AdminSettingsTab;
