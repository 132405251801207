import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Dividers from '../../../components/DataDisplay/Divider';
import MemberSubmissionService from '../../../services/MemberSubmissionService';
import OTCReportFormChecks from '../../Member/components/OTCReportFormChecks';
import SecondaryProgressLoader from '../../../components/Utils/SecondaryProgressLoader';
import { LoggerService } from '../../../services/LoggerService';
import TextBox from '../../../components/Input/TextBox';
import Button from '@material-ui/core/Button';
import CardActions from '@material-ui/core/CardActions';
import StringUtils from '../../../services/Utility/StringUtils.js';
import { HttpError } from '../../../services/HttpService';
import DialogContentText from '@material-ui/core/DialogContentText';
import NotificationService, { DefaultNotificationMessage, NotificationTypes } from '../../../services/NotificationService';

const logger = LoggerService.getLogger('MemberSubmissionOTCPrivateLabel');

const maxDecimal = 1000000000000000;

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },

}));

function MemberSubmissionOTCSelfReporting(props) {
    const classes = useStyles();
    const [form, setForm] = useState({
        hasPrivateLabel: null,
        isSubmitted: false,
        dateSubmittedUtc: false,
        companyId: 0,
        selfReportedOtcData: null
    });
    const [isSaving, setIsSaving] = useState(false);

    useEffect(() => {
        loadData();
    }, [props.companyId, props.year]);

    function loadData() {
        MemberSubmissionService.getSelfReportedOtc(props.companyId, props.year)
            .then((res) => {
                setForm(res);
            }).catch((err) => {
                logger.error(`Unable to get memberSubmissions for ${props.companyId}`, err);
            });
    }

    function handleFormCheckChange(event, value) {
        setForm({ ...form, hasPrivateLabel: value === 'yes' });
    };

    function handleRevenueChange(value) {
        let revenue;
        if (value === '') {
            revenue = null;
        } else {
            revenue = StringUtils.toNumber(value);
        }

        if (!isNaN(revenue) && revenue >= 0 && revenue <= maxDecimal) {
            setForm({ ...form, revenue: revenue });
        }
    }

    function handleTotalChange(value) {
        let totalCoveredUnits;
        if (value === '') {
            totalCoveredUnits = null;
        } else {
            totalCoveredUnits = StringUtils.toNumber(value);
        }

        if (!isNaN(totalCoveredUnits) && totalCoveredUnits >= 0 && totalCoveredUnits <= maxDecimal) {
            setForm({ ...form, totalCoveredUnits: totalCoveredUnits });
        }
    }

    function handleSubmit() {
        postForm(true);
    }

    function handleSave() {
        postForm(false);
    }

    function postForm(isSubmitting) {
        const message = isSubmitting ? DefaultNotificationMessage.submitted : DefaultNotificationMessage.saved;
        setIsSaving(true);
        MemberSubmissionService.postSelfReportedOtc({ ...form, isSubmitted: isSubmitting })
            .then(() => {
                setIsSaving(false);
                //Triggers reload of form after successful post.
                loadData();
                props.refresh();
                NotificationService.fireNotification(NotificationTypes.success, message);
            })
            .catch((error: HttpError) => {
                setIsSaving(false);
                NotificationService.fireNotification(NotificationTypes.error, `Error ${error.message}`);
            });
    };

    function renderForm() {
        const disabled = !props.editable || isSaving;
        let emptyWhenShouldHaveData = false;
        if (form && form.hasPrivateLabel && (form.revenue === null || form.revenue <= 0
            || form.totalCoveredUnits === null || form.totalCoveredUnits <= 0)) {
            emptyWhenShouldHaveData = true;
        }
        
        if (form.companyId > 0) {
            return (
                <div className={classes.root}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Card className={classes.card}>

                                <Grid
                                    container
                                    direction='row'
                                    justify='space-between'
                                    alignItems='center'
                                >
                                    <CardHeader

                                        title='OTC Reporting Form'
                                        subheader='Store-Brand/Private Label Sales'
                                    />
                                </Grid>
                                <Dividers />
                                <CardContent>
                                    <p>1. Does your company manufacture covered products under a store-brand or private label?</p>
                                    <OTCReportFormChecks value={form.hasPrivateLabel} disabled={disabled} onChange={handleFormCheckChange} />
                                    {
                                        form.hasPrivateLabel
                                            ? (
                                                <React.Fragment>
                                                    <div>
                                                        <p> 2. Provide the consolidated revenue associated with covered OTC Private Label
                                                            units (UPC) shipped nationally between 1/1/{props.year - 1} and 12/31/{props.year - 1}.
                                                            Revenue is defined as the indirect (wholesalers) and direct (manufacturers) sales of
                                                            product to an outlet (retail - pharmacy, mail, etc.). The figure reported should
                                                            represent the combined dollar sales volume paid by all outlets for all covered products,
                                                            inclusive of on-invoice discounts and excluding rebates or prompt-payment discounts. <b>Please
                                                            enter a whole dollar amount. Do not include decimals.</b>
                                                        </p>

                                                        <TextBox
                                                            id='revenue_input'
                                                            label='Total Revenue'
                                                            value={form.revenue ?? ''}
                                                            onChange={handleRevenueChange}
                                                            disabled={disabled}
                                                        />
                                                    </div>
                                                    <div>
                                                        <p> 3. Provide the number of covered units shipped nationally (UPC) for sale under a
                                                            store-brand or private label between 1/1/{
                                                                props.year - 1} and 12/31/{props.year - 1} by your company and affiliates.</p>

                                                        <TextBox
                                                            id='total_units_input'
                                                            label='Total Covered Units'
                                                            value={form.totalCoveredUnits ?? ''}
                                                            onChange={handleTotalChange}
                                                            disabled={disabled}
                                                        />
                                                    </div>
                                                </React.Fragment>
                                            )
                                            : form.hasPrivateLabel === false
                                                ? (
                                                    <p>Please click submit and then move to the next section.</p>
                                                )
                                                : null
                                    }
                                </CardContent>
                                {props.editable &&
                                    <CardActions>
                                        <Button
                                            color='primary'
                                            disabled={disabled || emptyWhenShouldHaveData || form.hasPrivateLabel === null}
                                            onClick={handleSubmit}
                                            href={''}>Submit</Button>
                                        <Button
                                            color='primary'
                                            disabled={disabled}
                                            href={''}
                                            onClick={handleSave}>Save</Button>
                                    </CardActions>
                                }
                                {(props.editable && emptyWhenShouldHaveData) ? <DialogContentText color='error' className={classes.margFive}>
                                    Please input total revenue and covered units
                                </DialogContentText> : null}
                            </Card>
                        </Grid>
                    </Grid>
                </div>
            );
        } else {
            return <SecondaryProgressLoader />;
        }
    }

    return renderForm();
}

export default MemberSubmissionOTCSelfReporting;
