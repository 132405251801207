import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import DateUtils from '../../../services/Utility/DateUtils.js';
import AuthService from '../../../services/AuthService';
import {DueTypes} from '../../../services/DueDateService';

const useStyles = makeStyles(theme => ({
  root: {
    maxWidth: 500,
  },
  snackbar: {
    margin: theme.spacing(1),
    backgroundColor: '#d32f2f'
  },
}));

function FilteredDataDateNotification(props) {
  const classes = useStyles();
  const user = AuthService.getUser();
  const isSubmitted = props.isSubmitted;
  const isPastDueDate = props.dueDate && props.dueDate < new Date();

  function formatSubmitDate(date) {
    if (date) {
      return DateUtils.utcToLocalTime(date);
    } else {
      return '';
    }
  }

  const completedDate = props.dateSubmitted ? DateUtils.formatDate('MM-DD-YYYY', formatSubmitDate(props.dateSubmitted))
      : '';

  return (
      <div className={classes.root}>
        {isSubmitted ?
            (<SnackbarContent className={classes.snackbar}
                              message={`Data submitted on ${completedDate}.`}/>) :
            isPastDueDate ?
                (<SnackbarContent className={classes.snackbar} message='Cannot edit since the due date has passed.'/>) :
                (<SnackbarContent className={classes.snackbar}
                                  message={`Data not submitted.` + (props.dueDate ? ` Due by ${DateUtils.formatDate(
                                      'MM-DD-YYYY HH:mm', props.dueDate)}` : '')}/>)
        }
      </div>
  );
}

export default FilteredDataDateNotification;
