import React from 'react';
import MaterialTable from 'material-table';
import RxOtcSelect from '../../../components/CustomComps/RxOtcSelect';
import type {CoveredProduct} from '../../../services/ProductService';
import ProductService, {ProductTypes} from '../../../services/ProductService';
import CategorySearch from './CategorySearch';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import type {MaterialPageQuery} from '../../../services/Utility/ServiceUtil';

function CoveredProductsManagement() {
  const [type, setType] = React.useState(ProductTypes.Otc);
  const tableRef = React.createRef();

  // Columns to use for Otc products.
  const otcColumns = [
    {title: 'Super Category', field: 'bcSuperCategory', editable: 'never'},
    {title: 'Category', field: 'bcCategory', editable: 'never'},
    {title: 'Sub Category', field: 'bcSubCategory', editable: 'never'},
    {
      title: 'Segment', field: 'bcSegment', editComponent: categoryEdit
    },
    {title: 'Brand Low', field: 'name'},
    //{ title: 'Brand High', field: 'brandHigh' },
    //{ title: 'Brand Owner High', field: 'brandOwnerHigh' },
    {
      title: 'Covered',
      field: 'isCovered',
      lookup: {true: 'Covered', false: 'Not Covered'}
    }
  ];

  // Columns to use for Rx products.
  const rxColumns = [
    {title: 'ProdForm1', field: 'prodForm1', editable: 'never'},
    {title: 'ProdForm2', field: 'prodForm2', editable: 'never'},
    {title: 'ProdForm3', field: 'prodForm3', editComponent: categoryEdit},
    {title: 'NDC', field: 'ndc'},
    {title: 'Name', field: 'name'},
    {
      title: 'Injectable',
      field: 'isInjectable',
      lookup: {true: 'Injectable', false: 'Not Injectable'}
    },
    {
      title: 'Covered',
      field: 'isCovered',
      lookup: {true: 'Covered', false: 'Not Covered'}
    }
  ];

  function getColumns(type) {
    if (type === ProductTypes.Rx) {
      return rxColumns;
    }
    return otcColumns;
  }

  // Custom function for defining the component to use for editing the product's category.
  function categoryEdit(props) {

    let initialValue;
    if (type === ProductTypes.Otc) {
      initialValue = {
        value: props.rowData.bcSegmentId,
        label: props.rowData.bcSegment
      };
    } else {
      initialValue = {
        value: props.rowData.prodForm3Id,
        label: props.rowData.prodForm3
      };
    }

    return <CategorySearch
        buttonName={initialValue.label}
        type={type}
        initialValue={initialValue}
        onSelect={
          (id, label) => {
            if (type === ProductTypes.Otc) {
              props.rowData.bcSegmentId = id;
              props.rowData.bcSegment = label;
              props.rowData.bcSubCategory = '';
              props.rowData.bcCategory = '';
              props.rowData.bcSuperCategory = '';
            } else {
              props.rowData.prodForm3Id = id;
              props.rowData.prodForm3 = label;
              props.rowData.prodForm2 = '';
              props.rowData.prodForm1 = '';
            }

            props.onRowDataChange(props.rowData);
          }
        }/>;
  }

  return (
      <React.Fragment>
        <div style={{width: '100%'}}>
          <Box
              alignItems='center'
              display='flex'
              flexWrap='wrap'
              bgcolor='white'
          >
            <Box>
              <Typography variant='h4' color='secondary' noWrap>
                Covered Products
              </Typography>
            </Box>
            <Box>
              <RxOtcSelect type={type} handleChange={(e) => {
                setType(e.target.value);
                tableRef.current.onChangePage(null, 0);
              }}/>
            </Box>
          </Box>
        </div>
        <MaterialTable
            tableRef={tableRef}
            title={''}
            columns={getColumns(type)}
            data={(query: MaterialPageQuery) => {
              return ProductService.getCoveredProducts(query, type);
            }}
            options={{
              search: false,
              filtering: false,
              sorting: false,
              pageSize: 10,
              actionsColumnIndex: -1
            }}
            editable={{
              onRowAdd: (product: CoveredProduct) => {
                return ProductService.upsertProduct(product, type);
              },
              onRowUpdate: (updatedProduct: CoveredProduct) => {
                return ProductService.upsertProduct(updatedProduct, type);
              }
            }}
        />
      </React.Fragment>
  );
}

export default CoveredProductsManagement;
