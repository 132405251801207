/* @flow */

import {conf} from '../config';
import {HttpService} from './HttpService';
import {LoggerService} from './LoggerService';
import type {ProductType} from './ProductService';
import {ProductTypes} from './ProductService';
import {paramsError} from './Utility/ServiceUtil';

const logger = LoggerService.getLogger('BatchDataService');

export const BatchSteps = Object.freeze({
  upload: 'Upload',
  viewRawData: 'ViewRawData',
  editProcessedData: 'EditProcessedData',
  exceptionManagement: 'ExceptionManagement',
  approveAndRelease: 'ApproveAndRelease',
  complete: 'Complete'
});

export const BatchStatuses = Object.freeze({
  initialized: 'Initialized',
  rawProcessing: 'RawProcessing',
  filteredProcessing: 'FilteredProcessing',
  complete: 'Complete',
  error: 'Error'
});

export type BatchStep = $Values<typeof BatchSteps>;

export type BatchStatus = {|
  batchDataId: ?number,
  step: BatchStep,
  previousBatchThisYear: boolean,
  uploadStatus: $Values<typeof BatchStatuses> | null
|} | null;

export type UpdateBatchStep = {|
  batchDataId: number,
  forward: boolean
|};

export default class BatchDataService {
  static getMostRecentInProgBatch(type: ProductType): Promise<BatchStatus> {
    if (!(type in ProductTypes)) {
      logger.error('Missing required params.');
      return paramsError();
    }

    return HttpService.get(conf.api.getMostRecentInProgBatch, {
      pathParams: {type: type}
    }).toPromise();
  }

  static updateBatchDataStep(userNavigatedForward: boolean, batchId: number): Promise<void> {
    if (typeof userNavigatedForward !== 'boolean' || batchId === null || batchId === undefined) {
      logger.error('Missing required params.');
      return paramsError();
    }

    const data: UpdateBatchStep = {
      batchDataId: batchId,
      forward: userNavigatedForward
    };
    return HttpService.put(conf.api.updateBatchDataStep, {data: data}).toPromise();
  }

  static deleteBatchData(batchId: number): Promise<void> {
    if (batchId === null || batchId === undefined) {
      logger.error('Missing required params.');
      return paramsError();
    }

    return HttpService.put(conf.api.deleteBatchData, {
      pathParams: {batchId: batchId},
      data: {}
    }).toPromise();
  }

  static createBatchData(type: ProductType): Promise<number> {
    if (!(type in ProductTypes)) {
      logger.error('Missing required params.');
      return paramsError();
    }

    return HttpService.post(conf.api.createBatchData, {
      data: {},
      pathParams: {type: type}
    }).toPromise();
  }
}
