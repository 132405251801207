import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import AsyncSelect from 'react-select/async';
import {makeStyles} from '@material-ui/core';
import palette from '../../../theme/palette';
import CompanyService from '../../../services/CompanyService';

const useStyles = makeStyles(theme => ({
  dialogPaper: {
    height: '60vh',
    width: '45vh'
  },
  textColor: {
    color: '#ffffff',
  }
}));

function InputModal(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const [selectedOption, setSelectedOptions] = React.useState(null);

  const handleIgnore = props.onIgnore.bind(this);
  const handleResolve = props.onResolve.bind(this);

  const promiseOptions = inputValue =>
      new Promise(resolve => {
        setTimeout(() => {
          handleInputChange(inputValue).then(res => {
            resolve(res);
          });
        }, 1000);
      });

  const handleInputChange = (value: string) => {
    if (!value || !value.length || value.length < 3) {
      return Promise.resolve([]);
    }

    return CompanyService.searchCompanies(value)
    .then((res) => {
      return res.map(val => {
        return {value: val.companyId, label: val.companyName};
      });
    });
  };

  function handleClickOpen() {
    setOpen(true);
  }

  function handleClose() {
    setSelectedOptions(null);
    setOpen(false);
  }

  function ignoreClicked() {
    setOpen(false);
    handleIgnore(props.exception, props.idx);
  }

  function resolveClicked() {
    const value = selectedOption.value;
    setSelectedOptions(null);
    setOpen(false);
    handleResolve(props.exception, value, props.idx);
  }

  function setCompany(e) {
    setSelectedOptions(e);
  }

  return (
      <React.Fragment>
        <Button className={classes.textColor} onClick={handleClickOpen} href={''}>
          Resolve
          <i className="material-icons exception-arrow">
            arrow_forward
          </i>
        </Button>
        <Dialog classes={{paper: classes.dialogPaper}} open={open} maxWidth='lg'
                onClose={handleClose} aria-labelledby='form-dialog-title'>
          <DialogTitle id='form-dialog-title'>Company Not Found</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Please enter in correct company name.
            </DialogContentText>
            <AsyncSelect
                autoFocus
                margin='dense'
                placeholder='Type at least 3 characters to search...'
                cacheOptions
                defaultOptions
                loadOptions={promiseOptions}
                onChange={setCompany}

            />
          </DialogContent>
          <DialogActions>
            <Button onClick={ignoreClicked} color='primary' href={''}>
              Ignore Exception
            </Button>
            <Button onClick={resolveClicked}
                    disabled={selectedOption === null} color='primary' href={''}>
              Resolve
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
  );
}

export default InputModal;
