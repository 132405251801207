import React from 'react';
import InvoiceCompanyName from '../layouts/Member/components/InvoiceCompanyName';
import InoviceDataPoints from '../layouts/Member/components/InoviceDataPoints';
import InvoiceBankAccount from '../layouts/Member/components/InvoiceBankAccount';
import InvoiceDialogTable from '../layouts/Member/components/InvoiceDialogTable';
import InvoiceDisclaimer from '../layouts/Member/components/InvoiceDisclaimer';
import Grid from '@material-ui/core/Grid';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';

const useStyles = makeStyles(theme => ({
  root: {
    border: '14px solid #f8fafc',
    backgroundColor: '#f8fafc',

  },
  paper: {
    textAlign: 'center',

  },
  invoice: {
    margin: '0px 20px 0px 20px',
  },
  dialogBackground: {
    backgroundColor: '#fcfcfc',
  },
}));
export default function JustifyContent(props) {

  const classes = useStyles();

    return (
      <div className={classes.invoice}>
        <Box display="flex" p={1} className={classes.dialogBackground}>
          <Grid container spacing={1}>
                <Grid item sm={4} p={1}>
                  <InvoiceCompanyName companyId={props.companyId} isAdmin={props.isAdmin}/>
                 </Grid>
                <Grid sm={2} p={1}>
                      <InoviceDataPoints invoiceId={props.invoiceId} dueDate={props.dueDate} createdDate={props.createdDate} customerPoNumber={props.customerPoNumber} companyId={props.companyId} onPoNumberChange={props.onPoNumberChange}/>
                 </Grid>
              <Grid item sm={6}>
                <InvoiceBankAccount/>
              </Grid>
                <Grid item sm={12}>
                  <InvoiceDialogTable invoiceId={props.invoiceId} companyId={props.companyId} year={props.year} isAdmin={props.isAdmin} createdDate={props.createdDate}/>
               </Grid>
              <Grid item sm={12}>
                 <InvoiceDisclaimer />
                </Grid>
             </Grid>
        </Box>
      </div>
  );



}






