/* @flow */

import {conf} from '../config';
import {HttpService} from './HttpService';
import {LoggerService} from './LoggerService';
import StringUtils from './Utility/StringUtils';
import {paramsError} from './Utility/ServiceUtil';
import DateUtils from './Utility/DateUtils';

const logger = LoggerService.getLogger('DueDateService');

export const DueTypes = Object.freeze({
  otcPrivateLabel: 'Otc Private Label',
  sharpsSelfReport: 'Sharps Self Reporting',
  otcData: 'Otc Data',
  rxData: 'Rx Data'
});

export type DueType = $Values<typeof DueTypes>

export type DueDate = {|
  createdBy: string,
  createdByCompany: string,
  createdDateUtc: string,
  dueDate: string,
  id: number,
  isDeleted: false,
  lastModifiedBy: string,
  lastModifiedByCompany: string,
  lastModifiedDateUtc: string,
  type: DueType
|};

export default class DueDateService {

  static getDueDateByType(dueType: DueType): Promise<Date | null> {
    if (!Object.values(DueTypes).includes(dueType)) {
      logger.error('Missing required params.');
      return paramsError();
    }

    return HttpService.get(conf.api.getDueDateByType, {
      queryParams: {
        type: dueType
      }
    })
    .toPromise()
    .then((dateStr: string) => {
      if (dateStr) {
        const date = DateUtils.utcToLocalTime(StringUtils.toDate(dateStr));
        if (date.setHours) {
          return date;
        }
      }

      return null;
    });
  }

  static upsertDueDate(dueType: DueType, dateStr: string): Promise<void> {
    if (StringUtils.isBlank(dueType) || !Object.values(DueTypes).includes(dueType)) {
      logger.error('Missing required params.');
      return paramsError();
    }

    let localDate = DateUtils.createFromLocalDateString(dateStr);
    localDate.setHours(23, 59, 59);

    return HttpService.post(conf.api.upsertDueDate, {
      data: {
        dueDate: localDate.toISOString(),
        type: dueType
      }
    }).toPromise();
  };

  static getAllDueDates(): Promise<Array<DueDate>> {
    return HttpService.get(conf.api.getAllDueDates)
    .toPromise()
    .then((res) => {
      return res || [];
    });
  };

}
