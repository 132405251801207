/* @flow */

import StringUtils from './Utility/StringUtils';
import {conf} from '../config';
import {HttpService} from './HttpService';

export type MemberType = {|
  id: number,
  name: string
|};

export default class MemberTypeService {

  static getAllMemberTypes(): Promise<Array<MemberType>> {
    return HttpService.get(conf.api.getAllMemberTypes, {
    }).toPromise()
      .then(result => {
        if (!result || !(result.length > -1)) {
          return [];
        }
    return result;
  });
  };

}
