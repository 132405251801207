import React from 'react';
import DateUtils from '../../../services/Utility/DateUtils';
import MaterialTable from 'material-table';
import RawDataService from '../../../services/RawDataService';
import SecondaryLineLoader from '../../../components/Utils/SecondaryLineLoader';
import {conf} from '../../../config';
import {ProductTypes} from '../../../services/ProductService';
import {BatchStatuses} from '../../../services/BatchDataService';

// Columns to use for Otc products.
const otcColumns = [
  {title: 'Total US', field: 'totalUs', editable: 'never'},
  {title: 'BC Super Category', field: 'bcSuperCategory', editable: 'never'},
  {title: 'BC Category', field: 'bcCategory', editable: 'never'},
  {title: 'BC Sub Category', field: 'bcSubCategory', editable: 'never'},
  {title: 'BC Segment', field: 'bcSegment', editable: 'never'},
  {title: 'Brand Low', field: 'brandLow', editable: 'never'},
  {title: 'Brand High', field: 'brandHigh', editable: 'never'},
  {title: 'Brand Owner Low', field: 'brandOwnerLow', editable: 'never'},
  {title: 'Brand Owner High', field: 'brandOwnerHigh', editable: 'never'},
  {title: 'All Periods', field: 'allPeriods', editable: 'never'},
  {title: 'Dollar Value', field: 'dollarValue', editable: 'never'},
  {title: 'Revenue', field: 'revenue', editable: 'never'},
  {title: 'Units', field: 'units', editable: 'never'},
  {
    title: 'Marketshare By Value BC Super Category',
    field: 'marketshareByValueBCSuperCategory',
    editable: 'never'
  },
  {
    title: 'Marketshare By Units BC Super Category',
    field: 'marketshareByUnitsBCSuperCategory',
    editable: 'never'
  }
];

// Columns to use for Rx products.
const rxColumns = [
  {title: 'Corporation', field: 'corporation', editable: 'never'},
  {title: 'Manufacturer', field: 'manufacturer', editable: 'never'},
  {title: 'ProdForm1', field: 'prodForm1', editable: 'never'},
  {title: 'ProdForm2', field: 'prodForm2', editable: 'never'},
  {title: 'ProdForm3', field: 'prodForm3', editable: 'never'},
  {title: 'Product', field: 'product', editable: 'never'},
  {title: 'NDC', field: 'ndc', editable: 'never'},
  {title: 'Pack Size', field: 'packSize', editable: 'never'},
  {title: 'Pack Quantity', field: 'packQuantity', editable: 'never'},
  {title: `${DateUtils.getCurrentYear() - 1} TRx`, field: 'yearTrX', editable: 'never'},
  {title: `${DateUtils.getCurrentYear() - 1} Sales $`, field: 'revenue', editable: 'never'}
];

export default class StaticTable extends React.Component<Props, State> {
  pageSize = conf.defaultRowsPerPage;
  tableRef = React.createRef();

  getColumns(type) {
    if (type === ProductTypes.Rx) {
      return rxColumns;
    }
    return otcColumns;
  }
  canProgress(): boolean {
    return this.props.uploadStatus &&
    this.props.uploadStatus === BatchStatuses.complete;
  }

  loading(): boolean {
    return this.props.uploadStatus &&
        (this.props.uploadStatus === BatchStatuses.rawProcessing ||
        this.props.uploadStatus === BatchStatuses.initialized);
  }

  componentDidMount(): void {
    this.props.setCanProgress(this.canProgress());
    if (this.canProgress()) {
      this.tableRef.current.onChangePage(null, 0);
    }
  }

  componentDidUpdate(prevProps): void {
    if (prevProps.uploadStatus !== this.props.uploadStatus || prevProps.source !== this.props.source){
      this.props.setCanProgress(this.canProgress());
      if (this.canProgress()) {
        this.tableRef.current.onChangePage(null, 0);
      }
    }
  }

  render(){
    if (!this.loading()) {
      return <MaterialTable
          title=''
          tableRef={this.tableRef}
          columns={this.getColumns(this.props.source)}
          data={query => RawDataService.getProducts(query, this.props.source, this.props.batchId)}
          options={{
            search: false,
            filtering: false,
            sorting: false,
            pageSizeOptions: [20,50,100],
            pageSize: this.pageSize,
            actionsColumnIndex: -1,
            emptyRowsWhenPaging: false
          }}
      />;
    } else {
      return (<SecondaryLineLoader/>);
    }
  }
}
