import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import CardHeader from '@material-ui/core/CardHeader';
import Card from '@material-ui/core/Card';
import Box from '@material-ui/core/Box';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import { Divider } from '@material-ui/core';
import ValidationService from '../../../services/ValidationService';

const useStyles = makeStyles(theme => ({
    textField: {
        marginLeft: theme.spacing(.7),
        marginRight: theme.spacing(1),
    },
    marginTop: {
        marginTop: '15px'
    },
    divider: {
        marginTop: '15px',
        marginBottom: '15px'
    }
}));

const newEmptyProduct = {
    productName: '',
    units: '',
    notes: ''
};

export default function SharpsAddProductList(props) {
    const classes = useStyles();
    const [newProduct, setNewProduct] = React.useState(newEmptyProduct);
    const [focusedField, setFocusedField] = React.useState(null);
    const [attemptedAdd, setAttemptedAdd] = React.useState(false);

    const UpdateNewProduct = (value, field) => {

        if (!ValidationService.SharpsAdditionalDataIsValid(field, value, true)) {
            return;
        }

        let updatedNewProduct = { ...newProduct };
        updatedNewProduct[field] = value;

        setNewProduct(updatedNewProduct);
    }

    const AddNewProduct = () => {
        // Used to highlight errors after attempting to add the product
        setAttemptedAdd(true);

        // If adding the product succeeded, reset the inputs for another new product
        if (props.addAdditionalData(props.step, props.subStep, newProduct)) {
            setAttemptedAdd(false);
            setNewProduct(newEmptyProduct);
        }
    }

    const SaveFocusedField = (row, field, value) => {
        setFocusedField({
            row: row,
            field: field,
            value: value
        });
    }

    const RestoreIfLeftEmpty = (value) => {
        // If update field was left blank, reset it to its previous value
        if (value === '') {
            props.updateAdditionalData(focusedField.value, focusedField.row, focusedField.field, props.step)
        }
    }

    if (!props.data) {
        return <div />;
    }
    return (
        <Card style={{ width: '100%' }}>
            <CardHeader title={props.title} />
            <form noValidate autoComplete='off'>
                <Box alignItems='center'
                    display='flex'
                    flexWrap='wrap'
                    id='new-additional-product-box'
                    justifyContent='space-between'
                    p={1}
                >
                    <Fab
                        variant='extended'
                        size='large'
                        color='secondary'
                        aria-label='Add'
                        disabled={props.submitted}
                        onClick={AddNewProduct}
                        href={''}>
                        <AddIcon />
                        Add Product
                    </Fab>
                </Box>

                {props.data.length > 0 ? <Divider className={classes.divider} component={'hr'} /> : null}

                {props.data.map((row, i) => {
                    return (
                        <Box alignItems='center'
                            display='flex'
                            flexWrap='wrap'
                            justifyContent='space-between'
                            key={i}
                        >
                            <TextField
                                required
                                error={row.productName.length === 0 || row.productName.length > 50}
                                id='filled-applicable-product'
                                label='Product Name'
                                className={classes.textField}
                                value={row.productName}
                                margin='normal'
                                variant='outlined'
                                disabled={props.submitted}
                                onChange={e => props.updateAdditionalData(e.target.value, row, 'productName', props.step)}
                                onFocus={e => SaveFocusedField(row, 'productName', e.target.value)}
                                onBlur={e => RestoreIfLeftEmpty(e.target.value)}
                            />
                            <TextField
                                required
                                type='number'
                                error={row.units.length === 0 || row.units <= 0}
                                id='filled-units'
                                label='Units'
                                className={classes.textField}
                                value={row.units}
                                margin='normal'
                                variant='outlined'
                                disabled={props.submitted}
                                onChange={e => props.updateAdditionalData(e.target.value, row, 'units', props.step)}
                                onFocus={e => SaveFocusedField(row, 'units', e.target.value)}
                                onBlur={e => RestoreIfLeftEmpty(e.target.value)}
                            />
                            <TextField
                                id='filled-comments-notes'
                                label='Comments/Notes'
                                error={row.notes.length > 250}
                                className={classes.textField}
                                value={row.notes}
                                margin='normal'
                                variant='outlined'
                                disabled={props.submitted}
                                onChange={e => props.updateAdditionalData(e.target.value, row, 'notes', props.step)}
                            />
                            <Fab color='primary' aria-label='Delete' disabled={props.submitted}
                                onClick={() => props.removeAdditionalData(row, props.step)} href={''}>
                                <DeleteIcon />
                            </Fab>
                        </Box>
                    );
                })}
            </form>
        </Card>
    );
}
