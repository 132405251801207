/* @flow */

import React from 'react';
import {JSXElement} from '@babel/types';

type Props = {};
type State = {};

export default class NotFound extends React.Component<Props, State> {
  render(): JSXElement {
    return <h4>Page Not Found</h4>;
  }
}
