/* @flow */

import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import Card from '@material-ui/core/Card';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Button from '@material-ui/core/Button';
import SecondaryLineLoader from '../../../components/Utils/SecondaryLineLoader';
import type {InvoiceSearchResult} from '../../../services/InvoiceService';
import Box from '@material-ui/core/Box';
import Dividers from '../../../components/DataDisplay/Divider';
import Paper from '@material-ui/core/Paper';

const useStyles = makeStyles(theme => ({
  root: {
    margin: 'auto',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  moreButton: {
    width: '100%',
    margin: '5px 0px 0px 5px',
  },
  divider: {
    width: 1,
    height: 28,
    margin: 4,
  },
  result: {
    border: '1px solid #DFE3E8;',
    boxShadow: '0 0 0 0 rgba(255, 105, 135, .3)',
    backgroundColor: '#ffffff',
    margin: '0px 0px 0px 5px'
  },
  list: {
    padding: '0px !important',
  },


}));

type Props = {|
  classes: ClassNameMap,
  results: any,
  loading: boolean,
  hasNextPage: boolean,
  selectInvoice: InvoiceSearchResult => void,
  loadMore: void => void
|};

function InvoiceResults(props: Props) {
  const classes = useStyles();
  if (!props.results) {
    return null;
  }
  return (
      <React.Fragment>
        {props.loading ? <div className={classes.root}>
              <SecondaryLineLoader/>
            </div>
            : null}
        <List className={classes.list} dense component='nav' role='list'>
          {props.results.map((value: InvoiceSearchResult, index: number) => {
            return (
                <div
                    key={index}
                >
                  <ListItem
                      className={classes.result}
                      button
                      key={value.companyId}
                      role='listitem' component={'li'}
                      id={'invoiceResult-' + index}
                      onClick={() => props.selectInvoice(value)}
                  >
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <ListItemText
                            primary={value.companyName}
                            secondary={'Invoice Year:' + value.year}
                        />
                      </Grid>

                      <Grid item xs={6}>
                          <ListItemText
                              secondary={'Invoice #' + value.invoiceId}
                          />
                      </Grid>
                    </Grid>
                  </ListItem>
                </div>
            );
          })}
          {props.hasNextPage ?
              <Button
                  className={classes.moreButton}
                  color={'secondary'}
                  variant={'contained'}
                  onClick={props.loadMore}
              >
                Load More
              </Button> : null}
        </List>
      </React.Fragment>
  );
}

export default InvoiceResults;
