import React from 'react';
import SharpsAddProductList from '../components/SharpsAddProductList';

function getSteps() {
  return ['Other'];
}

export default function SharpsSelfReportingStepperOneC(props) {
  const step = '1C';

  function getSelectedData(subStep) {
    if (!props || !props.data) {
      return [];
    }

    return props.data.filter((elm) => elm.subStep === subStep);
  }

  return (
      <SharpsAddProductList title={getSteps()[0]} submitted={props.submitted} subStep={getSteps()[0]} step={step}
                            data={getSelectedData(getSteps()[0])} addAdditionalData={props.addAdditionalData}
                            removeAdditionalData={props.removeAdditionalData}
                            updateAdditionalData={props.updateAdditionalData}/>
  );
}
