import React, { useMemo } from 'react';
import { useDropzone } from 'react-dropzone';

const baseStyle = {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: '#bdbdbd',
    outline: 'none',
    transition: 'border .24s ease-in-out'
};

const activeStyle = {
    borderColor: '#2196f3'
};

const acceptStyle = {
    borderColor: '#00e676'
};

const rejectStyle = {
    borderColor: '#ff1744'
};

const removeButtonStyle = {
    float: 'right'
};

function StyledDropzone(props) {

    const {
        getRootProps,
        getInputProps,
        isDragActive,
        isDragAccept,
        isDragReject
    } = useDropzone({
        accept: '.xlsx',
        onDropAccepted: (files) => props.handleFilesAccepted(files)
    });

    const style = useMemo(() => ({
        ...baseStyle,
        ...(isDragActive ? activeStyle : {}),
        ...(isDragAccept ? acceptStyle : {}),
        ...(isDragReject ? rejectStyle : {})
    }), [
        isDragActive,
        isDragReject,
        isDragAccept
    ]);

    const fileData = props.file ? (
        <li key={props.file.path}>
            {props.file.name} {props.date ? props.date.toString() : null} - {props.file.size} bytes
            <button style={removeButtonStyle} onClick={() => props.handleFileRemoved()}>
                Remove
            </button>
        </li>
    ) : null;

    return (
        <div className='container'>
            <p className='flex-center pad-15'>
                {props.label}
            </p>

            <div {...getRootProps({ style })}>
                <input {...getInputProps()} />
                <p>Drag 'n' drop some files here, or click to select files</p>
            </div>

            <aside>
                <h4>Upload attachments</h4>
                <ul>{fileData}</ul>
            </aside>
        </div>
    );
}

export default StyledDropzone;
