/* @flow */

import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import MemberPortalDashboard from '../../../views/MemberPortalDashboard';
import MemberSharpsSelfReporting from '../../../views/MemberSharpsSelfReporting';
import MemberOTCSelfReporting from '../../../views/MemberOTCSelfReporting';
import MemberOTCDataTab from '../../../views/MemberOTCDataTab';
import MemberRxDataTab from '../../../views/MemberRxDataTab';
import Invoices from '../../../views/Invoices';
import {conf} from '../../../config';
import {Switch} from 'react-router-dom';
import NotFound from '../../NotFound';
import {Route, withRouter} from 'react-router';
import {getDerivedStateFromProps, routerTab} from '../../../components/Routes/RouteUtil';
import TabContainer from '../../../components/Navigation/TabContainer';
import {ClassNameMap} from '@material-ui/styles/withStyles';
import type {FormType} from '../../../services/NavTabsService';
import NavTabsService, {FormTypes} from '../../../services/NavTabsService';
import {LoggerService} from '../../../services/LoggerService';
import {JSXElement} from '@babel/types';

const logger = LoggerService.getLogger('NavTabsMember');

const styles = () => ({
  root: {
    flexGrow: 1,
    backgroundColor: '#f99900',
    padding: '0px'
  },
});

type Props = {|
  classes: ClassNameMap
|};

type State = {|
  selectedTab: string,
  applicableForms: Array<FormType>
|};

class NavTabsMember extends React.Component<Props, State> {
  static getDerivedStateFromProps = getDerivedStateFromProps;

  state = {
    selectedTab: '',
    applicableForms: []
  };

  componentDidMount(): void {
    NavTabsService.getApplicableForms().then((forms) => {
      this.setState({
        applicableForms: forms
      });
    });
  }

  changeTab = (e, tabRoute) => {
    this.setState({
      selectedTab: tabRoute
    });
  };

  getTabs = (): Array<JSXElement> => {
    return this.state.applicableForms.map((form, i) => {
      switch (form) {
        case FormTypes.OtcReviewForm:
          return routerTab('OTC Data', conf.hash.member.otcData, i);
        case FormTypes.RxReviewForm:
          return routerTab('RX Data', conf.hash.member.rxData, i);
        case FormTypes.SelfReportedOtcForm:
          return routerTab('OTC Private Label', conf.hash.member.otcPrivateLabel, i);
        case FormTypes.SelfReportedSharpsForm:
          return routerTab('SHARPS', conf.hash.member.sharps, i);
        default:
          logger.error('Received unknown form type.');
          return null;
      }
    });
  };

  getRouting = (): Array<JSXElement> => {
    return this.state.applicableForms.map((form, i) => {
      switch (form) {
        case FormTypes.OtcReviewForm:
          return <Route exact path={conf.hash.member.otcData} component={MemberOTCDataTab} key={i}/>;
        case FormTypes.RxReviewForm:
          return <Route exact path={conf.hash.member.rxData} component={MemberRxDataTab} key={i}/>;
        case FormTypes.SelfReportedOtcForm:
          return <Route exact path={conf.hash.member.otcPrivateLabel} component={MemberOTCSelfReporting} key={i}/>;
        case FormTypes.SelfReportedSharpsForm:
          return <Route exact path={conf.hash.member.sharps} component={MemberSharpsSelfReporting} key={i}/>;
        default:
          logger.error('Received unknown form type.');
          return null;
      }
    });
  };

  render() {
    const {classes} = this.props;

    if (!this.state.selectedTab) {
      return null;
    }

    return (
        <React.Fragment>
          <AppBar className={classes.root} position='static'>
            <Tabs onChange={this.changeTab} value={this.state.selectedTab} centered variant='fullWidth'
                  component={'div'}>
              {routerTab('Home', conf.hash.member.home)}
              {this.getTabs()}
              {routerTab('Invoice(s)', conf.hash.member.invoices)}
            </Tabs>
          </AppBar>
          <TabContainer>
            <Switch>
              <Route exact path={conf.hash.member.home} component={MemberPortalDashboard}/>
              {this.getRouting()}
              <Route exact path={conf.hash.member.invoices} component={Invoices}/>
              <Route component={NotFound}/>
            </Switch>
          </TabContainer>
        </React.Fragment>
    );
  }
}

export default withRouter<any>(withStyles(styles)(NavTabsMember));
