import React from 'react';
import Grid from '@material-ui/core/Grid';
import MemberSubmissionFilters from '../layouts/Admin/components/MemberSubmissionFilters';
import MemberSubmissionResults from '../layouts/Admin/components/MemberSubmissionResults';
import MemberSubmissionView from '../layouts/Admin/components/MemberSubmissionView';
import MemberSubmissionsExport from '../layouts/Admin/components/MemberSubmissionsExport';
import MemberSubmissionService, { MemberSubmissionResponse } from '../services/MemberSubmissionService';
import { LoggerService } from '../services/LoggerService';

const logger = LoggerService.getLogger('MemberSubmissions');

function AdminViewSelfReport() {
    const [currentId, setCurrentId] = React.useState(null);
    const [currentType, setCurrentType] = React.useState(null);
    const [currentYear, setCurrentYear] = React.useState(null);
    const [currentStatus, setCurrentStatus] = React.useState(null);
    const [data, setData] = React.useState([]);
    const [hasNextPage, setHasNextPage] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [page, setPage] = React.useState(0);
    //filters
    const [startDate, setStartDate] = React.useState((new Date()).getFullYear());
    const [endDate, setEndDate] = React.useState((new Date()).getFullYear());
    const [statusChecked, setStatusChecked] = React.useState(['Submitted']);
    const [typeChecked, setTypeChecked] = React.useState([]);
    const [keyword, setKeyword] = React.useState('');
    const pageSize = 20;

    React.useEffect(() => {
        refreshSearchResults();
    }, []);

    function fetchData(filters, data) {
        setLoading(true);
        MemberSubmissionService.postSubmissionFilter(filters)
            .then((memberSubmissions: MemberSubmissionResponse) => {
                setData(memberSubmissions.data.concat(data));
                setHasNextPage(memberSubmissions.hasNextPage);
            }).catch((err) => {
                logger.error(`Unable to get memberSubmissions for ${JSON.stringify(filters)}`, err);
            }).finally(() => {
                setLoading(false);
            });
    }

    function getOppositeStatus(status) {
        return status === 'Submitted' ? 'Saved' : 'Submitted';
    }

    function setCurrent(id, type, year, status) {
        setCurrentId(id);
        setCurrentType(type);
        setCurrentYear(year);
        setCurrentStatus(status);
    }

    function loadMore() {
        const newPage = page + 1;
        setPage(newPage);
        const filters = {
            startYear: startDate,
            endYear: endDate,
            statuses: statusChecked,
            types: typeChecked,
            keyword: keyword,
            page: newPage,
            pageSize: pageSize
        };
        fetchData(filters, data);
    }

    function saveFilters(filters) {
        setStartDate(filters.startDate);
        setEndDate(filters.endDate);
        setKeyword(filters.keyword);
        setTypeChecked(filters.typeChecked);
        setStatusChecked(filters.statusChecked);
        setPage(0);
        filters[page] = 0;
        const f = {
            startYear: filters.startDate,
            endYear: filters.endDate,
            statuses: filters.statusChecked,
            types: filters.typeChecked,
            keyword: filters.keyword,
            page: filters.page,
            pageSize: pageSize
        };
        fetchData(f, []);
    }

    function refreshSearchResults() {
        const filters = {
            startYear: startDate,
            endYear: endDate,
            statuses: statusChecked,
            types: typeChecked,
            keyword: keyword,
            page: 0,
            pageSize: pageSize
        };
        setPage(0);
        fetchData(filters, []);
        setCurrent(currentId, currentType, currentYear, getOppositeStatus(currentStatus));
    }

    return (
        <React.Fragment>
            <Grid container spacing={5}>
                <Grid item xs={3}>
                    <MemberSubmissionsExport/>
                    <MemberSubmissionFilters
                        startDate={startDate}
                        endDate={endDate}
                        statusChecked={statusChecked}
                        typeChecked={typeChecked}
                        keyword={keyword}
                        saveFilters={saveFilters}
                    />
                    <MemberSubmissionResults results={data} loading={loading} setCurrent={setCurrent} loadMore={loadMore}
                        hasNextPage={hasNextPage} />
                </Grid>
                <Grid item xs={9}>
                    <MemberSubmissionView currentId={currentId} status={currentStatus} currentType={currentType}
                        currentYear={currentYear} refresh={refreshSearchResults} />
                </Grid>
            </Grid>
        </React.Fragment>
    );
}

export default AdminViewSelfReport;
