import React from 'react';
import { conf } from '../config';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Grid from '@material-ui/core/Grid';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';
import SharpsAccordionSectionOne from '../layouts/Member/components/SharpsAccordionSectionOne';
import SharpsAccordionSectionTwo from '../layouts/Member/components/SharpsAccordionSectionTwo';
import SharpsSelfReportTopNav from '../layouts/Member/components/SharpsSelfReportTopNav';
import StringUtils from '../services/Utility/StringUtils.js';
import Button from '@material-ui/core/Button';
import CardActions from '@material-ui/core/CardActions';
import Box from '@material-ui/core/Box';
import SharpsDataDateNotification from '../layouts/Member/components/SharpsDataDateNotification';

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
    },
    avatar: {
        backgroundColor: '#0dbeb7',
    },
    headings: {
        padding: '0px 20px',
    },
    boldType: {
        fontWeight: 'bold',
        margin: '0px 0px 10px 0px'
    },
    boldRedType: {
        color: '#F6174D',
        fontWeight: 'bold',
        padding: '10px 0px 15px 0px',
    },
    lightFont: {
        fontWeight: 300,
        padding: '4px',
    },
    redType: {
        color: '#F6174D'
    },
    topTxt: {
        margin: '0px 0px 5px 0px',
    },
}
));

function SharpsFormView(props) {
    const classes = useStyles();
    const checked = props.checked;
    const setChecked = props.setChecked;
    const form = props.form;
    const readOnly = props.readOnly;
    const disabled = props.disabled || props.readOnly;
    const isPastDueDate = form.dueDate && new Date(form.dueDate) < (new Date()).setHours(0, 0, 0, 0);
    const formCreatedDate = (form.dateCreated && new Date(form.dateCreated)) ?? new Date();
    const useSimpleForm = formCreatedDate >= new Date(conf.features.simplifySharpsFormEffectiveDate);

    function canSave(f) {
        return !(f.isLocked === true) &&
            f.step1C.concat(f.step2A).concat(f.step2B).every(
                r => !StringUtils.isBlank(r.productName) && !StringUtils.isBlank(r.units) && r.units > 0 && r.productName.length <= 50 && r.notes.length <= 250);
    }

    function canSubmit(f) {
        return canSave(f) && f.allIqviaData.length === f.step1A.concat(f.step1B).length;
    }

    function getCardActions() {
        const disableSaveForMember = form.submitted || isPastDueDate;
        const disableSubmitForMember = disableSaveForMember || !checked;
        const showSaveButton = canSave(form) && !form.submitted;

        return <CardActions>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    This form cannot be submitted until all products listed are classified into one of the two categories provided above (Injectables or Devices).
                </Grid>
                <Grid item xs={12}>
                    {
                        !props.adminView &&
                        <div>
                            <Checkbox disabled={form.submitted || isPastDueDate || disabled}
                                onChange={evt => setChecked(evt.target.checked)} />
                            <span>
                                On behalf of my Company, I attest that the information provided in the Invoicing Portal is true, complete
                                and accurate to the best of my knowledge and belief.
                            </span>
                        </div>
                    }

                    {
                        showSaveButton &&
                            <Button
                                onClick={() => props.handleSave()}
                                color='primary'
                                disabled={(!props.adminView && disableSaveForMember) || disabled}
                                className={classes.button}
                                href={''}>
                                Save
                            </Button>
                    }

                    <Button
                        onClick={() => props.handleSubmit()}
                        color='primary'
                        disabled={(!props.adminView && disableSubmitForMember) || !canSubmit(form) || disabled}
                        className={classes.button}
                        href={''}>
                        {canSubmit(form) ? 'Submit' : 'Please fill out all required fields'}
                    </Button>
                </Grid>
            </Grid>
        </CardActions>
    }

    const sectionsDisabled = (!props.adminView && (form.submitted || isPastDueDate)) || readOnly || disabled;

    return (
        <React.Fragment>
            <SharpsSelfReportTopNav companyName={form.companyName || ''} isSubmitted={form.submitted} dateSubmitted={form.dateSubmitted} />

            <div className={classes.topTxt}>
                <Typography display='inline' className={classes.lightFont}>
                    As a participant in one or more sharps programs, your company must complete this sharps
                    self-reporting form. Sharps program participants that do not complete this self-reporting
                    form by the established deadline <strong>will be billed for the maximum
                        fee amount allocated to such producers</strong>.
                </Typography>
            </div>

            <Typography className={classes.lightFont}>
                Data provided by program participants will be seen only by authorized staff and used only for the purpose of
                the cost
                allocation under the terms of the non-disclosure agreement. This information will be used to determine your
                company's share of total program cost in each jurisdiction in which you participate according to the member
                approved cost allocation model:
            </Typography>
            <Typography className={classes.lightFont}>
                Base Fee: 0.5% of Total Program Cost (Paid by all participants)
            </Typography>
            <Typography className={classes.lightFont}>
                Sharps Devices Producers: 55% of Total Program Cost Balance
            </Typography>
            <Typography className={classes.lightFont}>
                Medicines Leading to the Use of a Sharp Producers: 45% of Total Program Cost Balance
            </Typography>
            <Typography className={classes.boldRedType}>
                If you update any information on this form and need to return later to complete the form,
                you must click the "Save" button at the bottom of the page, or all progress will be lost.
                When you have completed the form, you must click the "Submit" button.
            </Typography>

            <Grid item xs={6} lg={3}>
                <SharpsDataDateNotification className={classes.snackbar} isSubmitted={form.submitted}
                    dateSubmitted={form.dateSubmitted} />
            </Grid>


            {form.isLocked === true ? (<Typography className={classes.mainBit} variant='h4'>
                This form cannot be completed until after the Rx Data Review Form has been submitted.
            </Typography>)
                : form.companyId > 0 &&
                    (<div className={classes.root}>
                        <Grid container spacing={3}>
                            <Grid item xs>
                                <Card>
                                    <Box
                                        alignItems='center'
                                        display='flex'
                                        flexWrap='wrap'
                                    >
                                        <CardHeader
                                            avatar={
                                                <Avatar className={classes.avatar} component={'div'}>
                                                    1
                                                </Avatar>
                                            }
                                        >
                                        </CardHeader>

                                        <Box
                                            flexDirection={'column'}
                                            display='flex'
                                            flexWrap='wrap'
                                        >
                                            <Typography variant='h4'>
                                                Section 1
                                            </Typography>
                                            <Typography variant='overline' gutterBottom>
                                                Medicine Leading to use of Sharp (Injectables)
                                            </Typography>
                                        </Box>
                                    </Box>
                                    <Typography variant='subtitle1' className={classes.headings} gutterBottom>
                                        Units for products in this category will be derived from the IQVIA data. Units are at the TRx
                                        level
                                        (total Rx, or total prescriptions dispensed) - Example: One unit = one prescription dispensed.
                                        Please note that all products in this category need to be designated to either section 1A 
                                        (products "without" a permanently attached sharp ) OR section 1B  (products "with" a 
                                        permanently attached sharp).
                                    </Typography>
                                    <CardContent>
                                        <SharpsAccordionSectionOne
                                            submitted={sectionsDisabled}
                                            addData={props.addSelectedData}
                                            removeData={props.removeSelectedData}
                                            addAdditionalData={props.addAdditionalData}
                                            removeAdditionalData={props.removeAdditionalData}
                                            updateAdditionalData={props.updateAdditionalData}
                                            stepA={form.step1A}
                                            stepB={form.step1B}
                                            stepC={form.step1C}
                                            iqviaData={form.allIqviaData}
                                            useSimpleForm={useSimpleForm}
                                        />
                                    </CardContent>
                                </Card>
                            </Grid>
                        </Grid>
                        <Grid>
                            <Grid item xs>
                                <Card>
                                    <Box
                                        alignItems='center'
                                        display='flex'
                                        flexWrap='wrap'
                                    >
                                        <CardHeader
                                            avatar={
                                                <Avatar className={classes.avatar} component={'div'}>2</Avatar>
                                            }
                                        >
                                        </CardHeader>
                                        <Box
                                            flexDirection={'column'}
                                            display='flex'
                                            flexWrap='wrap'
                                        >
                                            <Typography variant='h5'>
                                                Section 2
                                            </Typography>
                                            <Typography variant='overline' gutterBottom>
                                                Sharps Devices
                                            </Typography>
                                        </Box>
                                    </Box>
                                    <CardContent>
                                        <SharpsAccordionSectionTwo
                                            submitted={sectionsDisabled}
                                            addAdditionalData={props.addAdditionalData}
                                            removeAdditionalData={props.removeAdditionalData}
                                            updateAdditionalData={props.updateAdditionalData}
                                            stepA={form.step2A}
                                            stepB={form.step2B} />
                                    </CardContent>
                                </Card>
                            </Grid>
                            {
                                !readOnly && getCardActions()
                            }
                        </Grid>
                    </div>)
            }
        </React.Fragment>
    );
}

export default SharpsFormView;
