import React, {useEffect} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CompanyService from '../../../services/CompanyService.js';
import type {CompanyProfile} from '../../../services/CompanyService';
import {companyProfileDefault} from '../../../services/CompanyService';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(.7),
    marginRight: theme.spacing(1)
  },
  companyNameField: {
    marginLeft: theme.spacing(.7),
    marginRight: theme.spacing(1),
    width: '100%'
},
}));

export default function FilledTextFields() {
  const classes = useStyles();
  const profileState: CompanyProfile = {
    ...companyProfileDefault
  };
  const [profile, setProfile] = React.useState(profileState);

  //Gets company information every time the user changes.
  useEffect(() => {
    CompanyService.getCompanyProfile().then((profile: CompanyProfile) => {
      setProfile(profile);
    });
  }, []);

  return (
      <Card>
        <CardHeader title='Company Info'/>
        <form className={classes.container} noValidate autoComplete='off'>
          <TextField
              id='filled-name'
              label='Company Name'
              className={classes.companyNameField}
              value={profile.name}
              InputProps={{
                readOnly: true
              }}
              disabled
              margin='normal'
              variant='outlined'
          />
          <TextField
              id='filled-address-line1'
              label='Address'
              className={classes.textField}
              value={profile.addressLine1}
              InputProps={{
                readOnly: true
              }}
              disabled
              margin='normal'
              variant='outlined'
          />
          <TextField
              id='filled-address-line2'
              label='Address Cont.'
              className={classes.textField}
              value={profile.addressLine2}
              InputProps={{
                readOnly: true
              }}
              disabled
              margin='normal'
              variant='outlined'
          />
          <TextField
              id='filled-city'
              label='City'
              className={classes.textField}
              value={profile.city}
              InputProps={{
                readOnly: true
              }}
              disabled
              margin='normal'
              variant='outlined'
          />
          <TextField
              id='filled-state'
              label='State'
              className={classes.textField}
              value={profile.state}
              InputProps={{
                readOnly: true
              }}
              disabled
              margin='normal'
              variant='outlined'
          />
          <TextField
              id='filled-zip-code'
              label='Zip'
              className={classes.textField}
              value={profile.zipCode}
              InputProps={{
                readOnly: true
              }}
              disabled
              margin='normal'
              variant='outlined'
          />
          <TextField
              id='filled-country'
              label='Country'
              className={classes.textField}
              value={profile.country}
              InputProps={{
                readOnly: true
              }}
              disabled
              margin='normal'
              variant='outlined'
          />
        </form>
      </Card>
  );
}
