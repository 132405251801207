import React from 'react';
import MaterialTable from 'material-table';
import InvoiceHistoryService from '../../../services/InvoiceService';
import InvoiceDialog from './InvoiceDialog';

type Props = {};
type State = {|
  invoiceId:number,
  openView:boolean,
  dueDate: string,
  createdDate: string,
  downloadLoading: ?int
|};

export default class InvoicesTable extends React.Component<Props, State> {
  state = {
    invoiceId:0,
    openView: false,
    dueDate: '',
    createdDate: '',
    downloadLoading: null,
    customerPoNumber: null
  };
  handleClickPrint = (row): void => {
    this.setState({downloadLoading: row.invoiceId});
    InvoiceHistoryService.downloadInvoice(row.invoiceId).then(() => {
      this.setState({downloadLoading: null});
    });
    };

  tableRef = React.createRef();

  handleClickView = (row): void => {
    this.setState({
      openView:true,
      invoiceId: row.invoiceId,
      dueDate: row.dueDate,
      createdDate: row.createdDate,
      customerPoNumber: row.customerPoNumber
    });
  };

  handleClose = (): void => {
    this.setState({ openView: false });
    this.tableRef && this.tableRef.current.onQueryChange();
  };

  render() {
    return (
        <React.Fragment>
          <InvoiceDialog open={this.state.openView} invoiceId={this.state.invoiceId} dueDate={this.state.dueDate} createdDate={this.state.createdDate} customerPoNumber={this.state.customerPoNumber} handleClose={this.handleClose}/>
          <MaterialTable
              tableRef={this.tableRef}
              title=''
              columns={[
                {title: 'Invoice #', field: 'invoiceId'},
                {title: 'Name', field: 'name'},
                {title: 'Invoice Date', field: 'createdDate'},
                {title: 'Due Date', hidden: true, field: 'dueDate'},
                {title: 'Participation Fee', field: 'participationFee', type: 'currency'},
                {field: 'companyId', hidden: true}
              ]}
              data={query => InvoiceHistoryService.getInvoiceHistory(query)}
              options={{
                search: false,
                actionsColumnIndex: -1,
                toolbar: false,
                sorting: false,
                filtering: false,
                emptyRowsWhenPaging: false,
              }}
              actions={[
                rowData => ({
                  icon: 'print',
                  tooltip: 'Print',
                  onClick: (event: Event, row: { id: number }) => this.handleClickPrint(row),
                  disabled: this.state.downloadLoading === rowData.invoiceId
                }),
                {
                  icon: 'visibility',
                  tooltip: 'View',
                  onClick: (event: Event, row: { id: number }) => this.handleClickView(row)
                }
              ]}
          />
        </React.Fragment>
    );
  }
}


