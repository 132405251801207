import React, { useEffect, useRef } from 'react';
import MaterialTable from 'material-table';
import { makeStyles } from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import CardActions from '@material-ui/core/CardActions';
import { conf } from '../../../config';
import { getColumns } from '../../Admin/components/AdminMemberColumns';
import { MemberEditService } from '../../../services/MemberEditService';
import Typography from '@material-ui/core/Typography';
import DateUtils from '../../../services/Utility/DateUtils';
import { ProductTypes, ProductVendor } from '../../../services/ProductService';
import { HttpError } from '../../../services/HttpService';
import NotificationService, {
    DefaultNotificationMessage,
    NotificationTypes
} from '../../../services/NotificationService';
import Grid from '@material-ui/core/Grid';
import MemberExcelOptions from './MemberExcelOptions';

const useStyles = makeStyles(theme => ({
    root: {
        padding: '0 !important',
        position: 'fixed',
    },
    infoText: {
        margin: '0px 5px 15px 5px',
        fontWeight: 300,
    },
}));

function MemberEditTable(props) {
    const classes = useStyles();
    const [checkedConfirmation, setCheckedConfirmation] = React.useState(false);
    const [isSubmitting, setIsSubmitting] = React.useState(false);
    const pageSize = conf.defaultRowsPerPage;
    const isSubmitted = props.isSubmitted;
    const setIsSubmitted = props.setIsSubmitted;
    const [totalUnits, setTotalUnits] = React.useState(0);
    const [totalSales, setTotalSales] = React.useState(0);
    const tableRef = React.useRef();

    useEffect(() => {
        MemberEditService.getTotalUnits(ProductVendor[props.source]).then(r => setTotalUnits(r.totalUnits));
    }, [props.source]);

    useEffect(() => {
        MemberEditService.getTotalSales(ProductVendor[props.source]).then(r => setTotalSales(r.totalSales));
    }, [props.source]);

    async function handleSubmitForm() {
        if (props.company > 0) {

            setIsSubmitting(true);

            MemberEditService.putReviewForm(ProductVendor[props.source])
                .then(() => {
                    NotificationService.fireNotification(NotificationTypes.success, DefaultNotificationMessage.submitted);
                    setIsSubmitted(true);
                    setIsSubmitting(false);
                })
                .catch((error: HttpError) => {
                    setIsSubmitting(false);
                    setIsSubmitted(false);
                    NotificationService.fireNotification(NotificationTypes.error, `Error ${error.message}`);
                })
        }
    }

    function handleUploadFile(file: any): Promise<boolean> {

        return MemberEditService.uploadMemberFile(file, ProductVendor[props.source])
            .then(() => {
                NotificationService.fireConfirmationNotification(NotificationTypes.success, 'Your file has been imported successfully.');

                if (tableRef.current !== null) {
                    tableRef.current.onQueryChange(); //Refresh the table
                }

                return true;
            })
            .catch((error: HttpError) => {
                NotificationService.fireConfirmationNotification(NotificationTypes.error, `Error ${error.message}`);
                throw error;
            });
    }

    return (<React.Fragment>
        <MaterialTable
            tableRef={tableRef}
            options={{
                search: false,
                filtering: false,
                sorting: false,
                toolbar: false,
                pageSize: pageSize,
                pageSizeOptions: [20, 50, 100],
                actionsColumnIndex: -1,
                emptyRowsWhenPaging: false,
                headerStyle: {
                    background: 'rgba(0,0,0,.9)',
                    color: 'white',
                    padding: '20px 0',
                    position: 'sticky',
                    top: '0',
                },
                fixedColumns: {
                    left: 2,
                    right: 0
                },
                maxBodyHeight: '300px'
            }}
            title={''}
            columns={getColumns(props.source)}
            data={query => MemberEditService.getMemberByCompanyId(ProductVendor[props.source], query).then((table) => {
                props.setHasProducts(table.data.length > 0);
                return table;
            })}
            className={classes.card}
            editable={!isSubmitted ? {
                onRowUpdate: (newData, oldData) =>
                    MemberEditService.updateVendorData(ProductVendor[props.source], newData).then(() => {
                        NotificationService.fireNotification(NotificationTypes.success, DefaultNotificationMessage.saved);
                    }).catch((error: HttpError) => {
                        NotificationService.fireNotification(NotificationTypes.error, `Error ${error.message}`);
                    }).finally(() => {
                        MemberEditService.getTotalUnits(ProductVendor[props.source]).then(r => setTotalUnits(r.totalUnits));
                        MemberEditService.getTotalSales(ProductVendor[props.source]).then(r => setTotalSales(r.totalSales));
                    })
            } : null}
        />
        <CardActions>
            <Grid container spacing={2}>
                {isSubmitted ? null : <Grid item xs={6}>
                    <Grid container>
                        <Grid item xs={1}>
                            <Checkbox onChange={e => {
                                setCheckedConfirmation(e.target.checked);
                            }} />
                        </Grid>
                        <Grid item xs={11}>
                            <span>
                                On behalf of my Company, I attest that the information provided in the Invoicing Portal is true, complete
                                and accurate to the best of my knowledge and belief.
                            </span>
                        </Grid>
                    </Grid>
                </Grid>
                }
                <Grid item xs={1}>
                    <Button color='primary' variant='contained' disabled={isSubmitted || !checkedConfirmation || isSubmitting}
                        onClick={handleSubmitForm} href={''}>Submit</Button>
                </Grid>
                
                <Grid item xs={isSubmitted ? 7 : 1}>
                    {/*spacing*/}
                </Grid>

                <Grid item xs={2}>
                    <Typography variant='h4' gutterBottom>
                        Total {props.source === ProductTypes.Rx ? `${DateUtils.getCurrentYear() - 1} TRx` : 'Units'}: {totalUnits}
                    </Typography>
                </Grid>

                <Grid item xs={2}>
                    <Typography variant='h4' gutterBottom>
                        Total Sales: ${totalSales}
                    </Typography>
                </Grid>

                <Grid item xs={12}>
                    <MemberExcelOptions
                        onExcelExport={() => MemberEditService.exportToExcel(ProductVendor[props.source])}
                        onExcelImport={handleUploadFile}
                        noImport={isSubmitted}
                    />
                </Grid>
            </Grid>
        </CardActions>
    </React.Fragment>
    );
}

export default MemberEditTable;
