/* @flow */

import React from 'react';
import Button from '@material-ui/core/Button';
import { Typography, withStyles } from '@material-ui/core';
import { JSXElement } from '@babel/types';
import StyledDropzone from '../../../components/CustomComps/FileUpload';
import Grid from '@material-ui/core/Grid';
import PrimaryProgressLoader from '../../../components/Utils/PrimaryProgressLoader';

const styles = theme => ({
    redType: {
        color: '#F6174D'
    },
    numberListItem: {
        fontWeight: 300,
        padding: '10px 0px 0px 0px'
    },
    infoMargin: {
        margin: '10px 0px 10px 0px',
    },
    boldType: {
        fontWeight: 'bold'
    },
    numberList: {
        padding: '0px 0px 0px 40px'
    }
});

type Props = {|
    classes: ClassNameMap,
    onExcelExport: () => void,
    onExcelImport: (file: any) => Promise <boolean>,
    noImport: boolean
    |};

type State = {|
    importDisabled: boolean,
    showFileUpload: boolean,
    uploadedFile: any,
    errorMessage: string
    |};

const defaultErrorMessage = 'There was a problem importing your changes.';

class MemberExcelOptions extends React.Component<Props, State> {

    state: State = {
        importInProgress: false,
        showFileUpload: false,
        uploadedFile: null,
        errorMessage: null
    };

    handleFilesAccepted = (files: [any]): void => {
        if (files != null && files.length > 0) {
            this.setState({
                uploadedFile: files[0]
            });
        }
    }

    handleFileRemoved = (): void => {
        this.setState({
            uploadedFile: null,
            errorMessage: null
        });
    }

    handleImportConfirmed = (): void => {
        // Lock the buttons while the import is processing
        this.setState({
            importInProgress: true
        });

        this.props.onExcelImport(this.state.uploadedFile)
            .then(success => {

                // On success, reset the state
                if (success) {
                    this.setState({
                        importInProgress: false,
                        showFileUpload: false,
                        uploadedFile: null,
                        errorMessage: null
                    });
                }
                else {
                    this.setState({
                        importInProgress: false,
                        errorMessage: defaultErrorMessage
                    });
                }
            })
            .catch(e => {
                // Display the exception's message if it has one
                const messageToDisplay = (e.message !== null && e.message !== undefined) ?
                    e.message : defaultErrorMessage

                this.setState({
                    importInProgress: false,
                    errorMessage: messageToDisplay
                });
            })
    }

    showImport = (): void => {
        this.setState({
            showFileUpload: true
        });
    }

    hideImport = (): void => {
        this.setState({
            showFileUpload: false
        });
    }

    render(): JSXElement {
        const { classes } = this.props;

        const allowImportConfirm = this.state.showFileUpload && (this.state.uploadedFile !== null);

        const label = "";

        return (
            <Grid container spacing={3}>
                <Grid item xs={12} className={classes.infoMargin}>
                    <Typography display='inline' className={classes.boldType}>
                        OPTIONAL: Import your reviewed data. 
                    </Typography>

                    <div className={classes.numberList}>
                        <Typography className={classes.numberListItem}>
                            1. Export this data to review and provide the required information via Excel. Use
                            "True" to indicate a checked box and "False" to indicate an un-checked box (e.g.
                            indicating "True" in the "Is Covered" column will mark a product as covered).
                        </Typography>

                        <Typography className={classes.numberListItem}>
                            2. Next, use the "Import from Excel" button below to import information from the
                            modified Excel (.xlsx) file. Changes will only be imported to fields that are available
                            for members to modify in the above form (only fields with a checkbox can be modified).
                            Do not make changes to static fields (e.g. "NDC," Product," 2021 TRx," etc.) as they will
                            not be saved, and the information in these fields must match the export file for the import
                            to be successful.
                        </Typography>

                        <Typography className={classes.numberListItem}>
                            3. Following the import, hit the "Submit" button above to complete your submission.
                        </Typography>

                        <Typography className={`${classes.numberListItem} ${classes.boldType}`}>
                            * The import may take several minutes. Please remain on this page until the import is complete.
                        </Typography>
                    </div>
                </Grid>

                <Grid item xs={2}>
                    <Button color='primary' variant='contained'
                        onClick={this.props.onExcelExport} href={''}>Export to Excel</Button>
                </Grid>
                {
                    this.props.noImport ? null :
                        <Grid item xs={5}>
                            {this.state.showFileUpload ?
                                <div>
                                    <StyledDropzone
                                        file={this.state.uploadedFile}
                                        handleFilesAccepted={this.handleFilesAccepted}
                                        handleFileRemoved={this.handleFileRemoved}
                                        label={label}
                                    />
                                    {
                                        this.state.errorMessage !== null ?
                                            <Typography className={classes.redType}>
                                                {this.state.errorMessage}
                                            </Typography>
                                            : null
                                    }
                                    {
                                        this.state.importInProgress ?
                                            <PrimaryProgressLoader /> :
                                            <Grid container spacing={3}>
                                                <Grid item xs={3}>
                                                    <Button color='secondary' variant='contained' disabled={this.state.importInProgress}
                                                        onClick={this.hideImport} href={''}>Cancel</Button>
                                                </Grid>
                                                <Grid item xs={3}>
                                                    <Button color='primary' variant='contained' disabled={!allowImportConfirm || this.state.importInProgress}
                                                        onClick={this.handleImportConfirmed} href={''}>Import</Button>
                                                </Grid>
                                            </Grid>
                                    }
                                </div> :
                                <Button color='primary' variant='contained'
                                    onClick={this.showImport} href={''}>Import from Excel</Button>
                            }
                        </Grid>
                }
            </Grid>
            
        );
    }
}

export default withStyles(styles)(MemberExcelOptions);