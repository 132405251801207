/* @flow */

export default class ValidationService {

    static SharpsAdditionalDataIsValid = (field, value, allowEmpty) => {
        if (field === 'units') {
            const units = Number(value);

            if ((!Number.isInteger(units) || units < 0) || (value === '' && !allowEmpty) || (units === 0 && !allowEmpty)) {
                return false;
            }
        }
        else if (field == 'productName') {
            if (value.length > 50 || (value.length === 0 && !allowEmpty)) {
                return false;
            }
        }
        else if (field == 'notes') {
            if (value.length > 250) {
                return false;
            }
        }

        return true;
    }

}
