/* @flow */

import React, { useEffect } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import ProgramAsyncSelect from './ProgramAsyncSelect';
import { ProgramParticipationService, ParticipationDisplayModes } from '../../../services/ProgramParticipationService';
import ObjectUtils from '../../../services/Utility/ObjectUtils';
import Chip from '@material-ui/core/Chip';
import type { ProgramView } from '../../../services/ProgramService';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Paper from '@material-ui/core/Paper';
import MarketShareItem from './MarketShareItem';
import NotificationService, {
  DefaultNotificationMessage,
  NotificationTypes
} from '../../../services/NotificationService';
import { HttpError } from '../../../services/HttpService';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import type { MemberType } from '../../../services/MemberTypeService';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import DateUtils from '../../../services/Utility/DateUtils';
import MenuItem from '@material-ui/core/MenuItem';
import RevenueTotalItem from './RevenueTotalItem';

const useStyles = makeStyles(theme => ({

  card: {
    maxWidth: '800px',
    margin: '20px',
  },
  header: {
    color: '#0dbeb7',
  },
  chip: {
    margin: '10px',
  },
  drawer: {
    position: 'relative',
    margin: '50px'
  },
  marketItems: {
    overflow: 'scroll',
    maxHeight: 1100,

  },
  memberTypeContainer: {
    position: 'relative',
    height: '48px'
  },
  inputLabel: {
    position: 'absolute'
  },
  memberTypeSelect: {
    marginTop: '16px'
  },

}));

const styles = theme => ({
  root: {
    margin: '0px',
    backgroundColor: '#0dbeb7',
    padding: '25px 24px',
  },

  closeButton: {
    position: 'absolute',
    right: theme.spacing(2),
    top: theme.spacing(2),
  },
});

const blankSettings = {
  id: 0,
  name: '',
  ppswgId: 0,
  address: '',
  city: '',
  state: '',
  zipCode: '',
  country: '',
  memberTypeId: null,
  affiliatedCompanies: [],
  programs: [],
  marketShares: [],
  revenueTotals: []
};

type DialogProps = {|
  title: string,
    classes: ClassNameMap,
      onClose: () => void
|};

const DialogTitle = withStyles(styles)((props: DialogProps) => {
  const { classes, onClose } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root}>
      <Typography variant='h3'>Program Participation Details</Typography>
      {onClose ? (
        <IconButton aria-label='close' className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(2),
    backgroundColor: '#f6f6f6 !important'
  },
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
  root: {
    margin: 0,
  },
}))(MuiDialogActions);

export type Props = {|
  open: boolean,
    handleClose: (Event, string) => void,
      companyId: number,
        memberTypes: Array<MemberType>
|};

export default function ProgramParticipationDialog(props: Props) {
  const classes = useStyles();

  const [companySettings, setCompanySettings] = React.useState(blankSettings);
  const [year, setYear] = React.useState(DateUtils.currentYear);
  const [mode, setMode] = React.useState(ParticipationDisplayModes.MarketShare)

  const hasMarketShares = companySettings.marketShares && companySettings.marketShares.length > 0;
  const hasRevenueTotals = companySettings.revenueTotals && companySettings.revenueTotals.length > 0;

  useEffect(() => {
    if (props.companyId > 0) {
      ProgramParticipationService.getCompanySettings(props.companyId)
        .then(result => setCompanySettings(result))
    } else {
      setCompanySettings(blankSettings);
    }
  }, [props.companyId]);

  function onTextChange(property, value) {
    // Check if this property exists in the settings.
    if (value !== undefined && ObjectUtils.hasPropertyWithValue(companySettings, property)) {
      let newSettings = Object.assign({}, companySettings);
      newSettings[property] = value;
      setCompanySettings(newSettings);
    }
  }
  function getYearOptions() {  
    let startYear = DateUtils.currentYear - 4
    return Array((DateUtils.currentYear + 1) - startYear).fill()
    .map((_, i) =>
        <MenuItem key={i} value={i + startYear}>{i + startYear}</MenuItem>
    );
}

  function onPPSWGIdChange(value) {
      // Check if the value is valid.
      const valueNum = value === '' ? 0 : parseInt(value, 10);
    if (valueNum > -1) {
      let newSettings = Object.assign({}, companySettings);
        newSettings.ppswgId = valueNum;
      setCompanySettings(newSettings);
    }
  }

  function onProgramChange(programs: Array<ProgramView>) {
    setCompanySettings({
      ...companySettings,
      programs: programs || []
    });
  }

  function onMemberTypeChange(id) {
    let newSettings = Object.assign({}, companySettings);
    newSettings.memberTypeId = id;
    setCompanySettings(newSettings);
  }

  function saveSettings() {
    if (companySettings.memberTypeId > 0) {
      const setAsNonMember = props.memberTypes.find(e => e.id === companySettings.memberTypeId).name === 'NonMember';
      if (setAsNonMember || companySettings.ppswgId > 0) {
        ProgramParticipationService.upsertCompanySettings(companySettings)
          .then(() => {
            NotificationService.fireNotification(NotificationTypes.success, DefaultNotificationMessage.saved);
            props.handleClose(null, 'SaveCompleted');
          })
          .catch((error: HttpError) => {
            NotificationService.fireNotification(NotificationTypes.error, `Error ${error.message}`);
          });
      } else {
        // Display error notification.
        NotificationService.fireNotification(NotificationTypes.error, `Error Invalid PPSWG Id`);
      }
    } else {
      NotificationService.fireNotification(NotificationTypes.error, `Error Invalid Member Type`);
    }
  }

  return (
    <React.Fragment>
      <Dialog id='programParticipationDetails' maxWidth='lg' open={props.open} onClose={props.handleClose}
        aria-labelledby='customized-dialog-title'>
        <DialogTitle id='customized-dialog-title' className={classes.header} onClose={props.handleClose}>
          Program Participation Details
          </DialogTitle>
        <DialogContent>
          <Grid container spacing={3}>
            <Grid item container sm={12} md={8} direction='row'
              justify='space-between'>

              <Card className={classes.card}>
                <CardContent>
                  <Typography variant='h6' gutterBottom>
                    Parent Company
                    </Typography>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        id='parentCompanyName'
                        name='parentCompanyName'
                        label='Parent Company Name'
                        value={companySettings.name}
                        onChange={e => onTextChange('name', e.target.value)}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={2}>
                      <TextField
                        id='PPSWGId'
                        name='PPSWGId'
                        label='PPSWG Id'
                        value={companySettings.ppswgId}
                        onChange={e => onPPSWGIdChange(e.target.value)}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <div className={classes.memberTypeContainer}>
                        <InputLabel shrink className={classes.inputLabel}>
                          MemberType
                          </InputLabel>
                        <Select
                          className={classes.memberTypeSelect}
                          value={companySettings.memberTypeId}
                          onChange={e => onMemberTypeChange(e.target.value)}
                          inputProps={{ name: 'memberType' }}
                          fullWidth
                        >
                          {[<option key={0} value='' disabled></option>,
                          ...(props.memberTypes.map(memberType => <option key={memberType.id}
                            value={memberType.id}>{memberType.name}</option>))]}
                        </Select>
                      </div>
                    </Grid>

                    <Grid item xs={12}>
                      <TextField
                        id='address'
                        name='address'
                        label='Address'
                        value={companySettings.address}
                        onChange={e => onTextChange('address', e.target.value)}
                        fullWidth
                      />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <TextField
                        id='city'
                        name='city'
                        label='City'
                        value={companySettings.city}
                        onChange={e => onTextChange('city', e.target.value)}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        id='state'
                        name='state'
                        label='State/Province/Region'
                        value={companySettings.state}
                        onChange={e => onTextChange('state', e.target.value)}
                        fullWidth />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        id='zip'
                        name='zip'
                        label='Zip / Postal code'
                        value={companySettings.zipCode}
                        onChange={e => onTextChange('zipCode', e.target.value)}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        id='country'
                        name='country'
                        label='Country'
                        value={companySettings.country}
                        onChange={e => onTextChange('country', e.target.value)}
                        fullWidth
                      />
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>

              <Card className={classes.card}>
                <CardContent>
                  <Typography variant='h6' gutterBottom>
                    Primary Contact
                    </Typography>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        id='firstName'
                        name='firstName'
                        label='First name'
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        id='lastName'
                        name='lastName'
                        label='Last name'
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        id='title'
                        name='title'
                        label='Title'
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        id='contactEmail'
                        name='contactEmail'
                        label='Contact Email'
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        id='contactPhone'
                        name='contactPhone'
                        label='Contact Phone'
                        fullWidth
                      />
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>

              <Grid item xs={12} sm={12}>

                <Card className={classes.card}>
                  <CardContent>
                    <Typography color='textSecondary' gutterBottom>
                      Affiliated Companies
                      </Typography>
                    {companySettings.affiliatedCompanies.map((name, index) => {
                      return (<Chip
                        variant='outlined'
                        size='small'
                        color='secondary'
                        key={index}
                        label={name}
                        className={classes.chip}
                        component={'div'} />);
                    })}
                  </CardContent>
                </Card>
              </Grid>


              <Grid item xs={12} sm={12}>

                <Paper className={classes.card}>
                  <CardContent>
                    <Typography color='textSecondary' gutterBottom>
                      Program Participation
                      </Typography>
                    <ProgramAsyncSelect initialPrograms={companySettings.programs.map((data) => {
                      return { value: data.id, label: data.name };
                    })} onChange={onProgramChange} />
                  </CardContent>
                </Paper>

              </Grid>


            </Grid>
            {(hasMarketShares || hasRevenueTotals) ?
              (
                <Grid item sm={12} md={4}>
                  <Paper className={classes.root}>
                    <Grid>
                      <Grid item sm={12}>
                        <Select
                          className={classes.input}
                          value={year}
                          onChange={e => setYear(e.target.value)}
                        >
                          {getYearOptions()}
                        </Select>
                      </Grid>
                      <Grid item sm={12}>
                        <ToggleButtonGroup size='small' value={mode} exclusive
                          onChange={(e, v) => { if (v) { setMode(v); } }} className='marg-top-20'>
                          <ToggleButton key={'1'} value={ParticipationDisplayModes.MarketShare} href={''}>
                            <p>Market Share</p>
                          </ToggleButton>
                          <ToggleButton key={'2'} value={ParticipationDisplayModes.Revenue} href={''}>
                            <p>Revenue</p>
                          </ToggleButton>,
                        </ToggleButtonGroup>
                      </Grid>
                    </Grid>
                  </Paper>
                  <Paper className={classes.marketItems}>
                    {
                      mode === ParticipationDisplayModes.MarketShare ?
                        companySettings.marketShares.filter(data => data.year === year).map(
                          data => 
                            (<MarketShareItem programName={data.programName} percentage={data.percentage}
                              category={data.category} />)
                        ) :
                        companySettings.revenueTotals.filter(data => data.year === year).map(
                          data => (<RevenueTotalItem revenue={data.totalRevenue} category={data.category} />)
                        )
                    }
                  </Paper>
                </Grid>)
              : null
            }
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button variant={'contained'} onClick={saveSettings} color='primary' href={''}>
            Save
            </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
