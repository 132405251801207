/* @flow */

import Cookies from 'universal-cookie';
import {conf} from '../config';
import {LoggerService} from './LoggerService';
import type {HttpMethod, HttpParams, HttpRequestOptions} from './HttpTypes';
import {HTTP_ERROR_CODE} from './HttpTypes';
import {catchError, map, retryWhen, switchMap, timeout} from 'rxjs/operators';
import Axios from 'axios-observable';
import StringUtils from './Utility/StringUtils';
import {AxiosRequestConfig, AxiosResponse} from 'axios';
import {Observable, throwError, TimeoutError, timer} from 'rxjs';
import {saveAs} from 'file-saver';

const HTTP_RETRY_INTERVAL = conf.httpRetryInterval;
const HTTP_RETRY_LIMIT = conf.httpRetryLimit;
const HTTP_REQUEST_TIMEOUT = conf.httpRequestTimeout;
const HTTP_REQUEST_TIMEOUT_RETRY_LIMIT = conf.httpRequestTimeoutRetryLimit;
const RES_TYPE = 'arraybuffer';

const logger = LoggerService.getLogger('HttpService');

export class HttpService {

  /*
      Create an HTTP GET request to fetch a resource.

      Example usage:
        HttpService.get("/foo/:bar/baz?query=:value", {
          pathParams: {
            bar: "dog"
          },
          queryParams: {
            value: 123
          }
        })
        .toPromise()
        .then((fooObj)=>{
          logger.info("Got " + JSON.stringify(fooObj));
        }).catch((e)=>{
          logger.error("Failed to get")
        })
   */
  static get(urlPath: string, requestOptions?: HttpRequestOptions): Observable<Object | null> {
    return HttpService._request('get', urlPath, requestOptions);
  }

  /*
      Create an HTTP POST request to create a new resource.

      Example usage:
        const user = {name: "foo"};

        HttpService.post("/foo/:bar/baz", {
          data: user,
          pathParams: {
            bar: "dog"
          },
          queryParams: {
            value: 123
          }
        })
        .toPromise()
        .then((fooObj)=>{
          logger.info("Got " + JSON.stringify(fooObj));
        }).catch((e)=>{
          logger.error("Failed to post")
        })
   */
  static post(urlPath: string, requestOptions?: HttpRequestOptions): Observable<any> {
    return HttpService._request('post', urlPath, requestOptions);
  }

  /*
      Create an HTTP PUT request to update a resource.

      Example usage:
        const user = {name: "foo"};

        HttpService.put("/foo/:bar/baz", {
          data: user,
          pathParams: {
            bar: "dog"
          },
          queryParams: {
            value: 123
          }
        })
        .toPromise()
        .then((fooObj)=>{
          logger.info("Got " + JSON.stringify(fooObj));
        }).catch((e)=>{
          logger.error("Failed to post")
        })
   */
  static put(urlPath: string, requestOptions?: HttpRequestOptions): Observable<any> {
    return HttpService._request('put', urlPath, requestOptions);
  }

  /*
      Create an HTTP DELETE request to remove a resource.

      Example usage:
        HttpService.delete("/foo/:bar/baz", {
          pathParams: {
            bar: "dog"
          }
        })
        .toPromise()
        .then((fooObj)=>{
          logger.info("Got " + JSON.stringify(fooObj));
        }).catch((e)=>{
          logger.error("Failed to post")
        })
   */
  static delete(urlPath: string, requestOptions?: HttpRequestOptions): Observable<void> {
    return HttpService._request('delete', urlPath, requestOptions);
  }

  static getFileDownload(urlPath: string, requestOptions?: HttpRequestOptions): Observable<any> {
    return HttpService.get(urlPath, {...requestOptions, responseType: 'blob', parser: HttpUtils.parseFile});
  }

  static putFileDownload(urlPath: string, requestOptions?: HttpRequestOptions): Observable<void> {
    return HttpService.put(urlPath, {...requestOptions, responseType: 'blob', parser: HttpUtils.parseFile});
  }

  static postFileDownload(urlPath: string, requestOptions?: HttpRequestOptions): Observable<void> {
    return HttpService.post(urlPath, {...requestOptions, responseType: 'blob', parser: HttpUtils.parseFile});
  }

  static _request(method: HttpMethod, urlPath: string, requestOptions?: HttpRequestOptions): Observable<any> {
    requestOptions = requestOptions || (Object.freeze({}): HttpRequestOptions);

    const {useMockApi, externalUrl, pathParams, queryParams, responseType, data} = requestOptions;
    let {httpRetryLimit} = requestOptions;

    const mockApiBase = useMockApi ? conf.mockApiBase : '';
    const url = externalUrl || HttpUtils.parseUrlWithParams(urlPath, pathParams, mockApiBase);

    if ((method === 'put' || method === 'post') && !requestOptions.data) {
      logger.warn(`data should be provided in the requestOptions for ${method} ${url}`);
    }

    if (method === 'post' && (httpRetryLimit === null || httpRetryLimit === undefined)) {
      httpRetryLimit = 0;
    }

    const axiosOpts: AxiosRequestConfig = {
      method: method,
      url: url,
      data: data || null,
      withCredentials: true,
      responseType: responseType || RES_TYPE,
      params: queryParams || null
    };
    Axios.defaults.headers.common['Authorization'] =
        'Bearer ' + (new Cookies()).get('ppswg_jwt');
    return Axios.request(axiosOpts).pipe(
        timeout(requestOptions.httpTimeout || HTTP_REQUEST_TIMEOUT),
        retryWhen((errObs: Observable<any>) => {
          return HttpUtils.timedRetryHandler(errObs, httpRetryLimit, url);
        }),
        catchError(HttpUtils.errHandler),
        map(requestOptions.parser || HttpUtils.parseJson)
    );
  }

}

export class HttpError {
  code: number;
  message: string;

  constructor(code: number, message: string): void {
    this.code = code;
    this.message = message;
  }

  toString(): string {
    return `HttpError: ${this.code} ${this.message}`;
  }
}

export class HttpUtils {

  static parseJson(res: AxiosResponse): { [key: string]: any } | null {
    if (!res || res.data === null || res.data === undefined) {
        return null;
    }

    let responseText = '';
    try {
      responseText = Buffer.from(res.data, 'binary').toString();
    } catch (e) {
      logger.error('Failed reading response body from server, returning null.');
      return null;
    }

    const url = HttpUtils.rebuildReqUrl(res);

    if (responseText === '' || responseText === 'OK') {
      logger.info(`${res.status} ${res.statusText}: ${url}`);
      return null;
    }

    try {
      logger.info(`${res.status} ${res.statusText}: ${url}`);
      return JSON.parse(responseText);
    } catch (e) {
      const resTextObj = {
        responseText: responseText
      };

      logger.warn(`${url}: Server returned text instead of JSON, using: ${JSON.stringify(resTextObj)}`);

      return resTextObj;
    }
  };

  // Axios leaves the query params out of the url, and this method recreates the full url.
  static rebuildReqUrl(res: AxiosResponse): string {
    let url = res.config.url;
    if (res.config.params) {
      const params = res.config.params;
      const paramKeys = Object.keys(params);
      if (paramKeys.length > 0) {
        try {
          // build the query params back in
          url = url + '?' + paramKeys.map(k => `${k}=${encodeURIComponent(params[k])}`).join('&');
        } catch (e) {
          logger.warn(`${url}: Failed to rebuild url string`);
        }
      }
    }

    return url;
  }

  static parseFile(res: AxiosResponse): void {
    if (!res || res.data === null || res.data === undefined) {
      logger.warn('Ignoring file download: empty response.');
      return;
    }

    let fileName = res.headers ? res.headers[conf.filenameHeader] : '';
    if (!fileName) {
      fileName = conf.defaultDownloadedFileName;
    }

    try {
      HttpUtils.saveFile(res.data, fileName);
      logger.info(`${res.status} ${res.statusText}: ${res.config.url}: ${fileName}`);
    } catch (e) {
      logger.error('Failed piping file download:', fileName, res.data, e);
    }
  }

  // Wrapping es5 import for testability.
  static saveFile(data: any, fileName: string): void {
    saveAs(data, fileName);
  }

  static parseUrlWithParams(urlPath: string, params: HttpParams | any, apiBase?: string): string {
    const base = apiBase || conf.api.base;
    if (!params || Object.keys(params).length === 0) {
      return base + urlPath;
    }
    const url = base + urlPath;

    const colonParams = {};
    Object.keys(params).forEach(key => {
      colonParams[':' + key] = params[key];
    });

    return StringUtils.replaceMap(url, colonParams);
  }

  static timedRetryHandler(errObs: Observable<any>, httpRetryLimit: number | any = HTTP_RETRY_LIMIT,
      url: string): Observable<any> {
    if (httpRetryLimit === null || httpRetryLimit === undefined) {
      httpRetryLimit = conf.httpRetryLimit;
    }

    let count = 0;
    return errObs.pipe(
        switchMap((res: AxiosResponse | TimeoutError) => {

          if (res instanceof TimeoutError && count < HTTP_REQUEST_TIMEOUT_RETRY_LIMIT) {
            logger.debug('Retrying request after timeout to url "' + url + '".', 'Attempt ' + count + '.');

            count += 1;
            return timer(HTTP_RETRY_INTERVAL);
          } else if (count >= httpRetryLimit ||
              res.status === HTTP_ERROR_CODE.BAD_REQUEST ||
              res.status === HTTP_ERROR_CODE.UNAUTHORIZED
          ) {
            return throwError(res);
          } else {
            logger.debug('Retrying request to url "' + url + '".', 'Attempt ' + count + '.');

            count += 1;
            return timer(HTTP_RETRY_INTERVAL);
          }
        })
    );
  };

  static errHandler(res: AxiosResponse | any): Observable<HttpError> {
    if (res instanceof TimeoutError) {
      return throwError(new HttpError(408, res.message));
    }

    let code = 0, message = '';
    try {
      code = res.response.status;
      message = res.response.statusText;
    } catch (err) {
      logger.error('Error parsing server error message.');
    }

    const emptyMessage = !message || !message.toLowerCase() ||
        message.toLowerCase().includes('ok');
    const systemError = code === HTTP_ERROR_CODE.SYSTEM || !code;

    if (emptyMessage || systemError) {
      message = conf.defaultErrMessage;
      }

    const body = HttpUtils.parseJson(res.response);
    if (body?.errorMessage !== null && body?.errorMessage !== undefined) {
        message = body.errorMessage;
    }

    logger.error('Failed request to url', res.config.url + '. Status', code, '. Message', message + '.');

    return throwError(new HttpError(code, message));
  }
}
