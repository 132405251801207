/* @flow */

import Typography from '@material-ui/core/Typography';
import * as React from 'react';

type TabContainerProps = {
  children: React.Node
};
type TabContainerState = {};

export default class TabContainer extends React.Component<TabContainerProps, TabContainerState> {
  render() {
    return (
        <Typography component={'div'} style={{padding: 8 * 3}}>
          {this.props.children}
        </Typography>
    );
  }
}
